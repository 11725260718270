import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { catchError, Observable, of } from "rxjs";
import { ApiResponseModel } from "src/app/models/common.model";
import {
  AddLoanDataExtraction,
  DataExtractionHIL,
  DeleteRestoreFlagFieldValue,
  DocumentContentResponse,
  FetchField,
  GetEntityList,
  GetFieldList,
  IDocumentType,
  IParentDocument,
  UpdateLoanDataExtraction,
  UpdateLoanStatus,
} from "src/app/store/data-extraction-hil/data-extraction-hil.types";
import { mockHilData } from "src/app/store/data-extraction-hil/mockData";
import { TokenStorageService } from "../TokenStorageService/token-storage.service";

@Injectable({
  providedIn: "root",
})
export class DataExtractionHILService {
  idToken: string;
  constructor(
    private http: HttpClient,
    tokenStorageService: TokenStorageService,
    @Inject("DATA_EXTRACTION_HIL_API_URL") private baseUrl: string,
    @Inject("DOCUMENT_URL") private LoanDocumentsUrl: string,
    @Inject("LOAN_STATUS_URL") private LoanStatusUrl: string
  ) {
    this.idToken = tokenStorageService.getAccessToken() || "";
  }

  // Loan Data
  getDataExtractionByLoanId(projectId: number, loanId: string): Observable<ApiResponseModel<DataExtractionHIL>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http
      .get<
        ApiResponseModel<DataExtractionHIL>
      >(`${this.baseUrl}DataExtraction/project/${projectId}/loans/${loanId}`, { headers })
      .pipe(
        catchError(() => {
          console.warn("API request failed. Returning mock data.");
          return of(mockHilData as unknown as ApiResponseModel<DataExtractionHIL>);
        })
      );
  }

  // Create the field data
  addLoanExtraction(
    projectId: number,
    loanId: string,
    addloandataextraction: AddLoanDataExtraction
  ): Observable<ApiResponseModel<boolean>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.post<ApiResponseModel<boolean>>(
      `${this.baseUrl}DataExtraction/Project/${projectId}/loans/${loanId}/LoanFields`,
      addloandataextraction,
      { headers }
    );
  }

  // Restore field data
  deleteRestoreFieldValue(
    projectId: number,
    loanId: string,
    fieldValueId: string | undefined,
    deleteRestoreObject: DeleteRestoreFlagFieldValue
  ): Observable<ApiResponseModel<boolean>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.post<ApiResponseModel<boolean>>(
      `${this.baseUrl}DataExtraction/project/${projectId}/loans/${loanId}/LoanFields/${fieldValueId}/RestoreDelete`,
      deleteRestoreObject,
      { headers }
    );
  }

  // Update the field Data
  updateDataExtraction(
    loanId: string,
    projectId: number,
    fieldId: string,
    updateloandataextraction: UpdateLoanDataExtraction
  ): Observable<ApiResponseModel<boolean>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    const url = `${this.baseUrl}DataExtraction/project/${projectId}/loans/${loanId}/LoanFields/${fieldId}`;
    return this.http.put<ApiResponseModel<boolean>>(url, updateloandataextraction, { headers });
  }

  // Document data
  getDocumentByDocId(
    documentId: string,
    projectId: number,
    loanId: string
  ): Observable<ApiResponseModel<DocumentContentResponse>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<DocumentContentResponse>>(
      `${this.baseUrl}DataExtraction/project/${projectId}/loans/${loanId}/document/${documentId}`,
      { headers }
    );
  }

  // truth field update
  setSourceOfTruth(
    fieldValueId: string,
    projectId: number,
    loanId: string
  ): Observable<ApiResponseModel<DocumentContentResponse>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.post<ApiResponseModel<DocumentContentResponse>>(
      `${this.baseUrl}DataExtraction/project/${projectId}/loans/${loanId}/LoanFields/${fieldValueId}/TruthSource`,
      { headers }
    );
  }

  // delete field data
  deleteFieldData(projectId: number, loanId: string, fieldId: string): Observable<ApiResponseModel<boolean>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.post<ApiResponseModel<boolean>>(
      `${this.baseUrl}DataExtraction/project/${projectId}/loans/${loanId}/LoanFields/${fieldId}/Delete`,
      { headers }
    );
  }

  // Entity Data
  fetchEntity(projectId: number, loanId: string, fieldId: string): Observable<ApiResponseModel<GetEntityList[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    const body = {
      field_id: fieldId,
    };
    return this.http.post<ApiResponseModel<GetEntityList[]>>(
      `${this.baseUrl}DataExtraction/project/${projectId}/loans/${loanId}/FetchEntities`,
      body,
      { headers }
    );
  }

  // Field Data
  fetchFields(
    projectId: number,
    loanId: string,
    fetchFields: FetchField
  ): Observable<ApiResponseModel<GetFieldList[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.post<ApiResponseModel<GetFieldList[]>>(
      `${this.baseUrl}DataExtraction/project/${projectId}/loans/${loanId}/FetchFields`,
      fetchFields,
      { headers }
    );
  }

  getDocumentData(
    projectId: number,
    loanId: string,
    fieldId: string,
    parentDocument: IParentDocument
  ): Observable<ApiResponseModel<IDocumentType[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.post<ApiResponseModel<IDocumentType[]>>(
      `${this.baseUrl}DataExtraction/project/${projectId}/loans/${loanId}/LoanFields/${fieldId}/GetDocuments`,
      parentDocument,
      { headers }
    );
  }

  updateLoanStatus(updateLoanStatus: UpdateLoanStatus): Observable<ApiResponseModel<boolean>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });

    const url = `${this.LoanStatusUrl}projects/${updateLoanStatus.projectId}/loans/${updateLoanStatus.loanId}/status?loanStatusId=${updateLoanStatus.loanStatusId}`;
    return this.http.put<ApiResponseModel<boolean>>(url, null, { headers });
  }

  updateCoreLoanAllocationStatus(updateLoanStatus: UpdateLoanStatus) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    const url = `${this.LoanDocumentsUrl}api/v1/projects/${updateLoanStatus.projectId}/loans/${updateLoanStatus.loanId}/users/${updateLoanStatus.userId}/status?loanStatusId=${updateLoanStatus.loanStatusId}`;
    return this.http.put<ApiResponseModel<boolean>>(url, null, { headers });
  }

  updateTheFieldData(
    projectId: number,
    loanId: string,
    fieldValueId: string,
    updateTheFieldValue: string
  ): Observable<ApiResponseModel<boolean>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    const url = `${this.baseUrl}DataExtraction/project/${projectId}/loans/${loanId}/LoanFields/${fieldValueId}`;
    return this.http.put<ApiResponseModel<boolean>>(url, { value: updateTheFieldValue }, { headers });
  }

  revertToOriginalFieldData(
    projectId: number,
    loanId: string,
    fieldValueId: string
  ): Observable<ApiResponseModel<boolean>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    const url = `${this.baseUrl}DataExtraction/project/${projectId}/loans/${loanId}/LoanFields/${fieldValueId}/RestoreOriginal`;
    return this.http.post<ApiResponseModel<boolean>>(url, null, { headers });
  }
}
