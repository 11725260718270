export const mockHilData = {
  success: true,
  message: "Loan data fetched successfully.",
  errors: [],
  data: {
    id: "18bb6796-e095-4d68-a225-3844372a216c",
    createdDateTime: "2025-02-21T18:32:01Z",
    projectid: "1",
    loantypeid: "OTHER ",
    confidence_Threshold: "0.800000",
    minimum_Matching_Sources: "3",
    maximum_Source_Count: "4",
    loanid: "ac530351-71e8-40d7-9c55-6788302a3ba7",
    loan_number: "AIRFLOWTEST2-4",
    borrowerCount: "2",
    borrowerNames: "John Michael Bennett, Denna Lemelin Bennett",
    loan_data: [
      {
        field_name: "Loan_Purpose_Construction",
        field_id: "51a1e288-e9d3-4339-8cf1-b5dbf965a8a0",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "7ce2be0d-d687-48ff-875e-049a5cb3c409",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98800",
                  page_width: "8.26390",
                  x_Coordinate: "322.46398925781250",
                  y_Coordinate: "264.18240356445310",
                  width_Coordinate: "9.70559692382810",
                  height_Coordinate: "9.45599365234380",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "B1_PMP_MIP",
        field_id: "c0d7cfd7-cb2e-4e19-a5f5-9661e0fef89f",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "c680157f-c751-4595-9f38-0d6ca7fae862",
                  type: "Decimal",
                  page_no: "1",
                  confidence: "0.98200",
                  page_width: "8.26390",
                  x_Coordinate: "602.54400634765620",
                  y_Coordinate: "412.22399902343750",
                  width_Coordinate: "33.10083007812500",
                  height_Coordinate: "8.27520751953125",
                  is_Truth: "1",
                  lastModifiedBy: "B5D71380-FABA-4528-A61C-8B8247C0BC1E",
                  lastModifiedDate: "2025-02-21T11:30:42.18",
                  content: "162.95",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "162.95",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Loan_Purpose_Energy_Efficient",
        field_id: "8dad7c06-7856-4ec8-998b-a997d6218f63",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "2bb9941a-1672-4242-9866-1017b8f946aa",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98900",
                  page_width: "8.26390",
                  x_Coordinate: "322.46398925781250",
                  y_Coordinate: "274.17599487304690",
                  width_Coordinate: "9.57119750976560",
                  height_Coordinate: "9.45599365234370",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Estimated_Taxes_Ins&amp;Assessment",
        field_id: "5fca9c81-6c57-4c00-952c-e06741f64b7a",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "8ba5d494-a2bf-43d1-a0c9-1633c2b1aa26",
                  type: "Decimal",
                  page_no: "1",
                  confidence: "0.98800",
                  page_width: "8.26390",
                  x_Coordinate: "602.54400634765620",
                  y_Coordinate: "469.17120361328125",
                  width_Coordinate: "33.58074951171880",
                  height_Coordinate: "8.27520751953125",
                  is_Truth: "1",
                  lastModifiedBy: "8BA5D494-A2BF-43D1-A0C9-1633C2B1AA26",
                  lastModifiedDate: "2025-02-19T17:50:24.8566667",
                  content: "255.76",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "255.76",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "First_P&amp;I",
        field_id: "a3f842e5-7947-4108-802b-5e1ceb34fda0",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "13644f76-5c28-494e-8984-2c715c0c00d1",
                  type: "Decimal",
                  page_no: "1",
                  confidence: "0.94800",
                  page_width: "8.26390",
                  x_Coordinate: "589.89123535156250",
                  y_Coordinate: "400.54077148437500",
                  width_Coordinate: "47.21276855468750",
                  height_Coordinate: "9.24481201171875",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "2602",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "2602",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Gift_Family",
        field_id: "dab8775b-5970-4ff0-bf11-99ad9cb9785e",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "d363907d-248c-4d59-8a75-3280ca5e891d",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98600",
                  page_width: "8.26390",
                  x_Coordinate: "552.68164062500000",
                  y_Coordinate: "289.49758911132810",
                  width_Coordinate: "9.96472167968750",
                  height_Coordinate: "9.96481323242190",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Gift2_Other",
        field_id: "e5c193ee-96a6-416b-b2db-366981812c09",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "97716b57-49d1-4f27-88cb-2ee5dd568dee",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98700",
                  page_width: "8.26390",
                  x_Coordinate: "470.24639892578125",
                  y_Coordinate: "340.88641357421875",
                  width_Coordinate: "9.69601440429685",
                  height_Coordinate: "9.58081054687500",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "B1_PMP_HOA",
        field_id: "7f6469fe-ddd5-4561-b52e-cec941d6fb8b",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "170ec44a-35cd-43bc-9103-55ded1ec630b",
                  type: "Decimal",
                  page_no: "1",
                  confidence: "0.96500",
                  page_width: "8.26390",
                  x_Coordinate: "607.90081787109380",
                  y_Coordinate: "423.66717529296875",
                  width_Coordinate: "28.29119873046870",
                  height_Coordinate: "7.77606201171875",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "47",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "47",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Amort_Fixed",
        field_id: "afd4d24d-4d45-44f1-aece-b5c367f1ba21",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "36025934-4eed-4af3-bcaa-52581ed87f01",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98700",
                  page_width: "8.26390",
                  x_Coordinate: "137.48159790039062",
                  y_Coordinate: "213.70559692382812",
                  width_Coordinate: "10.86720275878908",
                  height_Coordinate: "9.06239318847658",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":selected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":selected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Gift2_NP",
        field_id: "05d51f76-3e8a-4e64-9314-e0368f1ee442",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "a3f5762e-46ac-42dd-aaef-57a4e4e20f0c",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98700",
                  page_width: "8.26390",
                  x_Coordinate: "517.29602050781250",
                  y_Coordinate: "330.89279174804690",
                  width_Coordinate: "10.08959960937500",
                  height_Coordinate: "10.09921264648435",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Gift_Other",
        field_id: "4bd7e1e3-5f58-49a8-b4bb-126c95fa4435",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "a27c75e2-5940-4e88-8146-6cf4f0ca99d7",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98700",
                  page_width: "8.26390",
                  x_Coordinate: "470.11199951171875",
                  y_Coordinate: "299.61599731445310",
                  width_Coordinate: "9.70556640625000",
                  height_Coordinate: "9.71517944335940",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Prop_Type_Co-Op",
        field_id: "4da1a796-e134-4d14-849c-8978be2eb340",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "96360bfe-1a31-4e39-8209-69451f757d12",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98700",
                  page_width: "8.26390",
                  x_Coordinate: "202.04159545898438",
                  y_Coordinate: "163.08479309082030",
                  width_Coordinate: "10.60800170898437",
                  height_Coordinate: "10.35841369628908",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Loan_Int_Rate",
        field_id: "ad936dd0-90c6-4855-9bb5-d1c26e634ca8",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "d8eb5075-c59e-4553-8de2-479a30f82a0d",
                  type: "Decimal",
                  page_no: "1",
                  confidence: "0.98700",
                  page_width: "8.26390",
                  x_Coordinate: "270.60479736328125",
                  y_Coordinate: "318.28799438476560",
                  width_Coordinate: "35.63519287109375",
                  height_Coordinate: "7.79519653320315",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "7.750%",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "7.750%",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Income_Rental",
        field_id: "501cd429-aa07-4523-9fd7-89c2134dce23",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "38da9dd9-ad92-4047-bc21-4ed1f275e64a",
                  type: "Decimal",
                  page_no: "1",
                  confidence: "0.98100",
                  page_width: "8.26390",
                  x_Coordinate: "387.41760253906250",
                  y_Coordinate: "418.54077148437500",
                  width_Coordinate: "21.90722656250000",
                  height_Coordinate: "7.79522705078125",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "0",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "0",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "CHUMS_Id",
        field_id: "d0c280eb-ae46-400e-b4a9-c7b84fb79ec3",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "2b7e7c6e-6bb1-46a8-aec2-4fde3bd9928c",
                  type: "String",
                  page_no: "1",
                  confidence: "0.94900",
                  page_width: "8.26390",
                  x_Coordinate: "471.12957763671875",
                  y_Coordinate: "830.78405761718750",
                  width_Coordinate: "22.38720703125000",
                  height_Coordinate: "7.29589843750000",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "KZ83",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "KZ83",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "B1_PMP_Hazard_Insurance",
        field_id: "c043cd80-9a71-408f-a962-cc581f94be89",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "e02dcc94-10b4-48b6-a832-3562ff456572",
                  type: "Decimal",
                  page_no: "1",
                  confidence: "0.98400",
                  page_width: "8.26390",
                  x_Coordinate: "602.54400634765620",
                  y_Coordinate: "457.97760009765625",
                  width_Coordinate: "33.15838623046880",
                  height_Coordinate: "7.78558349609375",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "135.28",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "135.28",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Second_Fin_Other",
        field_id: "1278ddf6-fcac-4d42-b6ae-70f8fbb4865c",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "692ed142-20ce-4fba-9e94-385eef088293",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98700",
                  page_width: "8.26390",
                  x_Coordinate: "468.69116210937500",
                  y_Coordinate: "227.46240234375000",
                  width_Coordinate: "9.95523071289060",
                  height_Coordinate: "9.44638061523438",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Income_Total",
        field_id: "03abb06b-3eef-4394-9ba3-266f1e0397f4",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "79133379-0ca9-4cd4-8b64-37cc2fe210f7",
                  type: "Decimal",
                  page_no: "1",
                  confidence: "0.86600",
                  page_width: "8.26390",
                  x_Coordinate: "357.73437500000000",
                  y_Coordinate: "428.77441406250000",
                  width_Coordinate: "51.59045410156250",
                  height_Coordinate: "8.75518798828125",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "10433.98",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "10433.98",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Loan_With_UFMIP",
        field_id: "b1acf75f-4587-4e99-9e14-58eaa3e99d31",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "8946cfd1-db1e-4886-9c9b-3a51cf20d686",
                  type: "Decimal",
                  page_no: "1",
                  confidence: "0.98600",
                  page_width: "8.26390",
                  x_Coordinate: "240.92160034179688",
                  y_Coordinate: "307.10400390625000",
                  width_Coordinate: "57.46560668945312",
                  height_Coordinate: "8.75518798828125",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "363199",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "363199",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Gift2_Family",
        field_id: "63d00047-0edf-4e65-ba59-d92c849940f6",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "22ec921a-74b0-472e-af7e-f1f2574fd32e",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98700",
                  page_width: "8.26390",
                  x_Coordinate: "552.81604003906250",
                  y_Coordinate: "330.89279174804690",
                  width_Coordinate: "9.83032226562500",
                  height_Coordinate: "9.83999633789060",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Loan_Purpose_Cash",
        field_id: "3e90b27f-52a1-42ec-ad71-2564aed2fb39",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "ba31b591-8a7a-4c3a-a92b-e761af9d1f51",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98300",
                  page_width: "8.26390",
                  x_Coordinate: "322.46398925781250",
                  y_Coordinate: "233.42399597167970",
                  width_Coordinate: "9.57119750976560",
                  height_Coordinate: "9.45600891113280",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Risk_Class_A_A",
        field_id: "7ab062cc-6c21-4681-8fb4-67641dc25c28",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "a8a7440c-49bb-4b96-90af-e096494bdfaf",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98800",
                  page_width: "8.26390",
                  x_Coordinate: "341.77920532226560",
                  y_Coordinate: "871.31518554687500",
                  width_Coordinate: "10.22396850585940",
                  height_Coordinate: "10.09924316406250",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Second_Fin_NP",
        field_id: "1c50e980-920d-40d0-b466-0b0924ba8d02",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "42b9bbcb-4233-483c-be0e-b42c78760514",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98700",
                  page_width: "8.26390",
                  x_Coordinate: "516.00000000000000",
                  y_Coordinate: "217.72799682617188",
                  width_Coordinate: "9.83038330078120",
                  height_Coordinate: "9.70561218261718",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Risk_Total_No",
        field_id: "4056b65a-5d00-49c0-b11d-36cad232a20c",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "6acd5f06-3389-42e0-b888-b5c62d02bdab",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98800",
                  page_width: "8.26390",
                  x_Coordinate: "252.97920227050780",
                  y_Coordinate: "871.83361816406250",
                  width_Coordinate: "10.86720275878910",
                  height_Coordinate: "10.61755371093750",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":selected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":selected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Loan_Purpose_Building",
        field_id: "6401eb86-7afa-48ea-9ff5-146cd3e788fe",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "c1bf1966-6692-45f1-81a1-c2839fdeabc1",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98900",
                  page_width: "8.26390",
                  x_Coordinate: "322.46398925781250",
                  y_Coordinate: "283.78558349609375",
                  width_Coordinate: "9.83041381835940",
                  height_Coordinate: "9.58081054687500",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Risk_Total_Yes",
        field_id: "56075e0c-f1f9-45be-8896-453e89b8c387",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "c19d0e97-9b56-4e57-b7c9-cdf43cfba89b",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98800",
                  page_width: "8.26390",
                  x_Coordinate: "216.03840637207030",
                  y_Coordinate: "867.16796875000000",
                  width_Coordinate: "10.08958435058595",
                  height_Coordinate: "9.84002685546880",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Loan_Purpose_Purchase",
        field_id: "b261d571-f41b-480f-a4a5-eb63dd79d61b",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "416b3c13-fc9c-44d2-8765-d0d1242df3b9",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98700",
                  page_width: "8.26390",
                  x_Coordinate: "322.20480346679690",
                  y_Coordinate: "212.65919494628906",
                  width_Coordinate: "10.08959960937500",
                  height_Coordinate: "9.83999633789064",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":selected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":selected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Gift2_Gov",
        field_id: "6fb1fc7a-1c6e-4559-8f08-d990900cffff",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "bc73bd42-ada2-4aaf-95f1-c9736bc0c104",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98800",
                  page_width: "8.26390",
                  x_Coordinate: "470.37121582031250",
                  y_Coordinate: "331.15200805664060",
                  width_Coordinate: "9.44635009765625",
                  height_Coordinate: "9.32159423828130",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Projected_Payment",
        field_id: "12d16745-089b-4cce-92ef-a877a82eea14",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "32d11ac7-2cf8-46c1-b543-ca115b79d131",
                  type: "String",
                  page_no: "1",
                  confidence: "0.98100",
                  page_width: "8.26390",
                  x_Coordinate: "590.82238769531250",
                  y_Coordinate: "481.08480834960940",
                  width_Coordinate: "45.26397705078130",
                  height_Coordinate: "7.80477905273435",
                  is_Truth: "1",
                  lastModifiedBy: "B5D71380-FABA-4528-A61C-8B8247C0BC1E",
                  lastModifiedDate: "2025-02-21T09:52:45.96",
                  content: "200.10",
                  isDeleted: "0",
                  has_Changed: "1",
                  original_Value: "3201.78",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Income_Base",
        field_id: "17af9c0f-353d-4489-9465-c0807ee040d5",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "36cf5176-26e6-4bb2-9e8a-c94dd6a92cdc",
                  type: "Decimal",
                  page_no: "1",
                  confidence: "0.85400",
                  page_width: "8.26390",
                  x_Coordinate: "363.08160400390625",
                  y_Coordinate: "396.65283203125000",
                  width_Coordinate: "46.72317504882815",
                  height_Coordinate: "9.24475097656250",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "8881.47",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "8881.47",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Gift_Gov",
        field_id: "77172f6f-ad01-4723-8d0f-5ecb64f30d50",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "09bec3af-b0ee-4c8f-9d88-9b2ed882167b",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98700",
                  page_width: "8.26390",
                  x_Coordinate: "470.11199951171875",
                  y_Coordinate: "289.62240600585940",
                  width_Coordinate: "9.83041381835935",
                  height_Coordinate: "9.71517944335935",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Loan_Purpose_Other",
        field_id: "c6446c63-b087-4891-8e11-c79ee3b20c92",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "0e564f6e-9cba-425b-83ad-a8d08112ea8e",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98800",
                  page_width: "8.26390",
                  x_Coordinate: "322.20480346679690",
                  y_Coordinate: "332.97598266601560",
                  width_Coordinate: "10.22399902343750",
                  height_Coordinate: "9.96481323242190",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Risk_Class_Refer",
        field_id: "5cc58731-cbc8-49e8-8c50-b7076ef9e0bb",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "3b216e41-ecfd-4112-9c19-ad3b41c22e1d",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98900",
                  page_width: "8.26390",
                  x_Coordinate: "385.07519531250000",
                  y_Coordinate: "871.31518554687500",
                  width_Coordinate: "10.34881591796875",
                  height_Coordinate: "10.09924316406250",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":selected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":selected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Qualify_Pay_To_Income",
        field_id: "12242ee7-2504-4698-b82f-71fab32a2d75",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "48487d3e-a407-4647-a99f-aef10f433225",
                  type: "Decimal",
                  page_no: "1",
                  confidence: "0.98200",
                  page_width: "8.26390",
                  x_Coordinate: "607.98718261718750",
                  y_Coordinate: "542.16003417968750",
                  width_Coordinate: "38.44799804687500",
                  height_Coordinate: "6.82556152343750",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "30.69%",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "30.69%",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Desktop_UW_Sign",
        field_id: "e969e169-25c3-491c-a4de-19260499c516",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "fd3b7741-8162-40b5-b1f4-b27651a2a650",
                  type: "String",
                  page_no: "1",
                  confidence: "0.16700",
                  page_width: "8.26390",
                  x_Coordinate: "200.03518676757812",
                  y_Coordinate: "824.93756103515620",
                  width_Coordinate: "216.58560180664063",
                  height_Coordinate: "25.79522705078130",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "Dawn Gillespie Dawn Gillespie 12/15/2023 9:16 AM",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "Dawn Gillespie Dawn Gillespie 12/15/2023 9:16 AM",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "UW_Sign_Date",
        field_id: "beb058bd-c6d4-4e9c-b645-dc1f84081d94",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "11f9dc81-423c-428f-9451-b28ca3f28d8e",
                  type: "Date",
                  page_no: "1",
                  confidence: "0.94200",
                  page_width: "8.26390",
                  x_Coordinate: "345.07202148437500",
                  y_Coordinate: "809.36633300781250",
                  width_Coordinate: "78.85440063476560",
                  height_Coordinate: "9.73449707031250",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "2023-12-15",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "2023-12-15",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Loan_Purpose_Streamlined_Refinance",
        field_id: "9bbe0153-ea5f-4f0d-8758-402d7ce9c17c",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "9d48c582-178c-4ec4-8800-8c4587740f48",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98900",
                  page_width: "8.26390",
                  x_Coordinate: "322.46398925781250",
                  y_Coordinate: "253.41119384765625",
                  width_Coordinate: "9.83041381835940",
                  height_Coordinate: "9.58081054687500",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Loan_Purpose_203K_Standard",
        field_id: "64a1a482-ff8b-4d24-9d5f-ab86713f9298",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "932db92c-5b97-4aca-9c3f-95103390f2b1",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98800",
                  page_width: "8.26390",
                  x_Coordinate: "338.01599121093750",
                  y_Coordinate: "314.15039062500000",
                  width_Coordinate: "9.44641113281250",
                  height_Coordinate: "9.32159423828125",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "1",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Income_Other",
        field_id: "9ab19fd6-874a-4aa7-bb5b-df3acc31e7b8",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "04e5408d-e951-41ab-8ce3-9530bac565bd",
                  type: "Decimal",
                  page_no: "1",
                  confidence: "0.96900",
                  page_width: "8.26390",
                  x_Coordinate: "364.06079101562500",
                  y_Coordinate: "407.84643554687500",
                  width_Coordinate: "45.74398803710940",
                  height_Coordinate: "8.27514648437500",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "1552.51",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "1552.51",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Loan_Purpose_No_Cash",
        field_id: "90e2e01c-e887-44a7-acfb-ece872753248",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "93ffa221-ebe8-465d-b1b7-7d1eb9513be5",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98900",
                  page_width: "8.26390",
                  x_Coordinate: "322.46398925781250",
                  y_Coordinate: "223.43038940429688",
                  width_Coordinate: "9.83041381835940",
                  height_Coordinate: "9.59040832519532",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Gift_Seller_Funded_Y_N",
        field_id: "0fa9d558-3bea-4674-b8cc-d5fc622feaa8",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "68b423bb-64f4-4c05-bc9f-72bf3940d3ca",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.90000",
                  page_width: "8.26390",
                  x_Coordinate: "568.89599609375000",
                  y_Coordinate: "268.21441650390625",
                  width_Coordinate: "9.95520019531250",
                  height_Coordinate: "9.96478271484375",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Qualify_Fixed_Pay_To_Income",
        field_id: "9500c5a9-2f5e-4ee2-9f76-db3707b57ad7",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "53cdf9c2-b201-4f56-9c28-ffc75c2237db",
                  type: "Decimal",
                  page_no: "1",
                  confidence: "0.98300",
                  page_width: "8.26390",
                  x_Coordinate: "607.90081787109380",
                  y_Coordinate: "553.36322021484380",
                  width_Coordinate: "38.00640869140620",
                  height_Coordinate: "7.30560302734370",
                  is_Truth: "1",
                  lastModifiedBy: "B5D71380-FABA-4528-A61C-8B8247C0BC1E",
                  lastModifiedDate: "2025-02-21T07:47:16.14",
                  content: "34.8",
                  isDeleted: "0",
                  has_Changed: "1",
                  original_Value: "34.86%",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Gift_NP",
        field_id: "719ec39b-0aab-45f6-9a0a-5bda3f0f90ca",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "965fee73-8ac2-493c-9053-fff720105227",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98700",
                  page_width: "8.26390",
                  x_Coordinate: "517.43041992187500",
                  y_Coordinate: "289.62240600585940",
                  width_Coordinate: "9.83038330078120",
                  height_Coordinate: "9.71517944335935",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Second_Fin_Gov",
        field_id: "b2917061-6f6d-4303-bf4b-6e5a609503b5",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "d3a77035-073f-42ad-884f-fe142a438953",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98700",
                  page_width: "8.26390",
                  x_Coordinate: "468.81600952148440",
                  y_Coordinate: "217.46881103515625",
                  width_Coordinate: "9.83038330078120",
                  height_Coordinate: "9.70559692382813",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Loan_Purpose_203K_Limited",
        field_id: "0dd5bcdb-8f85-4f31-8eb1-402cd5d272a8",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "5e2bf5d7-00dd-489e-b636-facc8b7d1490",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98800",
                  page_width: "8.26390",
                  x_Coordinate: "338.01599121093750",
                  y_Coordinate: "323.24160766601560",
                  width_Coordinate: "9.32159423828125",
                  height_Coordinate: "9.18719482421880",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Loan_Purpose_HUD_REO",
        field_id: "35b84614-b2c8-4f1a-829c-b69dc817b9b4",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "1607fc4a-3116-4921-a762-f9226df78b33",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98600",
                  page_width: "8.26390",
                  x_Coordinate: "322.33920288085940",
                  y_Coordinate: "294.03839111328125",
                  width_Coordinate: "9.83038330078120",
                  height_Coordinate: "9.58081054687500",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Second_Fin_Family",
        field_id: "fa5db101-502e-4049-b4db-c84c54e3aeb1",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "163f143b-12f4-45aa-bd9d-f6e0e2d4fd8a",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98700",
                  page_width: "8.26390",
                  x_Coordinate: "551.26080322265620",
                  y_Coordinate: "217.59359741210938",
                  width_Coordinate: "10.08959960937500",
                  height_Coordinate: "9.97441101074218",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Loan_Purpose_203K",
        field_id: "60a5748e-6502-4aef-a5bd-040b257913ae",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "9d89da89-878c-4a35-8d82-f7fc11a66b0b",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.98800",
                  page_width: "8.26390",
                  x_Coordinate: "322.33920288085940",
                  y_Coordinate: "304.03201293945310",
                  width_Coordinate: "9.83038330078120",
                  height_Coordinate: "9.58078002929690",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Seller_Date",
        field_id: "41001caa-8601-4661-acb3-9cd9864c4eee",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSPROPC",
            documentTypeDisplayName: "PROPERTY-PURCHASE CONTRACT",
            documentTypeShortCode: "PROPC",
            versions: [
              {
                documentId: "7a300b75-ce3b-47d1-ac56-274c2769d1da",
                documentDisplayName: "PROPERTY-PURCHASE CONTRACT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "bed4360a-8a6b-4690-9252-06c97b76302c",
                  type: "Date",
                  page_no: "15",
                  confidence: "0.51600",
                  page_width: "8.26390",
                  x_Coordinate: "569.45275878906250",
                  y_Coordinate: "415.20959472656250",
                  width_Coordinate: "88.37762451171870",
                  height_Coordinate: "18.92163085937500",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "2023-11-20",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "2023-11-20",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Contract_Did_Not",
        field_id: "62398247-7149-408f-8833-ee265eea065b",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAPPLR",
            documentTypeDisplayName: "APPRAISAL REPORT",
            documentTypeShortCode: "APPLR",
            versions: [
              {
                documentId: "d8374348-522b-4228-9d26-36b620debc43",
                documentDisplayName: "APPRAISAL REPORT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "d28eb4d3-1219-491c-adce-066cea280fd1",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.99000",
                  page_width: "8.26390",
                  x_Coordinate: "168.47999572753906",
                  y_Coordinate: "235.67999267578125",
                  width_Coordinate: "9.12001037597656",
                  height_Coordinate: "9.11999511718750",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Appraiser_Sign",
        field_id: "57a4102c-615a-4f3b-9a0a-7e765edbebf8",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAPPLR",
            documentTypeDisplayName: "APPRAISAL REPORT",
            documentTypeShortCode: "APPLR",
            versions: [
              {
                documentId: "d8374348-522b-4228-9d26-36b620debc43",
                documentDisplayName: "APPRAISAL REPORT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "4279c474-c9a5-46e7-8fb5-15bfdec1c68c",
                  type: "Signature",
                  page_no: "8",
                  confidence: "0.70800",
                  page_width: "8.26390",
                  x_Coordinate: "204.00000000000000",
                  y_Coordinate: "594.72003173828120",
                  width_Coordinate: "139.20001220703125",
                  height_Coordinate: "28.79998779296880",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "signed",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "signed",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Contract_Data_Source",
        field_id: "3a152aba-e396-433e-974f-c9b7c746d958",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAPPLR",
            documentTypeDisplayName: "APPRAISAL REPORT",
            documentTypeShortCode: "APPLR",
            versions: [
              {
                documentId: "d8374348-522b-4228-9d26-36b620debc43",
                documentDisplayName: "APPRAISAL REPORT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "1e168ff1-87da-4960-8791-0f9c9dc0fe84",
                  type: "String",
                  page_no: "1",
                  confidence: "0.98900",
                  page_width: "8.26390",
                  x_Coordinate: "606.72003173828120",
                  y_Coordinate: "269.76000976562500",
                  width_Coordinate: "73.91998291015630",
                  height_Coordinate: "9.60000610351560",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "Assessors Records",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "Assessors Records",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Owner_Public_Yes",
        field_id: "32833e01-6ee2-422f-9b11-114dcc3038ee",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAPPLR",
            documentTypeDisplayName: "APPRAISAL REPORT",
            documentTypeShortCode: "APPLR",
            versions: [
              {
                documentId: "d8374348-522b-4228-9d26-36b620debc43",
                documentDisplayName: "APPRAISAL REPORT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "a99b16f6-914b-4072-82db-10e12b03f272",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.99000",
                  page_width: "8.26390",
                  x_Coordinate: "501.59997558593750",
                  y_Coordinate: "268.79998779296875",
                  width_Coordinate: "9.12005615234375",
                  height_Coordinate: "9.11999511718750",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":selected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":selected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Financial_Assistance_Details",
        field_id: "81de8654-4248-47e7-8c00-8a44f84e1c78",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAPPLR",
            documentTypeDisplayName: "APPRAISAL REPORT",
            documentTypeShortCode: "APPLR",
            versions: [
              {
                documentId: "d8374348-522b-4228-9d26-36b620debc43",
                documentDisplayName: "APPRAISAL REPORT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "81c3bb94-5e21-4f62-bb06-1d6df11a2bd6",
                  type: "String",
                  page_no: "1",
                  confidence: "0.86100",
                  page_width: "8.26390",
                  x_Coordinate: "132.00000000000000",
                  y_Coordinate: "292.32000732421875",
                  width_Coordinate: "531.35998535156250",
                  height_Coordinate: "21.59997558593750",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content:
                    '$10,000;Per contract: "Seller to contribute $10,000 towards the Buyer\'s closing costs and pre-paids."',
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value:
                    '$10,000;Per contract: "Seller to contribute $10,000 towards the Buyer\'s closing costs and pre-paids."',
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Financial_Assistance_Yes",
        field_id: "fce2e4f4-47d7-4ab7-8195-67b4214249e7",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAPPLR",
            documentTypeDisplayName: "APPRAISAL REPORT",
            documentTypeShortCode: "APPLR",
            versions: [
              {
                documentId: "d8374348-522b-4228-9d26-36b620debc43",
                documentDisplayName: "APPRAISAL REPORT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "60844ac9-8a98-4f13-a10b-7737eb40d050",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.99000",
                  page_width: "8.26390",
                  x_Coordinate: "631.67999267578120",
                  y_Coordinate: "280.32000732421875",
                  width_Coordinate: "9.59997558593760",
                  height_Coordinate: "9.59997558593750",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":selected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":selected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "File_No",
        field_id: "4b1eae2d-865b-4c74-9dc7-c8c0018346da",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAPPLR",
            documentTypeDisplayName: "APPRAISAL REPORT",
            documentTypeShortCode: "APPLR",
            versions: [
              {
                documentId: "d8374348-522b-4228-9d26-36b620debc43",
                documentDisplayName: "APPRAISAL REPORT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "524ebea6-f687-4d73-8076-83dac18856ea",
                  type: "String",
                  page_no: "1",
                  confidence: "0.99000",
                  page_width: "8.26390",
                  x_Coordinate: "582.23999023437500",
                  y_Coordinate: "63.36000061035156",
                  width_Coordinate: "49.44000244140620",
                  height_Coordinate: "18.72000122070313",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "098-1725940 231101117",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "098-1725940 231101117",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Lender",
        field_id: "9f2cf72d-18ed-4cd5-9ce8-7c9d0ae2a6d9",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAPPLR",
            documentTypeDisplayName: "APPRAISAL REPORT",
            documentTypeShortCode: "APPLR",
            versions: [
              {
                documentId: "d8374348-522b-4228-9d26-36b620debc43",
                documentDisplayName: "APPRAISAL REPORT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "cd1930fb-e3d6-4bbb-8fae-ae3cc2039fc2",
                  type: "String",
                  page_no: "1",
                  confidence: "0.98000",
                  page_width: "8.26390",
                  x_Coordinate: "182.39999389648438",
                  y_Coordinate: "189.60000610351562",
                  width_Coordinate: "97.92001342773437",
                  height_Coordinate: "10.55999755859376",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "Movement Mortgage, LLC",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "Movement Mortgage, LLC",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Financial_Assistance_No",
        field_id: "008f6459-7e1c-414b-8d81-9d5262fa35a0",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAPPLR",
            documentTypeDisplayName: "APPRAISAL REPORT",
            documentTypeShortCode: "APPLR",
            versions: [
              {
                documentId: "d8374348-522b-4228-9d26-36b620debc43",
                documentDisplayName: "APPRAISAL REPORT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "c0e57cc6-ee63-491e-8ef5-9bf03b50ffb6",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.99000",
                  page_width: "8.26390",
                  x_Coordinate: "665.27996826171880",
                  y_Coordinate: "280.32000732421875",
                  width_Coordinate: "9.60003662109370",
                  height_Coordinate: "9.59997558593750",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Contract_Did",
        field_id: "91eee593-eb84-4818-9220-ab8a99f5f15a",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAPPLR",
            documentTypeDisplayName: "APPRAISAL REPORT",
            documentTypeShortCode: "APPLR",
            versions: [
              {
                documentId: "d8374348-522b-4228-9d26-36b620debc43",
                documentDisplayName: "APPRAISAL REPORT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "65bd697e-7a61-4318-8510-b2a85ef43e50",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.99000",
                  page_width: "8.26390",
                  x_Coordinate: "139.20001220703125",
                  y_Coordinate: "235.67999267578125",
                  width_Coordinate: "9.59997558593750",
                  height_Coordinate: "9.11999511718750",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":selected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":selected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Contract_Price",
        field_id: "dec1822d-894d-417d-99ee-80c7d543f8d5",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAPPLR",
            documentTypeDisplayName: "APPRAISAL REPORT",
            documentTypeShortCode: "APPLR",
            versions: [
              {
                documentId: "d8374348-522b-4228-9d26-36b620debc43",
                documentDisplayName: "APPRAISAL REPORT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "ec1c735f-2acf-4d03-8907-ebb4f56b7c54",
                  type: "String",
                  page_no: "1",
                  confidence: "0.99000",
                  page_width: "8.26390",
                  x_Coordinate: "184.31999206542970",
                  y_Coordinate: "269.27999877929690",
                  width_Coordinate: "31.19999694824218",
                  height_Coordinate: "10.55999755859370",
                  is_Truth: "1",
                  lastModifiedBy: "B5D71380-FABA-4528-A61C-8B8247C0BC1E",
                  lastModifiedDate: "2025-02-21T12:38:21.55",
                  content: "369,910as",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "369,910as",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Contract_Result",
        field_id: "8ae8dab3-35c8-47a0-8e47-0ba28710bb69",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAPPLR",
            documentTypeDisplayName: "APPRAISAL REPORT",
            documentTypeShortCode: "APPLR",
            versions: [
              {
                documentId: "d8374348-522b-4228-9d26-36b620debc43",
                documentDisplayName: "APPRAISAL REPORT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "4b2cf8bc-a99b-4295-bb6b-fabede157cda",
                  type: "String",
                  page_no: "1",
                  confidence: "0.91300",
                  page_width: "8.26390",
                  x_Coordinate: "173.27999877929688",
                  y_Coordinate: "246.72000122070312",
                  width_Coordinate: "155.51998901367187",
                  height_Coordinate: "10.07998657226563",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "Arms length sale;All terms appear typical.",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "Arms length sale;All terms appear typical.",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Contract_Date",
        field_id: "a1d665f2-8844-434d-ac38-e14d514c5315",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAPPLR",
            documentTypeDisplayName: "APPRAISAL REPORT",
            documentTypeShortCode: "APPLR",
            versions: [
              {
                documentId: "d8374348-522b-4228-9d26-36b620debc43",
                documentDisplayName: "APPRAISAL REPORT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "112ff04b-959c-46b7-9adc-f83b469b064c",
                  type: "String",
                  page_no: "1",
                  confidence: "0.99000",
                  page_width: "8.26390",
                  x_Coordinate: "290.40002441406250",
                  y_Coordinate: "268.79998779296875",
                  width_Coordinate: "42.71997070312500",
                  height_Coordinate: "10.08001708984375",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "11/20/2023",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "11/20/2023",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Owner_Public_No",
        field_id: "ae455335-bd7b-4349-b73f-5a37b438ad14",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAPPLR",
            documentTypeDisplayName: "APPRAISAL REPORT",
            documentTypeShortCode: "APPLR",
            versions: [
              {
                documentId: "d8374348-522b-4228-9d26-36b620debc43",
                documentDisplayName: "APPRAISAL REPORT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "d145c038-3975-42da-8373-ffaf43526f9f",
                  type: "Boolean",
                  page_no: "1",
                  confidence: "0.99100",
                  page_width: "8.26390",
                  x_Coordinate: "530.40002441406250",
                  y_Coordinate: "269.27999877929690",
                  width_Coordinate: "8.63995361328130",
                  height_Coordinate: "8.63998413085935",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: ":unselected:",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: ":unselected:",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "MIN",
        field_id: "4ec73b1a-7f49-47d4-8ceb-a027a9035140",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSCLODN",
            documentTypeDisplayName: "CLOSING DOCS NOTE",
            documentTypeShortCode: "CLODN",
            versions: [
              {
                documentId: "8f8faaa8-f2fd-4092-96cf-540cddac31a0",
                documentDisplayName: "CLOSING DOCS NOTE",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "c44d459e-3739-482c-9ccb-fac23690c08f",
                  type: "String",
                  page_no: "1",
                  confidence: "0.87300",
                  page_width: "8.26390",
                  x_Coordinate: "567.50402832031250",
                  y_Coordinate: "177.63839721679688",
                  width_Coordinate: "108.56634521484370",
                  height_Coordinate: "11.19360351562500",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "100670800041127796",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "100670800041127796",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "IssuingState",
        field_id: "d490b3bf-57b4-464f-a929-3a80bcf049a9",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSCLODN",
            documentTypeDisplayName: "CLOSING DOCS NOTE",
            documentTypeShortCode: "CLODN",
            versions: [
              {
                documentId: "8f8faaa8-f2fd-4092-96cf-540cddac31a0",
                documentDisplayName: "CLOSING DOCS NOTE",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "896ac211-ed73-4282-9169-ff978e53190f",
                  type: "Date",
                  page_no: "1",
                  confidence: "0.68600",
                  page_width: "8.26390",
                  x_Coordinate: "601.57440185546880",
                  y_Coordinate: "203.19360351562500",
                  width_Coordinate: "59.42401123046870",
                  height_Coordinate: "11.19360351562500",
                  is_Truth: "1",
                  lastModifiedBy: "B5D71380-FABA-4528-A61C-8B8247C0BC1E",
                  lastModifiedDate: "2025-02-21T12:38:42.5533333",
                  content: "2023-02-22",
                  isDeleted: "0",
                  has_Changed: "1",
                  original_Value: "COLORADO",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Case_Number",
        field_id: "534abe89-fa5d-47af-b439-80ab2af35f49",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSCLODN",
            documentTypeDisplayName: "CLOSING DOCS NOTE",
            documentTypeShortCode: "CLODN",
            versions: [
              {
                documentId: "8f8faaa8-f2fd-4092-96cf-540cddac31a0",
                documentDisplayName: "CLOSING DOCS NOTE",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "c6980eb5-f726-43e2-9405-ef777d4b15c3",
                  type: "String",
                  page_no: "1",
                  confidence: "0.52900",
                  page_width: "8.26390",
                  x_Coordinate: "544.62719726562500",
                  y_Coordinate: "129.45599365234375",
                  width_Coordinate: "91.07519531250000",
                  height_Coordinate: "11.19360351562500",
                  is_Truth: "0",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "098-1725940-703",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "098-1725940-703",
                },
              },
            ],
          },
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "2",
                always_present: "0",
                data: {
                  id: "597e4c1f-6e52-4b30-b082-b6f5c0d388e0",
                  type: "String",
                  page_no: "1",
                  confidence: "0.98800",
                  page_width: "8.26390",
                  x_Coordinate: "371.81759643554690",
                  y_Coordinate: "93.92639923095703",
                  width_Coordinate: "97.85278320312500",
                  height_Coordinate: "10.22400665283203",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "098-1725940-703",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "098-1725940-703",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "First_Payment_Date",
        field_id: "c3e139f5-8ce7-443b-9e52-e96c8f3343d1",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSCLODN",
            documentTypeDisplayName: "CLOSING DOCS NOTE",
            documentTypeShortCode: "CLODN",
            versions: [
              {
                documentId: "8f8faaa8-f2fd-4092-96cf-540cddac31a0",
                documentDisplayName: "CLOSING DOCS NOTE",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "1e45fdc8-96e2-4129-93bd-e35df4a19f11",
                  type: "String",
                  page_no: "1",
                  confidence: "0.96400",
                  page_width: "8.26390",
                  x_Coordinate: "540.64318847656250",
                  y_Coordinate: "506.16000366210940",
                  width_Coordinate: "108.62402343750000",
                  height_Coordinate: "14.11196899414060",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "MARCH 1, 2024.",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "MARCH 1, 2024.",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Maturity_Date",
        field_id: "1ebd8692-c344-42e1-bb48-f8fb03b861a3",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSCLODN",
            documentTypeDisplayName: "CLOSING DOCS NOTE",
            documentTypeShortCode: "CLODN",
            versions: [
              {
                documentId: "8f8faaa8-f2fd-4092-96cf-540cddac31a0",
                documentDisplayName: "CLOSING DOCS NOTE",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "dd5f0909-e590-4c80-afaf-887c85b953bb",
                  type: "String",
                  page_no: "1",
                  confidence: "0.94800",
                  page_width: "8.26390",
                  x_Coordinate: "404.45758056640625",
                  y_Coordinate: "549.47521972656250",
                  width_Coordinate: "124.58880615234375",
                  height_Coordinate: "14.11193847656250",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "FEBRUARY 1, 2054,",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "FEBRUARY 1, 2054,",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Interest",
        field_id: "9bf1d25e-bb16-4d4c-8d49-e870ee5436d4",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSCLODN",
            documentTypeDisplayName: "CLOSING DOCS NOTE",
            documentTypeShortCode: "CLODN",
            versions: [
              {
                documentId: "8f8faaa8-f2fd-4092-96cf-540cddac31a0",
                documentDisplayName: "CLOSING DOCS NOTE",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "a8e53fd3-d699-4760-a466-7cdb61f5dd2e",
                  type: "String",
                  page_no: "1",
                  confidence: "0.94800",
                  page_width: "8.26390",
                  x_Coordinate: "83.65440368652344",
                  y_Coordinate: "411.00482177734375",
                  width_Coordinate: "50.13119506835936",
                  height_Coordinate: "11.69277954101565",
                  is_Truth: "1",
                  lastModifiedBy: "B5D71380-FABA-4528-A61C-8B8247C0BC1E",
                  lastModifiedDate: "2025-02-21T12:49:52.1133333",
                  content: "01-04-2024",
                  isDeleted: "0",
                  has_Changed: "1",
                  original_Value: "7.750%.",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Serv_Number",
        field_id: "243d752b-b5f9-4843-9e29-c9367d36d8ad",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSCLODN",
            documentTypeDisplayName: "CLOSING DOCS NOTE",
            documentTypeShortCode: "CLODN",
            versions: [
              {
                documentId: "8f8faaa8-f2fd-4092-96cf-540cddac31a0",
                documentDisplayName: "CLOSING DOCS NOTE",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "2c9bdb97-e86c-45bd-be62-6cd63069550c",
                  type: "String",
                  page_no: "1",
                  confidence: "0.85800",
                  page_width: "8.26390",
                  x_Coordinate: "576.74877929687500",
                  y_Coordinate: "166.44480895996094",
                  width_Coordinate: "60.39361572265620",
                  height_Coordinate: "10.71359252929686",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "3010310791",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "3010310791",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Issued_Date",
        field_id: "cce1ebb5-3ac2-4ae5-8ebe-68a8867efa39",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSCLODN",
            documentTypeDisplayName: "CLOSING DOCS NOTE",
            documentTypeShortCode: "CLODN",
            versions: [
              {
                documentId: "8f8faaa8-f2fd-4092-96cf-540cddac31a0",
                documentDisplayName: "CLOSING DOCS NOTE",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "76f5c8f6-a82c-480e-be37-6e86b6e99276",
                  type: "Date",
                  page_no: "1",
                  confidence: "0.78700",
                  page_width: "8.26390",
                  x_Coordinate: "122.16000366210938",
                  y_Coordinate: "201.97439575195312",
                  width_Coordinate: "113.40478515625000",
                  height_Coordinate: "13.63198852539063",
                  is_Truth: "1",
                  lastModifiedBy: "B5D71380-FABA-4528-A61C-8B8247C0BC1E",
                  lastModifiedDate: "2025-02-21T12:26:45.7066667",
                  content: "2024-01-04",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "2024-01-04",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "IssuingCity",
        field_id: "9bcf7732-be1d-4078-9c00-23eeff413903",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSCLODN",
            documentTypeDisplayName: "CLOSING DOCS NOTE",
            documentTypeShortCode: "CLODN",
            versions: [
              {
                documentId: "8f8faaa8-f2fd-4092-96cf-540cddac31a0",
                documentDisplayName: "CLOSING DOCS NOTE",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "07939993-792f-4a04-a4a7-4cbf3322b1c2",
                  type: "Date",
                  page_no: "1",
                  confidence: "0.85200",
                  page_width: "8.26390",
                  x_Coordinate: "378.17279052734375",
                  y_Coordinate: "202.70401000976562",
                  width_Coordinate: "51.15838623046875",
                  height_Coordinate: "12.16317749023438",
                  is_Truth: "1",
                  lastModifiedBy: "B5D71380-FABA-4528-A61C-8B8247C0BC1E",
                  lastModifiedDate: "2025-02-21T12:49:33.82",
                  content: "2024-01-04",
                  isDeleted: "0",
                  has_Changed: "1",
                  original_Value: "02-21-2025",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Pages",
        field_id: "b4926d2e-8154-4504-9690-1d3257367095",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSCLODN",
            documentTypeDisplayName: "CLOSING DOCS NOTE",
            documentTypeShortCode: "CLODN",
            versions: [
              {
                documentId: "8f8faaa8-f2fd-4092-96cf-540cddac31a0",
                documentDisplayName: "CLOSING DOCS NOTE",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "b1945c97-53ad-417b-9580-0a3d6dac22da",
                  type: "String",
                  page_no: "1",
                  confidence: "0.86600",
                  page_width: "8.26390",
                  x_Coordinate: "424.39682006835940",
                  y_Coordinate: "907.92956542968750",
                  width_Coordinate: "6.33599853515620",
                  height_Coordinate: "11.65447998046870",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "4",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "4",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Mortgage_CLTV",
        field_id: "11c97dc1-40da-4d34-89d2-b97e027f6a65",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "755eeef3-dc73-41f6-9df0-0294faacebcd",
                  type: "String",
                  page_no: "6",
                  confidence: "0.98200",
                  page_width: "8.26390",
                  x_Coordinate: "646.83837890625000",
                  y_Coordinate: "492.27841186523440",
                  width_Coordinate: "37.54565429687500",
                  height_Coordinate: "12.17282104492185",
                  is_Truth: "0",
                  lastModifiedBy: "B5D71380-FABA-4528-A61C-8B8247C0BC1E",
                  lastModifiedDate: "2025-02-21T13:33:50.1733333",
                  content: "test1",
                  isDeleted: "0",
                  has_Changed: "1",
                  original_Value: "test",
                },
              },
            ],
          },
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "2",
                always_present: "0",
                data: {
                  id: "f6c483a3-c5aa-443a-a68d-ad69fe742b63",
                  type: "String",
                  page_no: "1",
                  confidence: "0.98400",
                  page_width: "8.26390",
                  x_Coordinate: "601.57440185546880",
                  y_Coordinate: "530.48638916015620",
                  width_Coordinate: "33.09124755859370",
                  height_Coordinate: "7.78558349609380",
                  is_Truth: "1",
                  lastModifiedBy: "B5D71380-FABA-4528-A61C-8B8247C0BC1E",
                  lastModifiedDate: "2025-02-21T12:17:47.5",
                  content: "96.500",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "96.500",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Funds_Required",
        field_id: "9aaf2c47-3c0c-4f30-93eb-481d131c18e0",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "d6bd1a9f-3853-4c40-b40e-26c1a4e1fb7e",
                  type: "Decimal",
                  page_no: "7",
                  confidence: "0.98300",
                  page_width: "8.26390",
                  x_Coordinate: "338.26562500000000",
                  y_Coordinate: "28.71360015869141",
                  width_Coordinate: "51.10076904296875",
                  height_Coordinate: "11.68320083618164",
                  is_Truth: "0",
                  lastModifiedBy: "B5D71380-FABA-4528-A61C-8B8247C0BC1E",
                  lastModifiedDate: "2025-02-21T13:32:49.9633333",
                  content: "2000.30",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "2000.30",
                },
              },
            ],
          },
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "2",
                always_present: "0",
                data: {
                  id: "e8844a2e-6188-4bf2-9bf6-19cee94d72c3",
                  type: "Decimal",
                  page_no: "1",
                  confidence: "0.98300",
                  page_width: "8.26390",
                  x_Coordinate: "272.07360839843750",
                  y_Coordinate: "596.91839599609380",
                  width_Coordinate: "51.62878417968750",
                  height_Coordinate: "7.78558349609370",
                  is_Truth: "1",
                  lastModifiedBy: "B5D71380-FABA-4528-A61C-8B8247C0BC1E",
                  lastModifiedDate: "2025-02-21T11:29:37.9233333",
                  content: "15477.0",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "15477.0",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Recommendation",
        field_id: "823749be-abdc-4417-8e0b-1b3d786a1770",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "e93886a8-a636-4547-bfe9-281eb5b0cf30",
                  type: "String",
                  page_no: "6",
                  confidence: "0.96800",
                  page_width: "8.26390",
                  x_Coordinate: "254.54400634765625",
                  y_Coordinate: "317.80798339843750",
                  width_Coordinate: "70.67520141601565",
                  height_Coordinate: "13.14242553710940",
                  is_Truth: "1",
                  lastModifiedBy: "B5D71380-FABA-4528-A61C-8B8247C0BC1E",
                  lastModifiedDate: "2025-02-21T13:35:22.53",
                  content: "123",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "123",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Subordinate_Finance",
        field_id: "97790e2c-a2d5-4b5b-a2f4-2ee009decf16",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "96592d48-8c20-4437-a0fe-259c45458ad8",
                  type: "Decimal",
                  page_no: "6",
                  confidence: "0.98700",
                  page_width: "8.26390",
                  x_Coordinate: "254.33280944824220",
                  y_Coordinate: "597.16796875000000",
                  width_Coordinate: "27.47517395019530",
                  height_Coordinate: "11.68322753906250",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "$0.00",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "$0.00",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Mortgage_HCLTV",
        field_id: "8ad56f94-e3dd-4dbf-a19a-651e46ba626f",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "1786d01a-9bee-4d62-8803-22fcd1b3e7ea",
                  type: "String",
                  page_no: "6",
                  confidence: "0.98700",
                  page_width: "8.26390",
                  x_Coordinate: "658.53125000000000",
                  y_Coordinate: "507.36959838867190",
                  width_Coordinate: "32.84155273437500",
                  height_Coordinate: "11.18399047851560",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "0.00%",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "0.00%",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Loan_Payment_Frequency",
        field_id: "99f08b93-38f6-4f78-baa8-5c6de916efdf",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "d3331f26-4151-41ac-aad1-19eaecaf07ae",
                  type: "String",
                  page_no: "6",
                  confidence: "0.98500",
                  page_width: "8.26390",
                  x_Coordinate: "254.07360839843750",
                  y_Coordinate: "567.48480224609380",
                  width_Coordinate: "39.08160400390625",
                  height_Coordinate: "12.64324951171870",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "Monthly",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "Monthly",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Loan_Term",
        field_id: "4b688697-3601-4c0a-b7b7-a63aef4d434e",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "c25b8c83-df84-4725-bcfc-4fe4b3fc1c91",
                  type: "Integer",
                  page_no: "6",
                  confidence: "0.98900",
                  page_width: "8.26390",
                  x_Coordinate: "672.63360595703120",
                  y_Coordinate: "657.51361083984380",
                  width_Coordinate: "17.03039550781260",
                  height_Coordinate: "10.71356201171870",
                  is_Truth: "0",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "360",
                  isDeleted: "1",
                  has_Changed: "0",
                  original_Value: "360",
                },
              },
            ],
          },
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "2",
                always_present: "0",
                data: {
                  id: "94830855-427d-4a34-b408-8d0dfa42fd54",
                  type: "Integer",
                  page_no: "1",
                  confidence: "0.98900",
                  page_width: "8.26390",
                  x_Coordinate: "259.90078735351560",
                  y_Coordinate: "348.72000122070310",
                  width_Coordinate: "39.95523071289065",
                  height_Coordinate: "8.26559448242190",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "360/360",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "360/360",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Total_Loan_Amount",
        field_id: "5fc1c903-7b73-483d-b89e-437aa7b63d5a",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "d83ab238-5c94-4bf2-be04-3834aeb89aa0",
                  type: "Decimal",
                  page_no: "6",
                  confidence: "0.98700",
                  page_width: "8.26390",
                  x_Coordinate: "632.71679687500000",
                  y_Coordinate: "552.15356445312500",
                  width_Coordinate: "57.48480224609380",
                  height_Coordinate: "12.16326904296880",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "363199",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "363199",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Funds_Cash_Back",
        field_id: "d95133b0-0a0f-4946-9e6f-7d3564314408",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "57b81684-90e0-43f5-a4a8-41dbeca62f61",
                  type: "Decimal",
                  page_no: "7",
                  confidence: "0.98300",
                  page_width: "8.26390",
                  x_Coordinate: "362.11199951171875",
                  y_Coordinate: "61.81439971923828",
                  width_Coordinate: "27.74398803710935",
                  height_Coordinate: "11.19360351562500",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "$0.00",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "$0.00",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Funds_Available",
        field_id: "e35908ae-c582-4f58-83d8-67255751d3dc",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "e2800776-9c8f-4744-85f2-32d8e75e3183",
                  type: "Decimal",
                  page_no: "7",
                  confidence: "0.98300",
                  page_width: "8.26390",
                  x_Coordinate: "337.77600097656250",
                  y_Coordinate: "44.77439880371094",
                  width_Coordinate: "52.13763427734375",
                  height_Coordinate: "11.68320465087890",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "$20680.94",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "$20680.94",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Loan_Purpose",
        field_id: "0cc1a78b-a3f6-436d-ad0c-15c8b223bfe3",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "0260852a-e9e6-4aa1-87ad-33b57853915c",
                  type: "String",
                  page_no: "6",
                  confidence: "0.98300",
                  page_width: "8.26390",
                  x_Coordinate: "254.07360839843750",
                  y_Coordinate: "612.24963378906250",
                  width_Coordinate: "42.47039794921875",
                  height_Coordinate: "11.68316650390630",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "Purchase",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "Purchase",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Lien_Type",
        field_id: "f6b19e97-919d-4f49-b4a5-1047a3aa3414",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "d5cfcd79-22b6-4834-a278-347048f7b4f7",
                  type: "String",
                  page_no: "6",
                  confidence: "0.98200",
                  page_width: "8.26390",
                  x_Coordinate: "255.03359985351562",
                  y_Coordinate: "582.37438964843750",
                  width_Coordinate: "68.14080810546878",
                  height_Coordinate: "12.35522460937500",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "First Mortgage",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "First Mortgage",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Mortgage_LTV",
        field_id: "5b361ad3-50e7-44df-8c80-dcb8fdb90af1",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "5e44ad03-76ea-4798-8781-6067275054d0",
                  type: "String",
                  page_no: "6",
                  confidence: "0.98200",
                  page_width: "8.26390",
                  x_Coordinate: "600.59521484375000",
                  y_Coordinate: "492.52798461914060",
                  width_Coordinate: "37.96801757812500",
                  height_Coordinate: "11.68322753906250",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "96.50%",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "96.50%",
                },
              },
            ],
          },
          {
            documentTypeCode: "DTSFHAUT",
            documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
            documentTypeShortCode: "FHAUT",
            versions: [
              {
                documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "2",
                always_present: "0",
                data: {
                  id: "0827b87b-683a-4c14-96a8-9587cdf1a0d3",
                  type: "String",
                  page_no: "1",
                  confidence: "0.98100",
                  page_width: "8.26390",
                  x_Coordinate: "602.54400634765620",
                  y_Coordinate: "519.05279541015620",
                  width_Coordinate: "42.87359619140630",
                  height_Coordinate: "7.78558349609380",
                  is_Truth: "0",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "96.500%",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "96.500%",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Ballon_Payment",
        field_id: "102fa76c-8ae7-4598-afd3-63a520d5f2a2",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "1b91f62a-8899-42b1-a30c-60bd88af4d97",
                  type: "String",
                  page_no: "6",
                  confidence: "0.98800",
                  page_width: "8.26390",
                  x_Coordinate: "254.54400634765625",
                  y_Coordinate: "537.55200195312500",
                  width_Coordinate: "15.26397705078125",
                  height_Coordinate: "10.70397949218750",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "No",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "No",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Note_Rate",
        field_id: "baa4d1e6-89e2-48c1-ac6e-dbd2e105378c",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "2652c086-94f4-4374-a69f-613d285799e7",
                  type: "Decimal",
                  page_no: "6",
                  confidence: "0.98200",
                  page_width: "8.26390",
                  x_Coordinate: "654.14404296875000",
                  y_Coordinate: "611.76959228515620",
                  width_Coordinate: "36.66235351562500",
                  height_Coordinate: "12.65277099609380",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "7.750%",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "7.750%",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Loan_Number",
        field_id: "aea44550-47b7-4f2c-8864-f5f2054658d1",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "64f84369-601a-4513-9674-739e0579972b",
                  type: "String",
                  page_no: "6",
                  confidence: "0.98000",
                  page_width: "8.26390",
                  x_Coordinate: "254.54400634765625",
                  y_Coordinate: "347.98080444335940",
                  width_Coordinate: "42.41281127929685",
                  height_Coordinate: "12.17279052734370",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "4112779",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "4112779",
                },
              },
            ],
          },
          {
            documentTypeCode: "DTSCLODN",
            documentTypeDisplayName: "CLOSING DOCS NOTE",
            documentTypeShortCode: "CLODN",
            versions: [
              {
                documentId: "8f8faaa8-f2fd-4092-96cf-540cddac31a0",
                documentDisplayName: "CLOSING DOCS NOTE",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "2",
                always_present: "0",
                data: {
                  id: "479442f0-c2d4-4e53-956a-7410d21ae5d5",
                  type: "String",
                  page_no: "1",
                  confidence: "0.85300",
                  page_width: "8.26390",
                  x_Coordinate: "576.25915527343750",
                  y_Coordinate: "154.77120971679688",
                  width_Coordinate: "42.89282226562500",
                  height_Coordinate: "10.70399475097656",
                  is_Truth: "0",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "4112779",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "4112779",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Funds_Reserves",
        field_id: "0ed13444-2b19-432f-8711-2558832c0346",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "fda3c1cb-4250-4ed1-9224-760f743b6a48",
                  type: "Decimal",
                  page_no: "7",
                  confidence: "0.98200",
                  page_width: "8.26390",
                  x_Coordinate: "642.45123291015620",
                  y_Coordinate: "45.51359939575195",
                  width_Coordinate: "45.75354003906260",
                  height_Coordinate: "11.68320083618164",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "$5203.00",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "$5203.00",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Amortization_Type",
        field_id: "f86f4984-4045-4cfc-b345-1a701ee053ed",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "f88f1782-0bf6-4b65-9577-788f5172f2be",
                  type: "String",
                  page_no: "6",
                  confidence: "0.98300",
                  page_width: "8.26390",
                  x_Coordinate: "254.55358886718750",
                  y_Coordinate: "522.13439941406250",
                  width_Coordinate: "50.61120605468750",
                  height_Coordinate: "11.75036621093750",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "Fixed Rate",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "Fixed Rate",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Funds_Months_Reservces",
        field_id: "22cae60d-278e-449d-b380-70811b647120",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "4551e448-9e4c-441e-bf68-8b626080404a",
                  type: "Decimal",
                  page_no: "7",
                  confidence: "0.98900",
                  page_width: "8.26390",
                  x_Coordinate: "683.82720947265620",
                  y_Coordinate: "62.29439544677734",
                  width_Coordinate: "4.37756347656260",
                  height_Coordinate: "9.73440551757813",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "1",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "1",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Loan_Type",
        field_id: "54d83211-93ee-4ac2-aeec-769ee737218f",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "329c7ac1-8c53-4e2d-9853-8b73487d64e9",
                  type: "String",
                  page_no: "6",
                  confidence: "0.96500",
                  page_width: "8.26390",
                  x_Coordinate: "254.54400634765625",
                  y_Coordinate: "491.55841064453125",
                  width_Coordinate: "144.06719970703125",
                  height_Coordinate: "13.62237548828125",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "Federal Housing Administration",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "Federal Housing Administration",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Housing_Expense_Ratio",
        field_id: "d4da4311-ab76-40da-b0e0-d73d4bd9e6c1",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "cc535777-0247-496e-be9e-a1bccaaaecee",
                  type: "Decimal",
                  page_no: "6",
                  confidence: "0.98600",
                  page_width: "8.26390",
                  x_Coordinate: "650.23681640625000",
                  y_Coordinate: "701.07836914062500",
                  width_Coordinate: "38.45758056640620",
                  height_Coordinate: "11.19360351562500",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "30.69%",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "30.69%",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "DU_Version",
        field_id: "da9c69e5-d59a-4077-a293-9c0a970bb5ef",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "6efbb950-3156-4665-a196-af709c15fa59",
                  type: "String",
                  page_no: "6",
                  confidence: "0.98200",
                  page_width: "8.26390",
                  x_Coordinate: "537.32159423828120",
                  y_Coordinate: "363.80157470703125",
                  width_Coordinate: "39.42718505859380",
                  height_Coordinate: "10.70401000976565",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "DUG4.1",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "DUG4.1",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Funds_Net_Cash_Back",
        field_id: "2ee5a4fc-e49a-421f-98d9-c807a5f1cd01",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "51f2965b-ae48-4843-add3-b1c5c79461dc",
                  type: "Decimal",
                  page_no: "7",
                  confidence: "0.98400",
                  page_width: "8.26390",
                  x_Coordinate: "660.95043945312500",
                  y_Coordinate: "28.47360229492188",
                  width_Coordinate: "27.35998535156250",
                  height_Coordinate: "11.67359542846679",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "$0.00",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "$0.00",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Loan_Amount",
        field_id: "4af5a78f-a19d-4af9-81f0-2c3b8b0c368b",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "322b0553-ddc3-4155-807d-d356cdf73d5d",
                  type: "Decimal",
                  page_no: "6",
                  confidence: "0.98600",
                  page_width: "8.26390",
                  x_Coordinate: "632.71679687500000",
                  y_Coordinate: "522.46081542968750",
                  width_Coordinate: "57.48480224609380",
                  height_Coordinate: "11.67358398437500",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "$356953.00",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "$356953.00",
                },
              },
            ],
          },
          {
            documentTypeCode: "DTSCLODN",
            documentTypeDisplayName: "CLOSING DOCS NOTE",
            documentTypeShortCode: "CLODN",
            versions: [
              {
                documentId: "8f8faaa8-f2fd-4092-96cf-540cddac31a0",
                documentDisplayName: "CLOSING DOCS NOTE",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "2",
                always_present: "0",
                data: {
                  id: "6ab55dcc-60ee-4a64-af2e-0076bebdf33b",
                  type: "Decimal",
                  page_no: "1",
                  confidence: "0.94800",
                  page_width: "8.26390",
                  x_Coordinate: "448.26242065429690",
                  y_Coordinate: "303.20642089843750",
                  width_Coordinate: "84.19198608398430",
                  height_Coordinate: "14.60156250000000",
                  is_Truth: "0",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "363199",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "363199",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Qualifying_Rate",
        field_id: "c5adf932-4c45-4c30-9437-387866853350",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "ffc43f3d-d465-4b3d-9c20-ba9a666b4910",
                  type: "String",
                  page_no: "6",
                  confidence: "0.98700",
                  page_width: "8.26390",
                  x_Coordinate: "653.64483642578120",
                  y_Coordinate: "627.09118652343750",
                  width_Coordinate: "36.98876953125000",
                  height_Coordinate: "12.17279052734370",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "7.750%",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "7.750%",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "P&amp;I",
        field_id: "ca81598a-5e9c-435e-9b7c-4acd7a1bcec0",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "2df419d9-15ab-43be-9545-befc4946a491",
                  type: "Decimal",
                  page_no: "6",
                  confidence: "0.98400",
                  page_width: "8.26390",
                  x_Coordinate: "644.40002441406250",
                  y_Coordinate: "596.92797851562500",
                  width_Coordinate: "46.23358154296870",
                  height_Coordinate: "12.65283203125000",
                  is_Truth: "1",
                  lastModifiedBy: "B5D71380-FABA-4528-A61C-8B8247C0BC1E",
                  lastModifiedDate: "2025-02-21T13:07:44.8066667",
                  content: "2000.30",
                  isDeleted: "0",
                  has_Changed: "1",
                  original_Value: "$2602.00",
                },
              },
            ],
          },
          {
            documentTypeCode: "DTSCLODN",
            documentTypeDisplayName: "CLOSING DOCS NOTE",
            documentTypeShortCode: "CLODN",
            versions: [
              {
                documentId: "8f8faaa8-f2fd-4092-96cf-540cddac31a0",
                documentDisplayName: "CLOSING DOCS NOTE",
                documentVersionCode: "",
                documentVersionDisplayName: "",
                source_priority: "2",
                always_present: "0",
                data: {
                  id: "a4c0bbd3-fb45-4d58-96b4-fa9ae6cf884c",
                  type: "Decimal",
                  page_no: "1",
                  confidence: "0.90300",
                  page_width: "8.26390",
                  x_Coordinate: "374.27520751953125",
                  y_Coordinate: "626.37121582031250",
                  width_Coordinate: "70.57922363281250",
                  height_Coordinate: "14.60156250000000",
                  is_Truth: "0",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "2602",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "2602",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Total_Expense_Ratio",
        field_id: "8400834e-a4a8-4118-af60-11ee1c604176",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "9dbb53c0-979e-4686-965a-e4973bccf3d3",
                  type: "Decimal",
                  page_no: "6",
                  confidence: "0.98400",
                  page_width: "8.26390",
                  x_Coordinate: "650.24639892578120",
                  y_Coordinate: "717.38879394531250",
                  width_Coordinate: "38.59197998046880",
                  height_Coordinate: "11.18402099609370",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "34.86%",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "34.86%",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Buydown",
        field_id: "5b8507ec-37a9-46cd-8526-afb898215239",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "c7faaaea-dec9-43fa-bada-e8a2637b801e",
                  type: "String",
                  page_no: "6",
                  confidence: "0.98900",
                  page_width: "8.26390",
                  x_Coordinate: "254.22720336914062",
                  y_Coordinate: "657.76318359375000",
                  width_Coordinate: "16.06079101562498",
                  height_Coordinate: "10.70404052734380",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "No",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "No",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Community_Lending",
        field_id: "33bc6dba-34ca-4cd6-aa75-93a15ce2acbc",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "53a224b5-1e4a-4790-9b52-f1a6854dd442",
                  type: "String",
                  page_no: "6",
                  confidence: "0.98800",
                  page_width: "8.26390",
                  x_Coordinate: "254.54400634765625",
                  y_Coordinate: "552.64318847656250",
                  width_Coordinate: "15.25439453125000",
                  height_Coordinate: "10.21441650390630",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "No",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "No",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Case_FileId",
        field_id: "f6e9fb4b-9c5c-413a-aeb9-46a5ac4274da",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "5f4aa3b9-63d3-4341-af6d-f4ac33d40889",
                  type: "String",
                  page_no: "6",
                  confidence: "0.98300",
                  page_width: "8.26390",
                  x_Coordinate: "538.30078125000000",
                  y_Coordinate: "348.95999145507810",
                  width_Coordinate: "58.88641357421880",
                  height_Coordinate: "10.70401000976565",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "1659409846",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "1659409846",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Financed_MI_Amount",
        field_id: "3647eeee-bf89-4c34-9b1e-8b4cd8fb9f4e",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "f8a313dd-4da0-4e31-b2b5-f43322c2e0a8",
                  type: "Decimal",
                  page_no: "6",
                  confidence: "0.98700",
                  page_width: "8.26390",
                  x_Coordinate: "644.89916992187500",
                  y_Coordinate: "537.30236816406250",
                  width_Coordinate: "45.37927246093750",
                  height_Coordinate: "11.68322753906250",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "$6246.00",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "$6246.00",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "Dated",
        field_id: "f49e5bda-43b9-4b91-bdc9-e9feb6407216",
        entity_value: null,
        parent_field_id: null,
        children: [],
        documents: [
          {
            documentTypeCode: "DTSAUSDU",
            documentTypeDisplayName: "AUS-DU",
            documentTypeShortCode: "AUSDU",
            versions: [
              {
                documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                documentDisplayName: "FINAL",
                documentVersionCode: "DVAUSDUF0",
                documentVersionDisplayName: "USDUF0",
                source_priority: "1",
                always_present: "0",
                data: {
                  id: "762f961c-e6ed-4072-8750-f9896ed570a8",
                  type: "Date",
                  page_no: "6",
                  confidence: "0.98100",
                  page_width: "8.26390",
                  x_Coordinate: "255.03359985351562",
                  y_Coordinate: "362.09280395507810",
                  width_Coordinate: "97.83358764648438",
                  height_Coordinate: "13.63198852539065",
                  is_Truth: "1",
                  lastModifiedBy: "",
                  lastModifiedDate: null,
                  content: "2023-12-15",
                  isDeleted: "0",
                  has_Changed: "0",
                  original_Value: "2023-12-15",
                },
              },
            ],
          },
        ],
      },
      {
        field_name: "APPRAISAL_COMPANY",
        field_id: null,
        entity_value: "",
        parent_field_id: null,
        children: [
          {
            field_name: "Appraiser_State",
            field_id: "dbf57cf0-ed7a-4481-a191-265cf395d79d",
            parent_field_id: "6ace8e5b-0b2b-4193-942e-05bf174cd84a",
            parent_value: "APPRAISAL_COMPANY_2",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "e2af7e37-f990-41f1-958e-28a01c02d723",
                      type: "String",
                      page_no: "8",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "154.08000183105470",
                      y_Coordinate: "772.32000732421880",
                      width_Coordinate: "10.08000183105468",
                      height_Coordinate: "9.60003662109370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "FL",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "FL",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Appraised_Property",
            field_id: "122f1caa-0ce7-4803-95d8-7eb0ae410f45",
            parent_field_id: "6ace8e5b-0b2b-4193-942e-05bf174cd84a",
            parent_value: "APPRAISAL_COMPANY_2",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "8a9d91a3-8da8-42e9-badb-5badce9e2e5c",
                      type: "String",
                      page_no: "8",
                      confidence: "0.73900",
                      page_width: "8.26390",
                      x_Coordinate: "130.08000183105470",
                      y_Coordinate: "820.80004882812500",
                      width_Coordinate: "77.28001403808592",
                      height_Coordinate: "22.07995605468750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "3316 San Moise PI Plant City, FL 33567",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "3316 San Moise PI Plant City, FL 33567",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Appraiser_Sign_Date",
            field_id: "9a589b51-0daa-43b2-b568-9937b4ab958c",
            parent_field_id: "6ace8e5b-0b2b-4193-942e-05bf174cd84a",
            parent_value: "APPRAISAL_COMPANY_2",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "ebdb922e-dd73-4678-81bb-c8de9fce246d",
                      type: "Date",
                      page_no: "8",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "256.32000732421875",
                      y_Coordinate: "710.88000488281250",
                      width_Coordinate: "42.23999023437500",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "2023-11-28",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "2023-11-28",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Appraiser_Email",
            field_id: "bdded5dc-a67f-47cc-904f-a1e81ee75b18",
            parent_field_id: "6ace8e5b-0b2b-4193-942e-05bf174cd84a",
            parent_value: "APPRAISAL_COMPANY_2",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "a7e1c678-1ec0-45c5-8d32-309a595681ce",
                      type: "String",
                      page_no: "8",
                      confidence: "0.98700",
                      page_width: "8.26390",
                      x_Coordinate: "194.88000488281250",
                      y_Coordinate: "698.88000488281250",
                      width_Coordinate: "93.11999511718750",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "oblasioli@dsmurphy.com",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "oblasioli@dsmurphy.com",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "License",
            field_id: "c881876b-5886-4e97-a4a8-ab4733cc8a5b",
            parent_field_id: "6ace8e5b-0b2b-4193-942e-05bf174cd84a",
            parent_value: "APPRAISAL_COMPANY_2",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "8fb90864-cf48-4dd7-994d-a59c74e65898",
                      type: "String",
                      page_no: "24",
                      confidence: "0.00900",
                      page_width: "8.26390",
                      x_Coordinate: "260.16000366210940",
                      y_Coordinate: "405.59997558593750",
                      width_Coordinate: "288.48001098632810",
                      height_Coordinate: "18.72003173828125",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "FLORIDA REAL ESTATE APPRAISAL BD",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "FLORIDA REAL ESTATE APPRAISAL BD",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Appraiser_Effective_Date",
            field_id: "53919158-0da4-43fc-8de8-b81ad85650c9",
            parent_field_id: "6ace8e5b-0b2b-4193-942e-05bf174cd84a",
            parent_value: "APPRAISAL_COMPANY_2",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "8fb769ec-ff4d-4401-98fc-adb864f55ae2",
                      type: "Date",
                      page_no: "8",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "246.72000122070312",
                      y_Coordinate: "723.35998535156250",
                      width_Coordinate: "42.72000122070313",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "2023-11-28",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "2023-11-28",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Organization_Name",
            field_id: "e8a8bfce-b66a-48ff-8d53-c77f69e9c40a",
            parent_field_id: "6ace8e5b-0b2b-4193-942e-05bf174cd84a",
            parent_value: "APPRAISAL_COMPANY_2",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "043bcac7-afc6-4ae1-8f28-04941c978e11",
                      type: "String",
                      page_no: "8",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "202.55999755859375",
                      y_Coordinate: "649.44000244140620",
                      width_Coordinate: "103.67999267578125",
                      height_Coordinate: "9.59997558593760",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "DS Murphy Valuations, LLC",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "DS Murphy Valuations, LLC",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Appraiser_Phone",
            field_id: "5faf19ea-bc7a-4fc0-9c05-d3d151ffbd02",
            parent_field_id: "6ace8e5b-0b2b-4193-942e-05bf174cd84a",
            parent_value: "APPRAISAL_COMPANY_2",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "0265f182-b005-4772-830b-7ce39eb70135",
                      type: "String",
                      page_no: "8",
                      confidence: "0.98900",
                      page_width: "8.26390",
                      x_Coordinate: "213.11999511718750",
                      y_Coordinate: "686.40002441406250",
                      width_Coordinate: "52.79998779296875",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "813-773-3989",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "813-773-3989",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Consultant_Name",
            field_id: "d4c7fb6d-b20b-4256-bec5-da718a3f0cd2",
            parent_field_id: "6ace8e5b-0b2b-4193-942e-05bf174cd84a",
            parent_value: "APPRAISAL_COMPANY_2",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "36c2cb75-a08d-4471-8707-2842acb31339",
                      type: "String",
                      page_no: "8",
                      confidence: "0.97100",
                      page_width: "8.26390",
                      x_Coordinate: "157.91999816894530",
                      y_Coordinate: "636.47998046875000",
                      width_Coordinate: "51.83999633789064",
                      height_Coordinate: "9.60003662109380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Olivia Blasioli",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Olivia Blasioli",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Supervisor_Sign",
            field_id: "ba009c7e-1e34-4d54-a801-e5803435e7ed",
            parent_field_id: "6ace8e5b-0b2b-4193-942e-05bf174cd84a",
            parent_value: "APPRAISAL_COMPANY_2",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "08e72ec1-741a-443a-a68b-f78782e53c59",
                      type: "String",
                      page_no: "9",
                      confidence: "0.80100",
                      page_width: "8.26390",
                      x_Coordinate: "196.79998779296875",
                      y_Coordinate: "947.52001953125000",
                      width_Coordinate: "93.11999511718750",
                      height_Coordinate: "10.08001708984380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "oblasioli@dsmurphy.com",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "oblasioli@dsmurphy.com",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Appraiser_License_Expiry",
            field_id: "0072ada9-4772-4fc5-b2ca-e978eaf36524",
            parent_field_id: "6ace8e5b-0b2b-4193-942e-05bf174cd84a",
            parent_value: "APPRAISAL_COMPANY_2",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "a8bca34b-0abb-41ce-9d87-e4cefce392fc",
                      type: "Date",
                      page_no: "8",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "310.08001708984375",
                      y_Coordinate: "784.80004882812500",
                      width_Coordinate: "41.75997924804685",
                      height_Coordinate: "10.07995605468750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "2024-11-30",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "2024-11-30",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Appraiser_State_Certificate",
            field_id: "cdb920d7-fa7e-476e-9510-feddbb0b43a9",
            parent_field_id: "6ace8e5b-0b2b-4193-942e-05bf174cd84a",
            parent_value: "APPRAISAL_COMPANY_2",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "16d43962-f9f0-491f-920b-831fe998bb21",
                      type: "String",
                      page_no: "8",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "218.40000915527344",
                      y_Coordinate: "735.35998535156250",
                      width_Coordinate: "67.68000793457031",
                      height_Coordinate: "10.08001708984370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Cert Res RD8714",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Cert Res RD8714",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "ORGANIZATION_ADDRESS",
            field_id: "48a688cf-b959-4ef6-bb18-1c5008da67f4",
            parent_field_id: "6ace8e5b-0b2b-4193-942e-05bf174cd84a",
            parent_value: null,
            documents: null,
          },
        ],
        documents: null,
      },
      {
        field_name: "BORROWER",
        field_id: "699fe308-07be-45c0-bd15-1bcfef10d8db",
        entity_value: "BORROWER_1",
        parent_field_id: "560965f3-dc24-4df9-94d5-d52cf07b86c9",
        children: [
          {
            field_name: "Borrower_Income_Total",
            field_id: "b324ed14-61b7-4deb-b696-116ce0decd0c",
            parent_field_id: "699fe308-07be-45c0-bd15-1bcfef10d8db",
            parent_value: "BORROWER_1",
            documents: [
              {
                documentTypeCode: "DTSFHAUT",
                documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentTypeShortCode: "FHAUT",
                versions: [
                  {
                    documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                    documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "d165999f-02b6-4106-b275-88ed79a961a1",
                      type: "Decimal",
                      page_no: "1",
                      confidence: "0.98600",
                      page_width: "8.26390",
                      x_Coordinate: "236.05439758300780",
                      y_Coordinate: "429.02398681640625",
                      width_Coordinate: "45.31199645996095",
                      height_Coordinate: "8.26562500000000",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "7293.38",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "7293.38",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "SSN",
            field_id: "eebff21d-38f4-4749-855a-28308f1a0e43",
            parent_field_id: "699fe308-07be-45c0-bd15-1bcfef10d8db",
            parent_value: "BORROWER_1",
            documents: [
              {
                documentTypeCode: "DTSFHAUT",
                documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentTypeShortCode: "FHAUT",
                versions: [
                  {
                    documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                    documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "bcd0d2ac-c354-4485-a38c-9b00480ca4c2",
                      type: "String",
                      page_no: "1",
                      confidence: "0.98700",
                      page_width: "8.26390",
                      x_Coordinate: "577.23840332031250",
                      y_Coordinate: "113.15519714355469",
                      width_Coordinate: "63.78240966796870",
                      height_Coordinate: "7.29599761962891",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "590-75-1669",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "590-75-1669",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "LDP_Yes",
            field_id: "7c1d83c7-2075-4ec7-953e-9de6a0b1d10b",
            parent_field_id: "699fe308-07be-45c0-bd15-1bcfef10d8db",
            parent_value: "BORROWER_1",
            documents: [
              {
                documentTypeCode: "DTSFHAUT",
                documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentTypeShortCode: "FHAUT",
                versions: [
                  {
                    documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                    documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "f798f70f-aba5-44c6-ba1c-685433cc56d8",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.98200",
                      page_width: "8.26390",
                      x_Coordinate: "436.66561889648440",
                      y_Coordinate: "621.48480224609380",
                      width_Coordinate: "10.34878540039060",
                      height_Coordinate: "10.22399902343740",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Borrower_Income_Base",
            field_id: "b4dfd13f-bc57-4f68-a5f6-a5f25075001b",
            parent_field_id: "699fe308-07be-45c0-bd15-1bcfef10d8db",
            parent_value: "BORROWER_1",
            documents: [
              {
                documentTypeCode: "DTSFHAUT",
                documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentTypeShortCode: "FHAUT",
                versions: [
                  {
                    documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                    documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "d7cab82b-ed5e-41ed-9e86-a29a0947eafe",
                      type: "Decimal",
                      page_no: "1",
                      confidence: "0.98400",
                      page_width: "8.26390",
                      x_Coordinate: "234.59521484375000",
                      y_Coordinate: "396.65283203125000",
                      width_Coordinate: "47.69277954101560",
                      height_Coordinate: "9.24475097656250",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "5740.87",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "5740.87",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Borrower_Income_Rental",
            field_id: "d4b4889a-7196-4be9-a2ec-b02e7ecadd02",
            parent_field_id: "699fe308-07be-45c0-bd15-1bcfef10d8db",
            parent_value: "BORROWER_1",
            documents: [
              {
                documentTypeCode: "DTSFHAUT",
                documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentTypeShortCode: "FHAUT",
                versions: [
                  {
                    documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                    documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "ff710043-b410-4349-9b28-b9107c38eb1c",
                      type: "Decimal",
                      page_no: "1",
                      confidence: "0.98200",
                      page_width: "8.26390",
                      x_Coordinate: "258.93121337890625",
                      y_Coordinate: "418.55041503906250",
                      width_Coordinate: "22.38717651367185",
                      height_Coordinate: "8.27520751953125",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "0",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "0",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Borrower_Income_Other",
            field_id: "6b977533-5b49-4a07-a30a-caef7d5555b9",
            parent_field_id: "699fe308-07be-45c0-bd15-1bcfef10d8db",
            parent_value: "BORROWER_1",
            documents: [
              {
                documentTypeCode: "DTSFHAUT",
                documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentTypeShortCode: "FHAUT",
                versions: [
                  {
                    documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                    documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "0ec25520-8a4e-431f-b636-d149e0b15dfc",
                      type: "Decimal",
                      page_no: "1",
                      confidence: "0.98300",
                      page_width: "8.26390",
                      x_Coordinate: "235.56478881835938",
                      y_Coordinate: "407.35681152343750",
                      width_Coordinate: "46.80960083007812",
                      height_Coordinate: "8.26559448242190",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "1552.51",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "1552.51",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "LDP_No",
            field_id: "4c1d526c-c953-4f2f-8ede-df3b88a8c1c2",
            parent_field_id: "699fe308-07be-45c0-bd15-1bcfef10d8db",
            parent_value: "BORROWER_1",
            documents: [
              {
                documentTypeCode: "DTSFHAUT",
                documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentTypeShortCode: "FHAUT",
                versions: [
                  {
                    documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                    documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "8d1e5572-80b7-4f50-bf3b-debc3ba6a77c",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.98300",
                      page_width: "8.26390",
                      x_Coordinate: "472.32000732421875",
                      y_Coordinate: "621.09118652343750",
                      width_Coordinate: "10.99200439453125",
                      height_Coordinate: "10.61761474609370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Name",
            field_id: "482f80e0-40aa-4dd1-9f37-e1de184db92b",
            parent_field_id: "699fe308-07be-45c0-bd15-1bcfef10d8db",
            parent_value: "BORROWER_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "3",
                    always_present: "0",
                    data: {
                      id: "1ecb2f02-2c88-4705-8f1c-bf7a6418b78b",
                      type: "String",
                      page_no: "1",
                      confidence: "0.98000",
                      page_width: "8.26390",
                      x_Coordinate: "163.68000793457030",
                      y_Coordinate: "109.44000244140625",
                      width_Coordinate: "118.55998229980470",
                      height_Coordinate: "10.55999755859375",
                      is_Truth: "0",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "John Bennett &amp; Denna Bennett",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "John Bennett &amp; Denna Bennett",
                    },
                  },
                ],
              },
              {
                documentTypeCode: "DTSAUSDU",
                documentTypeDisplayName: "AUS-DU",
                documentTypeShortCode: "AUSDU",
                versions: [
                  {
                    documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                    documentDisplayName: "FINAL",
                    documentVersionCode: "DVAUSDUF0",
                    documentVersionDisplayName: "USDUF0",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "65af9587-4565-4411-ab86-96bfd05da121",
                      type: "String",
                      page_no: "6",
                      confidence: "0.92000",
                      page_width: "8.26390",
                      x_Coordinate: "254.54400634765625",
                      y_Coordinate: "332.40960693359375",
                      width_Coordinate: "99.29278564453125",
                      height_Coordinate: "13.14239501953125",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "John Michael Bennett",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "John Michael Bennett",
                    },
                  },
                ],
              },
              {
                documentTypeCode: "DTSCLODN",
                documentTypeDisplayName: "CLOSING DOCS NOTE",
                documentTypeShortCode: "CLODN",
                versions: [
                  {
                    documentId: "8f8faaa8-f2fd-4092-96cf-540cddac31a0",
                    documentDisplayName: "CLOSING DOCS NOTE",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "2",
                    always_present: "0",
                    data: {
                      id: "a01c04b9-b80e-4694-ab50-f728731d5906",
                      type: "String",
                      page_no: "3",
                      confidence: "0.64200",
                      page_width: "8.26390",
                      x_Coordinate: "169.31520080566406",
                      y_Coordinate: "812.21759033203120",
                      width_Coordinate: "156.77760314941404",
                      height_Coordinate: "12.71044921875000",
                      is_Truth: "0",
                      lastModifiedBy: "B5D71380-FABA-4528-A61C-8B8247C0BC1E",
                      lastModifiedDate: "2025-02-21T05:27:34.6166667",
                      content: "test",
                      isDeleted: "0",
                      has_Changed: "1",
                      original_Value: "JOHN MICHAEL BENNETT",
                    },
                  },
                ],
              },
              {
                documentTypeCode: "DTSFHAUT",
                documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentTypeShortCode: "FHAUT",
                versions: [
                  {
                    documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                    documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "4",
                    always_present: "0",
                    data: {
                      id: "1ebdc057-f790-47f4-9060-51cb4c24ffd2",
                      type: "String",
                      page_no: "1",
                      confidence: "0.97400",
                      page_width: "8.26390",
                      x_Coordinate: "209.27999877929688",
                      y_Coordinate: "110.47679901123047",
                      width_Coordinate: "116.81280517578122",
                      height_Coordinate: "9.73439788818359",
                      is_Truth: "0",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "John Michael Bennett",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "John Michael Bennett",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Signed_Date",
            field_id: "0ff15abe-3ee2-42a5-b03d-eaa197cc302f",
            parent_field_id: "699fe308-07be-45c0-bd15-1bcfef10d8db",
            parent_value: "BORROWER_1",
            documents: [
              {
                documentTypeCode: "DTSCLODN",
                documentTypeDisplayName: "CLOSING DOCS NOTE",
                documentTypeShortCode: "CLODN",
                versions: [
                  {
                    documentId: "8f8faaa8-f2fd-4092-96cf-540cddac31a0",
                    documentDisplayName: "CLOSING DOCS NOTE",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "b957a6ec-2495-404a-8c65-5c63031c016b",
                      type: "Date",
                      page_no: "7",
                      confidence: "0.80500",
                      page_width: "8.26390",
                      x_Coordinate: "288.61441040039060",
                      y_Coordinate: "788.92803955078120",
                      width_Coordinate: "68.76480102539065",
                      height_Coordinate: "18.97918701171880",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "2023-12-21",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "2023-12-21",
                    },
                  },
                ],
              },
            ],
          },
        ],
        documents: null,
      },
      {
        field_name: "BORROWER",
        field_id: "699fe308-07be-45c0-bd15-1bcfef10d8db",
        entity_value: "BORROWER_2",
        parent_field_id: "560965f3-dc24-4df9-94d5-d52cf07b86c9",
        children: [
          {
            field_name: "Borrower_Income_Total",
            field_id: "b324ed14-61b7-4deb-b696-116ce0decd0c",
            parent_field_id: "699fe308-07be-45c0-bd15-1bcfef10d8db",
            parent_value: "BORROWER_2",
            documents: [
              {
                documentTypeCode: "DTSFHAUT",
                documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentTypeShortCode: "FHAUT",
                versions: [
                  {
                    documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                    documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "4934c93b-a7a2-4f0d-90be-2a59892a8ab0",
                      type: "Decimal",
                      page_no: "1",
                      confidence: "0.90800",
                      page_width: "8.26390",
                      x_Coordinate: "300.29760742187500",
                      y_Coordinate: "428.77441406250000",
                      width_Coordinate: "45.26400756835940",
                      height_Coordinate: "8.75518798828125",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "3140.6",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "3140.6",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "SSN",
            field_id: "eebff21d-38f4-4749-855a-28308f1a0e43",
            parent_field_id: "699fe308-07be-45c0-bd15-1bcfef10d8db",
            parent_value: "BORROWER_2",
            documents: [
              {
                documentTypeCode: "DTSFHAUT",
                documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentTypeShortCode: "FHAUT",
                versions: [
                  {
                    documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                    documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "957f80ee-4afb-47f4-a989-8b9190858ce2",
                      type: "String",
                      page_no: "1",
                      confidence: "0.93800",
                      page_width: "8.26390",
                      x_Coordinate: "577.68957519531250",
                      y_Coordinate: "122.40959930419922",
                      width_Coordinate: "63.30242919921870",
                      height_Coordinate: "7.77599334716798",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "592-57-8547",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "592-57-8547",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "LDP_Yes",
            field_id: "7c1d83c7-2075-4ec7-953e-9de6a0b1d10b",
            parent_field_id: "699fe308-07be-45c0-bd15-1bcfef10d8db",
            parent_value: "BORROWER_2",
            documents: [
              {
                documentTypeCode: "DTSFHAUT",
                documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentTypeShortCode: "FHAUT",
                versions: [
                  {
                    documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                    documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "ae014464-c04c-4fd6-a1bd-19939a917014",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.98400",
                      page_width: "8.26390",
                      x_Coordinate: "513.02398681640620",
                      y_Coordinate: "621.22558593750000",
                      width_Coordinate: "10.73284912109380",
                      height_Coordinate: "10.48321533203120",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Borrower_Income_Base",
            field_id: "b4dfd13f-bc57-4f68-a5f6-a5f25075001b",
            parent_field_id: "699fe308-07be-45c0-bd15-1bcfef10d8db",
            parent_value: "BORROWER_2",
            documents: [
              {
                documentTypeCode: "DTSFHAUT",
                documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentTypeShortCode: "FHAUT",
                versions: [
                  {
                    documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                    documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "61e6654f-355b-48b4-b148-6317db936516",
                      type: "Decimal",
                      page_no: "1",
                      confidence: "0.91700",
                      page_width: "8.26390",
                      x_Coordinate: "300.29760742187500",
                      y_Coordinate: "396.65283203125000",
                      width_Coordinate: "46.23358154296875",
                      height_Coordinate: "8.75518798828125",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "3140.6",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "3140.6",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Borrower_Income_Rental",
            field_id: "d4b4889a-7196-4be9-a2ec-b02e7ecadd02",
            parent_field_id: "699fe308-07be-45c0-bd15-1bcfef10d8db",
            parent_value: "BORROWER_2",
            documents: [
              {
                documentTypeCode: "DTSFHAUT",
                documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentTypeShortCode: "FHAUT",
                versions: [
                  {
                    documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                    documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "e8905027-e230-48e6-b57a-f861ec937e23",
                      type: "Decimal",
                      page_no: "1",
                      confidence: "0.96400",
                      page_width: "8.26390",
                      x_Coordinate: "324.14398193359375",
                      y_Coordinate: "418.55041503906250",
                      width_Coordinate: "21.41763305664065",
                      height_Coordinate: "8.27520751953125",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "0",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "0",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Borrower_Income_Other",
            field_id: "6b977533-5b49-4a07-a30a-caef7d5555b9",
            parent_field_id: "699fe308-07be-45c0-bd15-1bcfef10d8db",
            parent_value: "BORROWER_2",
            documents: [
              {
                documentTypeCode: "DTSFHAUT",
                documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentTypeShortCode: "FHAUT",
                versions: [
                  {
                    documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                    documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "ea8ce7c7-0ea5-4519-9ce0-00829c526cdc",
                      type: "Decimal",
                      page_no: "1",
                      confidence: "0.98200",
                      page_width: "8.26390",
                      x_Coordinate: "323.66400146484375",
                      y_Coordinate: "407.60641479492190",
                      width_Coordinate: "29.75039672851565",
                      height_Coordinate: "8.26556396484370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "0",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "0",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "LDP_No",
            field_id: "4c1d526c-c953-4f2f-8ede-df3b88a8c1c2",
            parent_field_id: "699fe308-07be-45c0-bd15-1bcfef10d8db",
            parent_value: "BORROWER_2",
            documents: [
              {
                documentTypeCode: "DTSFHAUT",
                documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentTypeShortCode: "FHAUT",
                versions: [
                  {
                    documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                    documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "9f58c83c-b337-4566-bf32-9ec780a57150",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.98300",
                      page_width: "8.26390",
                      x_Coordinate: "548.01599121093750",
                      y_Coordinate: "621.22558593750000",
                      width_Coordinate: "11.00158691406250",
                      height_Coordinate: "10.35839843750000",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Name",
            field_id: "482f80e0-40aa-4dd1-9f37-e1de184db92b",
            parent_field_id: "699fe308-07be-45c0-bd15-1bcfef10d8db",
            parent_value: "BORROWER_2",
            documents: [
              {
                documentTypeCode: "DTSAUSDU",
                documentTypeDisplayName: "AUS-DU",
                documentTypeShortCode: "AUSDU",
                versions: [
                  {
                    documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                    documentDisplayName: "FINAL",
                    documentVersionCode: "DVAUSDUF0",
                    documentVersionDisplayName: "USDUF0",
                    source_priority: "2",
                    always_present: "0",
                    data: {
                      id: "426e94c1-c7a8-42b9-93d0-86a677ea954a",
                      type: "String",
                      page_no: "6",
                      confidence: "0.97000",
                      page_width: "8.26390",
                      x_Coordinate: "537.32159423828120",
                      y_Coordinate: "333.37921142578125",
                      width_Coordinate: "106.10882568359380",
                      height_Coordinate: "11.68316650390625",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Denna Lemelin Bennett",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Denna Lemelin Bennett",
                    },
                  },
                ],
              },
              {
                documentTypeCode: "DTSCLODN",
                documentTypeDisplayName: "CLOSING DOCS NOTE",
                documentTypeShortCode: "CLODN",
                versions: [
                  {
                    documentId: "8f8faaa8-f2fd-4092-96cf-540cddac31a0",
                    documentDisplayName: "CLOSING DOCS NOTE",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "39d4ac8a-8324-48eb-811d-0ab436ea3009",
                      type: "String",
                      page_no: "3",
                      confidence: "0.41200",
                      page_width: "8.26390",
                      x_Coordinate: "168.88319396972656",
                      y_Coordinate: "866.79364013671880",
                      width_Coordinate: "164.99522399902344",
                      height_Coordinate: "12.65277099609370",
                      is_Truth: "0",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "DENNA LEMELIN BENNETT",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "DENNA LEMELIN BENNETT",
                    },
                  },
                ],
              },
              {
                documentTypeCode: "DTSFHAUT",
                documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentTypeShortCode: "FHAUT",
                versions: [
                  {
                    documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                    documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "3",
                    always_present: "0",
                    data: {
                      id: "c9ccb5f2-9d41-4a61-a8a5-c0c041f33b6b",
                      type: "String",
                      page_no: "1",
                      confidence: "0.92000",
                      page_width: "8.26390",
                      x_Coordinate: "212.68801879882812",
                      y_Coordinate: "120.70080566406250",
                      width_Coordinate: "123.13919067382813",
                      height_Coordinate: "9.24479675292970",
                      is_Truth: "0",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Denna Lemelin Bennett",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Denna Lemelin Bennett",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Signed_Date",
            field_id: "0ff15abe-3ee2-42a5-b03d-eaa197cc302f",
            parent_field_id: "699fe308-07be-45c0-bd15-1bcfef10d8db",
            parent_value: "BORROWER_2",
            documents: [
              {
                documentTypeCode: "DTSCLODN",
                documentTypeDisplayName: "CLOSING DOCS NOTE",
                documentTypeShortCode: "CLODN",
                versions: [
                  {
                    documentId: "8f8faaa8-f2fd-4092-96cf-540cddac31a0",
                    documentDisplayName: "CLOSING DOCS NOTE",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "2e155585-222c-4310-9bae-b18a184ad002",
                      type: "Date",
                      page_no: "7",
                      confidence: "0.52000",
                      page_width: "8.26390",
                      x_Coordinate: "289.59359741210940",
                      y_Coordinate: "846.84472656250000",
                      width_Coordinate: "67.21920776367185",
                      height_Coordinate: "15.08166503906250",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "2023-12-21",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "2023-12-21",
                    },
                  },
                ],
              },
            ],
          },
        ],
        documents: null,
      },
      {
        field_name: "LOAN_ORIGINATOR",
        field_id: "6ace8e5b-0b2b-4193-942e-05bf174cd84a",
        entity_value: "LOAN_ORIGINATOR_1",
        parent_field_id: "560965f3-dc24-4df9-94d5-d52cf07b86c9",
        children: [
          {
            field_name: "Loan_Originator_NMLSR_Id",
            field_id: "9a8295c2-ba3c-49ad-9ba6-075784dbb45c",
            parent_field_id: "6ace8e5b-0b2b-4193-942e-05bf174cd84a",
            parent_value: "LOAN_ORIGINATOR_1",
            documents: [
              {
                documentTypeCode: "DTSCLODN",
                documentTypeDisplayName: "CLOSING DOCS NOTE",
                documentTypeShortCode: "CLODN",
                versions: [
                  {
                    documentId: "8f8faaa8-f2fd-4092-96cf-540cddac31a0",
                    documentDisplayName: "CLOSING DOCS NOTE",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "c8011925-9a50-4e18-bd21-4f112c78605b",
                      type: "String",
                      page_no: "8",
                      confidence: "0.95800",
                      page_width: "8.26390",
                      x_Coordinate: "366.33599853515625",
                      y_Coordinate: "110.96640014648438",
                      width_Coordinate: "44.43841552734375",
                      height_Coordinate: "13.62239074707031",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "989282",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "989282",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Organization_Name",
            field_id: "e8a8bfce-b66a-48ff-8d53-c77f69e9c40a",
            parent_field_id: "6ace8e5b-0b2b-4193-942e-05bf174cd84a",
            parent_value: "LOAN_ORIGINATOR_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "5617f139-0473-44e8-af41-fb3e3391372e",
                      type: "String",
                      page_no: "8",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "202.08000183105470",
                      y_Coordinate: "886.07995605468750",
                      width_Coordinate: "98.87998962402340",
                      height_Coordinate: "11.04003906250000",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Movement Mortgage, LLC",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Movement Mortgage, LLC",
                    },
                  },
                ],
              },
              {
                documentTypeCode: "DTSCLODN",
                documentTypeDisplayName: "CLOSING DOCS NOTE",
                documentTypeShortCode: "CLODN",
                versions: [
                  {
                    documentId: "8f8faaa8-f2fd-4092-96cf-540cddac31a0",
                    documentDisplayName: "CLOSING DOCS NOTE",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "2",
                    always_present: "0",
                    data: {
                      id: "bda3c4be-69b1-4f39-bad7-7763f1f4838d",
                      type: "String",
                      page_no: "8",
                      confidence: "0.92000",
                      page_width: "8.26390",
                      x_Coordinate: "223.88159179687500",
                      y_Coordinate: "125.56799316406250",
                      width_Coordinate: "170.35198974609375",
                      height_Coordinate: "14.11201477050780",
                      is_Truth: "0",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "MOVEMENT MORTGAGE, LLC,",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "MOVEMENT MORTGAGE, LLC,",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Loan_Originator_Name",
            field_id: "16607d94-04ed-4702-a2b8-ceee526702b9",
            parent_field_id: "6ace8e5b-0b2b-4193-942e-05bf174cd84a",
            parent_value: "LOAN_ORIGINATOR_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "d5746a58-a5a2-42f9-8ed4-77765059e34e",
                      type: "String",
                      page_no: "8",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "157.44000244140625",
                      y_Coordinate: "874.07995605468750",
                      width_Coordinate: "117.11999511718750",
                      height_Coordinate: "10.56005859375000",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Karis Management Group, LLC",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Karis Management Group, LLC",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Organization_NMLSR_Id",
            field_id: "1587b281-f19c-4dac-9d38-d7061e627c23",
            parent_field_id: "6ace8e5b-0b2b-4193-942e-05bf174cd84a",
            parent_value: "LOAN_ORIGINATOR_1",
            documents: [
              {
                documentTypeCode: "DTSCLODN",
                documentTypeDisplayName: "CLOSING DOCS NOTE",
                documentTypeShortCode: "CLODN",
                versions: [
                  {
                    documentId: "8f8faaa8-f2fd-4092-96cf-540cddac31a0",
                    documentDisplayName: "CLOSING DOCS NOTE",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "211129dc-7406-4f0a-8460-ca83532a30c4",
                      type: "String",
                      page_no: "4",
                      confidence: "0.90700",
                      page_width: "8.26390",
                      x_Coordinate: "463.74719238281250",
                      y_Coordinate: "126.04799652099610",
                      width_Coordinate: "37.56481933593750",
                      height_Coordinate: "12.66240692138670",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "39179",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "39179",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "ORGANIZATION_ADDRESS",
            field_id: "48a688cf-b959-4ef6-bb18-1c5008da67f4",
            parent_field_id: "6ace8e5b-0b2b-4193-942e-05bf174cd84a",
            parent_value: null,
            documents: null,
          },
        ],
        documents: null,
      },
      {
        field_name: "SUBJECT_PROPERTY",
        field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
        entity_value: "SUBJECT_PROPERTY_1",
        parent_field_id: "560965f3-dc24-4df9-94d5-d52cf07b86c9",
        children: [
          {
            field_name: "Improve_Heating_HWBB",
            field_id: "d6daa5bf-cdfc-4d95-81df-01098c5b0b60",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "865ec75b-94c4-4194-a07c-e43d757cff75",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99200",
                      page_width: "8.26390",
                      x_Coordinate: "334.08001708984375",
                      y_Coordinate: "728.16003417968750",
                      width_Coordinate: "8.63998413085935",
                      height_Coordinate: "9.11993408203130",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "PUD_Rider",
            field_id: "b702c101-e4e7-4e8b-9754-027bc3f583ca",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "ae803d68-ba4a-442e-b969-d2349e23bf63",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "504.96002197265625",
                      y_Coordinate: "155.04000854492188",
                      width_Coordinate: "9.59997558593755",
                      height_Coordinate: "9.59999084472656",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Income_Comments1",
            field_id: "c30c97fd-efb1-4175-82f9-040dd76e8e2a",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "f0b2ff1c-77a0-4e3d-a1e7-29d26ba28788",
                      type: "String",
                      page_no: "5",
                      confidence: "0.74800",
                      page_width: "8.26390",
                      x_Coordinate: "131.04000854492188",
                      y_Coordinate: "763.19995117187500",
                      width_Coordinate: "546.23995971679692",
                      height_Coordinate: "21.12005615234380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content:
                        "The income approach is not considered relevant in the sale of existing homes, is not requested by the client, and is therefore not utilized.",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value:
                        "The income approach is not considered relevant in the sale of existing homes, is not requested by the client, and is therefore not utilized.",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Sales_Comparison_Comments2",
            field_id: "37ce1d40-0a96-4aee-aa24-0489a85073f6",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "9cd5b797-c9b7-4701-bba2-076d751a6007",
                      type: "String",
                      page_no: "2",
                      confidence: "0.03400",
                      page_width: "8.26390",
                      x_Coordinate: "131.52000427246094",
                      y_Coordinate: "826.55993652343750",
                      width_Coordinate: "557.75996398925786",
                      height_Coordinate: "46.56005859375000",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content:
                        "Indicated Value by Sales Comparison Approach $ 370,000 Sales Comparison Approach $ 370,000 Cost Approach (if developed) $ Income Approach (if developed) $ The sales comparison approach was given the most consideration as it is a direct representation of buyers and sellers in the market. The cost approach was considered but not developed as it is not a good indicator of value in the resale of existing homes. The income approach was considered, but not developed because it is not a good indicator of value of the sale of existing",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value:
                        "Indicated Value by Sales Comparison Approach $ 370,000 Sales Comparison Approach $ 370,000 Cost Approach (if developed) $ Income Approach (if developed) $ The sales comparison approach was given the most consideration as it is a direct representation of buyers and sellers in the market. The cost approach was considered but not developed as it is not a good indicator of value in the resale of existing homes. The income approach was considered, but not developed because it is not a good indicator of value of the sale of existing",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_1_Unit_Trend_Demand_InBalance",
            field_id: "63c3a022-ea40-46df-a951-05406afe8265",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "f27cedf9-7c66-4358-950c-adc91fccbd67",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "423.35998535156250",
                      y_Coordinate: "361.91998291015625",
                      width_Coordinate: "9.12002563476560",
                      height_Coordinate: "9.12002563476565",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_Char_Growth_Slow",
            field_id: "e9d83b3f-9017-487b-b6bc-054f051a4df7",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "525cec17-81c5-4fdc-9c7a-2cb99dc3947f",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99200",
                      page_width: "8.26390",
                      x_Coordinate: "261.11999511718750",
                      y_Coordinate: "373.44000244140625",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_Char_Location_Suburban",
            field_id: "a8cfcd1f-3676-42db-adeb-066971e7a1ea",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "8e65c78b-14e2-4376-a64d-873599e2d49d",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "210.72000122070312",
                      y_Coordinate: "350.40002441406250",
                      width_Coordinate: "10.07998657226563",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Market_Area_Yes",
            field_id: "954a5e03-1b50-4fe3-88ed-08405f3fa328",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "0ee9ad85-9c27-438e-9990-9efb5e150ba2",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.98400",
                      page_width: "8.26390",
                      x_Coordinate: "360.00000000000000",
                      y_Coordinate: "578.88000488281250",
                      width_Coordinate: "10.08001708984375",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_Land_Use_Commercial",
            field_id: "74be910f-af3b-4d97-bd7a-0901c9d8c3f0",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "7199307c-3b38-4b6a-a9f6-30188794d9f5",
                      type: "String",
                      page_no: "1",
                      confidence: "0.97000",
                      page_width: "8.26390",
                      x_Coordinate: "673.91998291015620",
                      y_Coordinate: "385.44000244140625",
                      width_Coordinate: "8.64001464843760",
                      height_Coordinate: "8.15997314453125",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "15",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "15",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Driveway_Built-in",
            field_id: "538d128a-8e77-488c-9a72-095cae966845",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "dca6d996-e91e-4921-a945-5b1470387114",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99200",
                      page_width: "8.26390",
                      x_Coordinate: "656.64001464843750",
                      y_Coordinate: "762.72003173828120",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "8.63995361328130",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Did_Inspect_Interior_Exterior",
            field_id: "c6017e70-9e4d-434f-8961-09a649fe7b70",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "850e503e-7940-483d-8920-7d7a0f37d5a0",
                      type: "Boolean",
                      page_no: "8",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "411.83999633789060",
                      y_Coordinate: "841.92004394531250",
                      width_Coordinate: "10.07998657226565",
                      height_Coordinate: "10.07995605468750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_Char_Growth_Rapid",
            field_id: "4db989f8-f13a-4be9-afe4-0b4f5e5b0bb3",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "39a28985-d236-4fda-9ccd-dad4a91d2682",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "163.68000793457030",
                      y_Coordinate: "373.44000244140625",
                      width_Coordinate: "9.11997985839845",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Amenities_Porch_Desc",
            field_id: "4c996ecc-da18-4abf-b7e2-0cc69624e6bc",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "49602952-3a63-4fff-a126-80682113b1c5",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "522.72003173828120",
                      y_Coordinate: "751.67999267578120",
                      width_Coordinate: "25.91998291015630",
                      height_Coordinate: "9.59997558593760",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Cv Stp",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Cv Stp",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_1_Unit_Trend_Marketing_3-6",
            field_id: "eaeca6e2-7bc7-4e3e-a58d-0cfd9ed5ef66",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "8c7afa7b-7f85-46d5-9f45-a742a551a8af",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99100",
                      page_width: "8.26390",
                      x_Coordinate: "423.35998535156250",
                      y_Coordinate: "373.44000244140625",
                      width_Coordinate: "9.12002563476560",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Subject_To_Repairs",
            field_id: "4e04b3d5-fa4a-4c1b-a985-0ee42b48e458",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "ed3f267e-5482-4c93-9473-75ae31468615",
                      type: "Boolean",
                      page_no: "2",
                      confidence: "0.98900",
                      page_width: "8.26390",
                      x_Coordinate: "171.83999633789062",
                      y_Coordinate: "895.67999267578120",
                      width_Coordinate: "9.60000610351563",
                      height_Coordinate: "9.60003662109380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Amenities_Pool_Desc",
            field_id: "396ce765-bb91-4e54-a10c-1056e80e868d",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "fbfc814c-f352-44b3-b096-f98a49109485",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "441.59997558593750",
                      y_Coordinate: "763.67999267578120",
                      width_Coordinate: "19.67999267578125",
                      height_Coordinate: "8.64001464843760",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "None",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "None",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Finished_Area_Bedrooms",
            field_id: "e1faea77-e6fd-4029-be4d-107d09466b8c",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "f0fc0cc5-9bae-4634-9400-8f24658b48c5",
                      type: "Integer",
                      page_no: "1",
                      confidence: "0.98900",
                      page_width: "8.26390",
                      x_Coordinate: "355.20001220703125",
                      y_Coordinate: "788.16003417968750",
                      width_Coordinate: "3.83999633789065",
                      height_Coordinate: "7.19995117187500",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "4",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "4",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Property_Appraised_Other",
            field_id: "17dc234a-dc93-49c6-b937-10c7a7940346",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "5ec7f9f3-ab49-4e3a-bc3d-e440969fe260",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "330.23999023437500",
                      y_Coordinate: "166.55999755859375",
                      width_Coordinate: "9.12002563476560",
                      height_Coordinate: "9.12001037597655",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Heating_Other",
            field_id: "a3f8afcc-a5a2-46d0-b96c-11465b66675f",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "89e91e78-e9b3-4084-83fb-a1ba5379585b",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "272.63998413085940",
                      y_Coordinate: "739.67999267578120",
                      width_Coordinate: "8.64001464843750",
                      height_Coordinate: "8.64001464843760",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_Boundaries",
            field_id: "70755808-289c-4459-a51b-11f037dc136e",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "f283455f-7cde-4d6c-8f31-8292c8352b38",
                      type: "String",
                      page_no: "1",
                      confidence: "0.73200",
                      page_width: "8.26390",
                      x_Coordinate: "132.47999572753906",
                      y_Coordinate: "385.91998291015625",
                      width_Coordinate: "377.28001403808594",
                      height_Coordinate: "21.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content:
                        "The neighborhood is bound by: Sydney Rd to the North, Mud Lake Rd to the East, Holloway Rd to the South, and Turkey Creek Rd to the West.",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value:
                        "The neighborhood is bound by: Sydney Rd to the North, Mud Lake Rd to the East, Holloway Rd to the South, and Turkey Creek Rd to the West.",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Condition",
            field_id: "2ecb39b0-dff7-43ba-9871-1305a351966c",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "eff74604-24aa-4df6-bc08-38e25197990b",
                      type: "String",
                      page_no: "1",
                      confidence: "0.72400",
                      page_width: "8.26390",
                      x_Coordinate: "131.52000427246094",
                      y_Coordinate: "822.23999023437500",
                      width_Coordinate: "540.47999572753906",
                      height_Coordinate: "55.68005371093750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content:
                        "C3;Kitchen-updated-one to five years ago;Bathrooms-not updated; The subject was built in 2016 and has been well maintained. The kitchen has updated countertops, cabinets, and appliances. The bathrooms appear to be original and have been well maintained. There is updated vinyl plank flooring in the family room, dining room, and three of the bedrooms. At the time of the inspection, the utilities were on and functioning; however, the appraiser cannot confirm the proper functionality as this is beyond the scope of work of an appraiser.",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value:
                        "C3;Kitchen-updated-one to five years ago;Bathrooms-not updated; The subject was built in 2016 and has been well maintained. The kitchen has updated countertops, cabinets, and appliances. The bathrooms appear to be original and have been well maintained. There is updated vinyl plank flooring in the family room, dining room, and three of the bedrooms. At the time of the inspection, the utilities were on and functioning; however, the appraiser cannot confirm the proper functionality as this is beyond the scope of work of an appraiser.",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Appliances_Disposal",
            field_id: "f1e5e73f-060c-49a1-9c40-14bd22b30680",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "59597bf8-6a78-42e0-b75c-a8171a906fe2",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "332.63998413085940",
                      y_Coordinate: "774.71997070312500",
                      width_Coordinate: "9.60000610351560",
                      height_Coordinate: "9.60003662109380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Foundation_Sump_Pump",
            field_id: "102c448e-1b49-4a43-842b-155be269591a",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "9394495a-700f-49f4-af34-1f9d9a45f5ad",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "349.44000244140625",
                      y_Coordinate: "694.08001708984380",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.11993408203120",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Foundation_Dampness",
            field_id: "9893d694-de6b-45b0-8310-15a5547b7725",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "f7ddc02e-718f-499a-9ee7-34d71e3bf08b",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99200",
                      page_width: "8.26390",
                      x_Coordinate: "272.16000366210940",
                      y_Coordinate: "716.16003417968750",
                      width_Coordinate: "9.60000610351560",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Amenities_Other",
            field_id: "a9822499-1d6f-4ddb-a50e-16f2ec776c28",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "12884fd9-8db6-4385-9edb-88c57a99c4a3",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99200",
                      page_width: "8.26390",
                      x_Coordinate: "489.59997558593750",
                      y_Coordinate: "762.23999023437500",
                      width_Coordinate: "8.64001464843750",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_Land_Use_Multi-Family",
            field_id: "bd566400-f1ec-4e49-aaf5-17115057cc03",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "992af0f0-7a0a-4299-a7bc-5f484bdb17c1",
                      type: "String",
                      page_no: "1",
                      confidence: "0.86100",
                      page_width: "8.26390",
                      x_Coordinate: "673.91998291015620",
                      y_Coordinate: "373.91998291015625",
                      width_Coordinate: "8.64001464843760",
                      height_Coordinate: "8.16003417968750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "15",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "15",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Dimensions",
            field_id: "6ac93fae-4dcc-4750-a3e8-17ff04199fdc",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "bb6a631a-7e1f-432b-b7ec-60f2d8083bf7",
                      type: "String",
                      page_no: "1",
                      confidence: "0.96000",
                      page_width: "8.26390",
                      x_Coordinate: "170.39999389648438",
                      y_Coordinate: "476.64001464843750",
                      width_Coordinate: "28.80001831054687",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "0.27 ac",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "0.27 ac",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Exterior_Screens",
            field_id: "6cf3533f-dbe5-47e9-bcc2-1e11f51d7933",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "ce8a5c6c-4abf-43e0-a51e-aae1438e91e1",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "481.91998291015625",
                      y_Coordinate: "718.08001708984380",
                      width_Coordinate: "38.40002441406255",
                      height_Coordinate: "9.11993408203120",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Mesh/Avg",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Mesh/Avg",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "One-Unit_Price_High",
            field_id: "ed1bd4a7-8c9c-469c-93b4-1e4366d58b3f",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "c9166932-29aa-44f6-b05a-e1b89321b95d",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "534.72003173828120",
                      y_Coordinate: "385.44000244140625",
                      width_Coordinate: "13.91998291015630",
                      height_Coordinate: "8.64001464843750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "710",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "710",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Finished_Area_Gross_Living",
            field_id: "df2e03b0-d076-4093-b45e-1ec85abceb6f",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "d89d8ae4-c693-4a62-a119-353d97fa498e",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "509.27996826171875",
                      y_Coordinate: "787.67999267578120",
                      width_Coordinate: "20.64001464843745",
                      height_Coordinate: "9.60003662109380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "2,500",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "2,500",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Heating_Fuel",
            field_id: "e9458638-af32-421f-bbdd-2493d0ab2aa2",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "ca665ff6-ae9a-4582-8103-16d5b9b7d26a",
                      type: "String",
                      page_no: "1",
                      confidence: "0.98900",
                      page_width: "8.26390",
                      x_Coordinate: "360.48001098632810",
                      y_Coordinate: "740.16003417968750",
                      width_Coordinate: "28.32000732421880",
                      height_Coordinate: "8.64001464843750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Electric",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Electric",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Amenities_Fence_Desc",
            field_id: "f1c8333a-c8b7-4048-9a73-24b45efbad21",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "41fb0b4d-35c2-493d-8a46-73d76ae1044d",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "522.72003173828120",
                      y_Coordinate: "740.16003417968750",
                      width_Coordinate: "18.23999023437500",
                      height_Coordinate: "9.11993408203130",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Vinyl",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Vinyl",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "One-Unit_Price_Low",
            field_id: "4c37133e-95fc-4dd7-a16e-24c28ca50e32",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "63b6bafd-9cb6-48ab-ab9d-213731ae93d0",
                      type: "String",
                      page_no: "1",
                      confidence: "0.74900",
                      page_width: "8.26390",
                      x_Coordinate: "535.19995117187500",
                      y_Coordinate: "374.40002441406250",
                      width_Coordinate: "13.92004394531250",
                      height_Coordinate: "8.63998413085940",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "135",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "135",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Off-Site_Improve_Type_Alley_Public",
            field_id: "927688ab-5235-4f2b-9f94-2567a4c52600",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "336d0921-91ca-4a0c-91e9-74af808a5d17",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99100",
                      page_width: "8.26390",
                      x_Coordinate: "625.91998291015620",
                      y_Coordinate: "555.83996582031250",
                      width_Coordinate: "9.60003662109380",
                      height_Coordinate: "9.60003662109370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Occupant_Tenant",
            field_id: "41450499-ffbf-4753-82ec-27fe3b34c841",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "2b2371ae-9376-49f2-bfc2-73614c9ba5f3",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "205.44000244140625",
                      y_Coordinate: "155.52000427246094",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "8.63999938964844",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "As_Is",
            field_id: "25d824aa-ba15-42b1-91c4-284e5cb29a1f",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "b70a0a3a-b7f9-4fcb-a8f0-438b38fa456c",
                      type: "Boolean",
                      page_no: "2",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "205.44000244140625",
                      y_Coordinate: "885.11999511718750",
                      width_Coordinate: "9.60000610351563",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_Char_Built-Up_Under25",
            field_id: "213f1954-6d48-423b-a537-286867780b3e",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "b5e63d97-0b78-4bd9-be13-9fb6793f4247",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "261.11999511718750",
                      y_Coordinate: "362.40002441406250",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "8.63998413085940",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Property_Best_Use_No",
            field_id: "e51215c3-d0bb-4f63-9629-2971433b9b78",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "dbf64409-1d12-4f73-a496-7db9c8ec4d72",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "550.55999755859380",
                      y_Coordinate: "510.23999023437500",
                      width_Coordinate: "10.08001708984370",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Foundation_Settlement",
            field_id: "95c1dbda-5713-45a0-86bb-2afb9ef05263",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "c9c6ce24-d4e8-42b1-b966-2fb662e3c569",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "325.44000244140625",
                      y_Coordinate: "717.11999511718750",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "8.64001464843750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_Char_Location_Urban",
            field_id: "d1413aa4-9b24-4852-9fa9-2f314b5d56a4",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "1137f466-0009-4915-b5e7-2a26ff416d83",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "163.68000793457030",
                      y_Coordinate: "350.40002441406250",
                      width_Coordinate: "9.11997985839845",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Zone_Compliance_Legal",
            field_id: "d4312740-ca66-4e74-8be4-2f3e8283ecb0",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "46f5a1c0-5543-4fde-a4cc-312d1110a7dc",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "193.91999816894530",
                      y_Coordinate: "499.19998168945310",
                      width_Coordinate: "9.59999084472658",
                      height_Coordinate: "9.12002563476565",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Amenities_Fireplace_Desc",
            field_id: "4f0c9273-2b02-42e6-be02-3038f51145ef",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "58dd0a16-73d2-4325-9036-026737ff5dd8",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "477.11999511718750",
                      y_Coordinate: "741.11999511718750",
                      width_Coordinate: "3.84002685546875",
                      height_Coordinate: "6.71997070312500",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "0",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "0",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Finished_Area_Bath",
            field_id: "81bb4903-8d13-4704-9211-30f5dfb922f8",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "e085b433-1282-40bd-87f5-04e944dc4295",
                      type: "Decimal",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "433.44000244140625",
                      y_Coordinate: "788.16003417968750",
                      width_Coordinate: "12.00000000000000",
                      height_Coordinate: "8.64001464843750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "2.1",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "2.1",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Appliances_Microwave",
            field_id: "afafd4bc-a2a1-467c-8152-329ef3063033",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "dd4e3fd0-d2c9-4978-b5de-98d74f35377e",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "378.23999023437500",
                      y_Coordinate: "774.71997070312500",
                      width_Coordinate: "10.08001708984375",
                      height_Coordinate: "9.60003662109380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Amenities_Pool",
            field_id: "ea921044-7c72-4678-aa6c-37b827777968",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "f5b4aee6-e1fa-4d4d-b5d0-ad3ecb6b226d",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99200",
                      page_width: "8.26390",
                      x_Coordinate: "411.83999633789060",
                      y_Coordinate: "762.23999023437500",
                      width_Coordinate: "9.60000610351565",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Exterior_Exterior_Walls",
            field_id: "5475b3d1-08b5-4b7b-8898-3853f17b3e80",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "8e1d4723-b1f7-4540-9954-dc9fa5d6d989",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "482.88000488281250",
                      y_Coordinate: "660.96002197265620",
                      width_Coordinate: "42.71997070312500",
                      height_Coordinate: "9.11999511718760",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Stucco/Avg",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Stucco/Avg",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Prop_Neighbor_Yes",
            field_id: "d498a0a5-c1f0-44ca-b94d-3a40faa77bf4",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "05322f8b-472c-419f-964a-83c1c07ec75b",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "512.64001464843750",
                      y_Coordinate: "923.52001953125000",
                      width_Coordinate: "10.08001708984370",
                      height_Coordinate: "10.08001708984380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Driveway_Garage_No_Cars",
            field_id: "b8b70d24-da71-4942-8acd-3b024991c331",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "49ed81af-e487-4135-88e5-908f11483c7d",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "668.64001464843750",
                      y_Coordinate: "740.16003417968750",
                      width_Coordinate: "4.32000732421870",
                      height_Coordinate: "8.15997314453130",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "2",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "2",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Attic_None",
            field_id: "d701eb04-e49b-4e4e-9707-3b0e2a5de320",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "ff7a682d-9b02-4f88-bb82-ca0d3d8585d4",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "204.00000000000000",
                      y_Coordinate: "728.16003417968750",
                      width_Coordinate: "8.63998413085938",
                      height_Coordinate: "9.11993408203130",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_View",
            field_id: "04e0aeb1-9fb8-46f4-ad95-3b934f46b6be",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "b167f570-92be-4ca4-9431-57097a7eeb75",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "598.08001708984380",
                      y_Coordinate: "477.11999511718750",
                      width_Coordinate: "26.88000488281240",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "N;Res;",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "N;Res;",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Appliances_Refrigerator",
            field_id: "7bb2361a-8964-47e9-b064-3bdcf6fba63e",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "c27180b0-1801-4290-9841-643078867c0d",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "168.47999572753906",
                      y_Coordinate: "775.19995117187500",
                      width_Coordinate: "9.12001037597656",
                      height_Coordinate: "9.12005615234380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "New_Construction",
            field_id: "57fff6c3-ef88-434a-b3cc-3c0de9033d58",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSFHAUT",
                documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentTypeShortCode: "FHAUT",
                versions: [
                  {
                    documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                    documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "f9ce2cc8-d3aa-4131-be1c-f1da017a80ac",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.98900",
                      page_width: "8.26390",
                      x_Coordinate: "532.98242187500000",
                      y_Coordinate: "173.33760070800780",
                      width_Coordinate: "9.70556640625000",
                      height_Coordinate: "9.58079528808595",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Driveway_Garage",
            field_id: "58612ccb-deb0-4c39-9820-3d69a5d1fdc9",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "da87f0cb-78d6-4ab1-9722-5452396c33cc",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "566.40002441406250",
                      y_Coordinate: "739.19995117187500",
                      width_Coordinate: "10.55999755859370",
                      height_Coordinate: "10.08001708984380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "One-Unit_Age_Pred",
            field_id: "41b516d1-636f-4516-9e94-3e53718bf275",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "d3ea3c81-30b3-4f87-b148-6309242dde18",
                      type: "String",
                      page_no: "1",
                      confidence: "0.97500",
                      page_width: "8.26390",
                      x_Coordinate: "588.96002197265620",
                      y_Coordinate: "396.96002197265625",
                      width_Coordinate: "8.15997314453130",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "29",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "29",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Subject_Neighborhood_Name",
            field_id: "b3e342cb-1543-485a-9605-3f018048ae2c",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "cb7e39ea-7556-4f0a-b6c2-1aead99d5713",
                      type: "String",
                      page_no: "1",
                      confidence: "0.95300",
                      page_width: "8.26390",
                      x_Coordinate: "200.16000366210938",
                      y_Coordinate: "144.95999145507812",
                      width_Coordinate: "60.00000000000002",
                      height_Coordinate: "8.64001464843750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Magnolia Green",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Magnolia Green",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Prop_Neighbor",
            field_id: "47a2dc6f-390b-4eb9-9f5f-3f87677bb689",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "f72bf2a8-8a14-4a2f-a7a5-adec832672c5",
                      type: "String",
                      page_no: "1",
                      confidence: "0.24900",
                      page_width: "8.26390",
                      x_Coordinate: "553.91998291015620",
                      y_Coordinate: "924.00000000000000",
                      width_Coordinate: "57.11999511718760",
                      height_Coordinate: "9.60003662109380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "No If No, describe",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "No If No, describe",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Amenities_Patio_Deck",
            field_id: "12015373-ad71-4a82-ba3d-4076db4bb8ff",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "a92f65d1-a0b7-444b-af89-f3dbb03bb3cf",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99300",
                      page_width: "8.26390",
                      x_Coordinate: "411.83999633789060",
                      y_Coordinate: "751.19995117187500",
                      width_Coordinate: "9.60000610351565",
                      height_Coordinate: "9.12005615234380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "HOA_Per_Year",
            field_id: "7f36ce6e-3bb3-4817-8e9b-41f8ec158686",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "6084112b-5932-4b5c-b78a-12dde959c2f4",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "599.03997802734380",
                      y_Coordinate: "155.04000854492188",
                      width_Coordinate: "9.60003662109370",
                      height_Coordinate: "9.59999084472656",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Zone_Description",
            field_id: "5eae38bf-e20b-4530-a849-41fc357a0fe1",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "5e2cf3dc-11a8-4d83-a706-ab5f71b0897b",
                      type: "String",
                      page_no: "1",
                      confidence: "0.95300",
                      page_width: "8.26390",
                      x_Coordinate: "406.55999755859375",
                      y_Coordinate: "488.16000366210940",
                      width_Coordinate: "83.52001953125000",
                      height_Coordinate: "10.07998657226560",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Planned Development",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Planned Development",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Tax_Year",
            field_id: "b49aa944-4898-4225-8b56-444548689645",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "f305516d-9c57-4bdb-9857-525668911261",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "449.27996826171875",
                      y_Coordinate: "132.95999145507812",
                      width_Coordinate: "18.24002075195315",
                      height_Coordinate: "9.12001037597658",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "2022",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "2022",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Heating_FWA",
            field_id: "bdd86fa4-f62d-43e5-99e7-446e8388d6ee",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "f1347e7c-32cc-4642-8cec-c8119b7c98ce",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "299.04000854492190",
                      y_Coordinate: "728.16003417968750",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.11993408203130",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Zone_Compliance_Legal_Nonconforming",
            field_id: "111d851b-16f9-4897-bc93-44979b0720b0",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "d239ad80-8829-4d38-bef8-1ed484203134",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "229.44000244140625",
                      y_Coordinate: "499.19998168945310",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.12002563476565",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Zone_Compliance_No_Zone",
            field_id: "94821b09-b29f-4726-91a9-4828aca2c6ff",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "e74a5e90-652d-4407-8389-1ef2d040b097",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "373.44000244140625",
                      y_Coordinate: "499.19998168945310",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "8.64001464843750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Additional_Comments",
            field_id: "bd9cfced-7e0b-44dc-a8c6-485ed9be35f0",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "3a120d2d-fc7a-4355-8637-f81eb3bccf82",
                      type: "String",
                      page_no: "5",
                      confidence: "0.06400",
                      page_width: "8.26390",
                      x_Coordinate: "131.04000854492188",
                      y_Coordinate: "72.95999908447266",
                      width_Coordinate: "546.72000122070312",
                      height_Coordinate: "114.24001312255859",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content:
                        "231101117 The intended user of this appraisal report is FHA/HUD and the lender/client. No additional intended users are identified by the appraiser. This report contains sufficient information to enable the lender/client to understand the report. Any other party receiving a copy of this report for any reason is not an intended user; nor does receiving a copy of this report result in an appraiser-client relationship. Use of this report by any other party(ies) is not intended by the appraiser. The intended use of the appraisal is solely to assist FHA in assessing the risk of the Property securing the FHA-insured Mortgage (24 CFR § 200.145(b). FHA and the Mortgagee are the intended users of the appraisal report. The FHA appraiser does not guarantee that the Property is free from defects. The appraisal establishes the value of the Property for mortgage insurance purposes only. 16 Per FHA guidelines, and head and shoulders inspection of the attic was conducted. See photo. The subject complies with HUD MPRs/4000.1",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value:
                        "231101117 The intended user of this appraisal report is FHA/HUD and the lender/client. No additional intended users are identified by the appraiser. This report contains sufficient information to enable the lender/client to understand the report. Any other party receiving a copy of this report for any reason is not an intended user; nor does receiving a copy of this report result in an appraiser-client relationship. Use of this report by any other party(ies) is not intended by the appraiser. The intended use of the appraisal is solely to assist FHA in assessing the risk of the Property securing the FHA-insured Mortgage (24 CFR § 200.145(b). FHA and the Mortgagee are the intended users of the appraisal report. The FHA appraiser does not guarantee that the Property is free from defects. The appraisal establishes the value of the Property for mortgage insurance purposes only. 16 Per FHA guidelines, and head and shoulders inspection of the attic was conducted. See photo. The subject complies with HUD MPRs/4000.1",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Attic_Scuttle",
            field_id: "ee78e22e-15b5-457c-b8bf-4869d0eaec08",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "623947a3-63d9-4477-a0ca-22da5881bba6",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "203.51998901367188",
                      y_Coordinate: "751.19995117187500",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.12005615234380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Amenities_WoodStove_Desc",
            field_id: "50683de1-3bff-4515-8be3-48a1ef9c27ef",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "80640e46-86af-4547-8ca6-4530ed5590fe",
                      type: "String",
                      page_no: "1",
                      confidence: "0.78600",
                      page_width: "8.26390",
                      x_Coordinate: "555.35998535156250",
                      y_Coordinate: "729.59997558593750",
                      width_Coordinate: "4.80004882812500",
                      height_Coordinate: "7.67999267578130",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "0",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "0",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Cost_Approach_Comments2",
            field_id: "a92866a7-f17b-48ac-880f-4d64a632a72e",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "d90caea0-5902-4dc4-9cd8-af46fd554ffa",
                      type: "String",
                      page_no: "5",
                      confidence: "0.64000",
                      page_width: "8.26390",
                      x_Coordinate: "131.04000854492188",
                      y_Coordinate: "647.03997802734380",
                      width_Coordinate: "261.11999511718752",
                      height_Coordinate: "22.08001708984370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content:
                        "The cost approach is not considered relevant in the resale of existing homes and is given no consideration in the final value estimate.",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value:
                        "The cost approach is not considered relevant in the resale of existing homes and is given no consideration in the final value estimate.",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Utilities_Electricity_Other",
            field_id: "f6a48f86-d4a5-4aba-8ceb-4df476605040",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "cf84d3ec-fee4-4a39-b26f-4de3819de00d",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99300",
                      page_width: "8.26390",
                      x_Coordinate: "208.32000732421875",
                      y_Coordinate: "544.80004882812500",
                      width_Coordinate: "9.59999084472655",
                      height_Coordinate: "9.11993408203120",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Heating_Radiant",
            field_id: "f9345c6a-013e-46e8-856b-4fb2783656cd",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "7ad385ff-0aa9-4625-9670-3f14db7e9c02",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "373.91998291015625",
                      y_Coordinate: "728.16003417968750",
                      width_Coordinate: "9.12002563476565",
                      height_Coordinate: "9.11993408203130",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Market_Value",
            field_id: "cea7f250-3250-4ec7-91fc-500a53e97488",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "e2fc852e-f0c1-4fcf-ad32-fb39ca7736b5",
                      type: "String",
                      page_no: "2",
                      confidence: "0.96900",
                      page_width: "8.26390",
                      x_Coordinate: "150.72000122070312",
                      y_Coordinate: "947.52001953125000",
                      width_Coordinate: "31.19999694824218",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "370,000",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "370,000",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "HOA_Per_Month",
            field_id: "81479977-ed07-4031-a037-5037f04e60bf",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "9c1ae19c-9cd0-46ce-8a27-3b650e9d8f63",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "645.11999511718750",
                      y_Coordinate: "155.04000854492188",
                      width_Coordinate: "9.60003662109370",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Off-Site_Improve_Type_Street_Public",
            field_id: "e91f11b2-0263-4454-b934-5620e02a905b",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "57dde726-da16-470d-8f89-8d2a2136b6e2",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99200",
                      page_width: "8.26390",
                      x_Coordinate: "625.44000244140620",
                      y_Coordinate: "544.32000732421880",
                      width_Coordinate: "10.08001708984380",
                      height_Coordinate: "10.08001708984370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Exterior_Window_Type",
            field_id: "a9730861-62c8-476d-ac11-566e70fc0e9d",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "666c15e3-c316-4c38-a05a-2c77a527896c",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "482.40002441406250",
                      y_Coordinate: "695.03997802734380",
                      width_Coordinate: "37.91998291015630",
                      height_Coordinate: "9.12005615234370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Metal/Avg",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Metal/Avg",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Appliances_Washer",
            field_id: "7a15c565-58e2-4ce0-a9da-56eddb75a4c6",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "f7e3c222-779b-44d3-b98e-4f90e1c9bb0a",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "432.00000000000000",
                      y_Coordinate: "774.71997070312500",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Year_Built",
            field_id: "384fb797-8e42-4dda-aeaa-5801ebc82aec",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "890fb567-537b-41f4-8f37-1e912b0ee623",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "195.36001586914062",
                      y_Coordinate: "706.08001708984380",
                      width_Coordinate: "17.27996826171876",
                      height_Coordinate: "8.15997314453120",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "2016",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "2016",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "One-Unit_Age_High",
            field_id: "2730aba4-c0ae-48fc-99d3-599c08e3601c",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "83231f09-5236-4fc1-9d42-7592a71e4f84",
                      type: "String",
                      page_no: "1",
                      confidence: "0.98900",
                      page_width: "8.26390",
                      x_Coordinate: "588.47998046875000",
                      y_Coordinate: "385.44000244140625",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "8.64001464843750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "65",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "65",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Amenities_Fence",
            field_id: "5145deae-bd97-4482-91ef-5b78d9a391fc",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "2f09a6de-53fc-4aeb-a4cd-07c8531626ca",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "489.11999511718750",
                      y_Coordinate: "739.67999267578120",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.12005615234380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Utilities_Water_Other",
            field_id: "335d6dbf-98c1-48a7-94fb-5b83619985dd",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "0719ad75-764f-45e7-874d-71981dbadf50",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "389.27996826171875",
                      y_Coordinate: "544.80004882812500",
                      width_Coordinate: "9.12005615234375",
                      height_Coordinate: "9.11993408203120",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Appliances_Other_Details",
            field_id: "139e7b4e-9c1a-48ba-bbee-5f4636a88c94",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "7ce4d9cf-d036-4490-8bc3-b075de860fbf",
                      type: "String",
                      page_no: "1",
                      confidence: "0.86400",
                      page_width: "8.26390",
                      x_Coordinate: "507.35998535156250",
                      y_Coordinate: "774.71997070312500",
                      width_Coordinate: "45.11999511718750",
                      height_Coordinate: "9.60003662109380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Other (describe)",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Other (describe)",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_Land_Use_Other",
            field_id: "4bb625c6-b7ba-4a41-9691-5ff04fc86237",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "fe66b82d-cdd7-47ff-8b6b-709361069ce9",
                      type: "String",
                      page_no: "1",
                      confidence: "0.98700",
                      page_width: "8.26390",
                      x_Coordinate: "673.91998291015620",
                      y_Coordinate: "396.48001098632810",
                      width_Coordinate: "8.64001464843760",
                      height_Coordinate: "8.63998413085940",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "15",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "15",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Attic_Finished",
            field_id: "a58f9e07-9e5f-429f-b593-60a9af641d14",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "101264cc-57f7-4a9f-8e44-6cbc0d12591d",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "132.00000000000000",
                      y_Coordinate: "761.76000976562500",
                      width_Coordinate: "9.60000610351562",
                      height_Coordinate: "10.07995605468750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_Char_Built-Up_25-75",
            field_id: "9715d455-8ae0-42c0-87f4-62079bbcf0e6",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "f5960c16-33a9-4b34-8b42-b20c49aa19f9",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "211.20001220703125",
                      y_Coordinate: "362.40002441406250",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.11996459960940",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Property_Appraised_Fee_Simple",
            field_id: "97e48c2d-1d5b-44e0-ad4b-6415eaa7054b",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "993ba15c-495a-431e-b08f-8e54f991d4ea",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "217.44000244140625",
                      y_Coordinate: "166.08000183105470",
                      width_Coordinate: "10.07998657226563",
                      height_Coordinate: "9.60000610351560",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_Char_Growth_Stable",
            field_id: "ae3fe07b-c36c-47ae-843b-6512ee003f9e",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "c10fb56a-b54d-43db-87ff-201d6cf820f4",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "210.72000122070312",
                      y_Coordinate: "373.44000244140625",
                      width_Coordinate: "10.07998657226563",
                      height_Coordinate: "9.60000610351565",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Existing_Construction",
            field_id: "a9751363-7146-4f59-939c-65a2533c4bd0",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSFHAUT",
                documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentTypeShortCode: "FHAUT",
                versions: [
                  {
                    documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                    documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "cbad1149-a08f-4ed6-ae1b-7b558a7a07da",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.98700",
                      page_width: "8.26390",
                      x_Coordinate: "532.46398925781250",
                      y_Coordinate: "151.79519653320312",
                      width_Coordinate: "10.34881591796870",
                      height_Coordinate: "10.09921264648438",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_1_Unit_Trend_Pro_Value_Stable",
            field_id: "1ff5c7d0-719f-4a24-9670-65c29a511dca",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "1420e165-0a3e-4e68-b121-1f1bc6903a41",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "423.35998535156250",
                      y_Coordinate: "350.88000488281250",
                      width_Coordinate: "9.12002563476560",
                      height_Coordinate: "8.63998413085940",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Off-Site_Improve_Type_Street_Private",
            field_id: "e9f62b77-6d7d-497b-9f81-65d3ecdb2004",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "c1caf3fb-1b84-4e9c-89e8-17b9898eb0eb",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "660.47998046875000",
                      y_Coordinate: "544.80004882812500",
                      width_Coordinate: "9.60003662109380",
                      height_Coordinate: "9.11993408203120",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Foundation_Partial_Basement",
            field_id: "f654bc02-f7d6-4b2c-a230-6768fa742ed0",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "0bb44f16-6c92-4d9a-b1af-ab2e4cbdc8d4",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "339.83999633789060",
                      y_Coordinate: "660.00000000000000",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Cooling_Other",
            field_id: "03614c7a-3461-4d44-aab9-67741ed8c1ea",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "caf004cf-a7c8-47db-a837-2238f7daa7ce",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "343.20001220703125",
                      y_Coordinate: "762.23999023437500",
                      width_Coordinate: "9.59997558593750",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Did_Inspect_Exterior",
            field_id: "94ed4cc6-d8cd-44a9-8925-6785f7c116ba",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "fb1e628c-523f-44cd-9e55-03592bb0ac5c",
                      type: "Boolean",
                      page_no: "8",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "411.83999633789060",
                      y_Coordinate: "817.44006347656250",
                      width_Coordinate: "10.07998657226565",
                      height_Coordinate: "9.59991455078130",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Foundation_Outside",
            field_id: "cce1ba87-fed4-4b0e-bdfa-6856496671e0",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "777d2a6c-81a6-4056-972f-0906d8c65ffd",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "271.67999267578125",
                      y_Coordinate: "693.59997558593750",
                      width_Coordinate: "9.60000610351565",
                      height_Coordinate: "9.12005615234370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Did_Not_Inspect",
            field_id: "8bf855f0-fbd3-4577-8f5e-6886013e97fe",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "fe3769a5-706d-4f7f-8aec-831bfc506ea8",
                      type: "Boolean",
                      page_no: "8",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "411.83999633789060",
                      y_Coordinate: "804.00000000000000",
                      width_Coordinate: "10.07998657226565",
                      height_Coordinate: "10.07995605468750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_General_Units_1",
            field_id: "c65d200d-e4bd-47b0-a5eb-68f773f5b976",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "2e688852-4e8d-4387-a48d-615bd5629a5a",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "151.20001220703125",
                      y_Coordinate: "648.00000000000000",
                      width_Coordinate: "9.59997558593750",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Cost_Approach_Comments1",
            field_id: "343f15a4-c37a-431d-b39c-69c150902862",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "727ff3a1-4d5b-4a59-8fee-c8b2f52f1841",
                      type: "String",
                      page_no: "5",
                      confidence: "0.90000",
                      page_width: "8.26390",
                      x_Coordinate: "498.72003173828125",
                      y_Coordinate: "555.83996582031250",
                      width_Coordinate: "124.31994628906255",
                      height_Coordinate: "10.08001708984370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Site value is based on allocation.",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Site value is based on allocation.",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Utilities_Electricity_Public",
            field_id: "aa65eef8-a64a-4b81-8932-6a015adf9714",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "b0486a7a-8611-49cb-b91a-44a2792d8125",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "178.55999755859375",
                      y_Coordinate: "544.32000732421880",
                      width_Coordinate: "9.60000610351563",
                      height_Coordinate: "9.59997558593740",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Additional_Listing_Comments1",
            field_id: "b2718839-1214-4666-b6b0-6ab47452ae2e",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "c2213f0f-5ac5-4c1c-a635-15f4a30ded75",
                      type: "String",
                      page_no: "4",
                      confidence: "0.59200",
                      page_width: "8.26390",
                      x_Coordinate: "131.52000427246094",
                      y_Coordinate: "850.55993652343750",
                      width_Coordinate: "213.59999084472656",
                      height_Coordinate: "10.56005859375000",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "footage adjustment accounts for the bedroom difference.",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "footage adjustment accounts for the bedroom difference.",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Map_Reference",
            field_id: "57235ce0-423f-4622-8a70-6d2dc4be3e6c",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "3dc9c5e4-c485-4c89-85c7-5b7775338c27",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "470.88000488281250",
                      y_Coordinate: "144.47999572753906",
                      width_Coordinate: "22.55999755859375",
                      height_Coordinate: "8.63999938964844",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "45300",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "45300",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Subject_Data_Source",
            field_id: "45859421-b853-409a-8b06-6d9bfcceff68",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "8bb09d6d-0f0f-47b7-81a0-1ff2836bb7d3",
                      type: "String",
                      page_no: "1",
                      confidence: "0.57700",
                      page_width: "8.26390",
                      x_Coordinate: "132.00000000000000",
                      y_Coordinate: "212.63998413085938",
                      width_Coordinate: "546.23999023437500",
                      height_Coordinate: "21.60002136230468",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content:
                        "DOM 70;According to MFRMLS# T3471467 the subject was listed for $399,900 on 09/11/2023. Please see attachment for the subject's full listing history.",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value:
                        "DOM 70;According to MFRMLS# T3471467 the subject was listed for $399,900 on 09/11/2023. Please see attachment for the subject's full listing history.",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Shape",
            field_id: "791138ac-414e-4968-9a01-6fd13bb80e0e",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "c693ad80-7cc6-4b4f-8395-50319d29f6e1",
                      type: "String",
                      page_no: "1",
                      confidence: "0.98900",
                      page_width: "8.26390",
                      x_Coordinate: "483.83999633789060",
                      y_Coordinate: "476.64001464843750",
                      width_Coordinate: "72.96005249023440",
                      height_Coordinate: "10.08001708984375",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Nearly Rectangular",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Nearly Rectangular",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Attic_Floor",
            field_id: "57830e52-1377-4cb4-a5fd-70f4a2aa8a81",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "7438423e-56a4-490e-8c8b-2f98eedf8470",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "132.47999572753906",
                      y_Coordinate: "751.19995117187500",
                      width_Coordinate: "8.63999938964844",
                      height_Coordinate: "8.64001464843750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Deficiencies_Yes",
            field_id: "ea685203-0e18-440f-b906-710a4fe3e689",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "32b47d59-d02f-4a52-9adb-5ed9ac6ce5f2",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.98900",
                      page_width: "8.26390",
                      x_Coordinate: "563.52001953125000",
                      y_Coordinate: "878.39996337890620",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.12005615234380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_General_Type_End_Unit",
            field_id: "7a112a75-0cd1-45ae-8e78-712aee7ad225",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "c3977b3e-890d-4fa6-8ba3-4b3607cce588",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99200",
                      page_width: "8.26390",
                      x_Coordinate: "209.27999877929688",
                      y_Coordinate: "671.03997802734380",
                      width_Coordinate: "9.12001037597656",
                      height_Coordinate: "9.12005615234370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Utilities_Sewer_Public",
            field_id: "6f3b4f42-6ae0-45b8-9c53-72b3f661cc79",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "a294c654-66d2-453a-b281-f1ec644f3ee5",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.98900",
                      page_width: "8.26390",
                      x_Coordinate: "359.04000854492190",
                      y_Coordinate: "555.83996582031250",
                      width_Coordinate: "10.07998657226560",
                      height_Coordinate: "9.60003662109370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Deficiencies",
            field_id: "1851788e-646c-4c9c-b218-737bab89294e",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "22353582-8f41-481a-9fd8-cdd67bc06884",
                      type: "String",
                      page_no: "1",
                      confidence: "0.16300",
                      page_width: "8.26390",
                      x_Coordinate: "603.35998535156250",
                      y_Coordinate: "878.88000488281250",
                      width_Coordinate: "59.52001953125000",
                      height_Coordinate: "8.15997314453130",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "No If Yes, describe",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "No If Yes, describe",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Interior_Bath_Floor",
            field_id: "51dab04e-4c25-46a1-ba82-74d10f8c6405",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "0b86d320-b82c-4362-aeb7-5686e48d8bc7",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "613.44000244140620",
                      y_Coordinate: "683.52001953125000",
                      width_Coordinate: "29.75994873046880",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Tile/Avg",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Tile/Avg",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_General_No_Stories",
            field_id: "23e49c34-fc2f-45b1-8967-77df63ae9e0f",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "4255a249-1b6b-4261-910d-e934e5d82551",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "194.88000488281250",
                      y_Coordinate: "661.44000244140620",
                      width_Coordinate: "4.32000732421875",
                      height_Coordinate: "8.15997314453130",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "2",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "2",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Attic_Stairs",
            field_id: "906b81bf-1ef9-4e4b-a68d-78181a2d655d",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "11e05022-e946-4070-a1e8-a384b73dc2a7",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "203.51998901367188",
                      y_Coordinate: "739.67999267578120",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "8.64001464843760",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Cooling_Individual",
            field_id: "b7cbe5c4-3018-45e7-8bef-796d457c91f0",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "ae7a92de-23da-47e7-b3c2-dbec5dc293fc",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "272.16000366210940",
                      y_Coordinate: "762.23999023437500",
                      width_Coordinate: "9.60000610351560",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_FEMA_Map_Date",
            field_id: "b1707f96-92ea-4bfc-8e11-7a5ff882b8be",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "a9308608-c965-4beb-ac51-bb97088b1ed1",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "627.83996582031250",
                      y_Coordinate: "567.83996582031250",
                      width_Coordinate: "42.72003173828130",
                      height_Coordinate: "10.08001708984370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "08/28/2008",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "08/28/2008",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Interior_Bath_Wainscot",
            field_id: "047cb4c1-d9ef-4452-ac70-7b47faad6924",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "92a37942-7ccf-4cde-affc-028e25e0a346",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "612.47998046875000",
                      y_Coordinate: "694.55999755859380",
                      width_Coordinate: "20.64001464843750",
                      height_Coordinate: "9.60003662109370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "None",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "None",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Appliances_Range",
            field_id: "1bf914df-57ef-4d71-b95c-7b66ec5910c0",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "601f91ec-6464-4088-8660-8878b6178637",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "221.75999450683594",
                      y_Coordinate: "774.71997070312500",
                      width_Coordinate: "9.12001037597656",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Occupant_Owner",
            field_id: "d6297623-126d-47c0-9a33-7c0f3a4a6da9",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "772a6668-8b3e-443b-9b02-6d0d34f373c7",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "166.55999755859375",
                      y_Coordinate: "155.04000854492188",
                      width_Coordinate: "9.12001037597655",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Foundation_Basement_Area",
            field_id: "148b8bcf-4193-49a4-bb05-7cf43fd52e5c",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "ad848147-f5da-431f-910a-b26a4782c7ec",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "384.48001098632810",
                      y_Coordinate: "672.00000000000000",
                      width_Coordinate: "4.32000732421880",
                      height_Coordinate: "8.16003417968750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "0",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "0",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Utilities_Gas_Other_Details",
            field_id: "3ad90cde-2c78-4666-916c-7d69d0797f3f",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "a76196a2-8944-4cd5-a36c-2a974b99eccf",
                      type: "String",
                      page_no: "1",
                      confidence: "0.95300",
                      page_width: "8.26390",
                      x_Coordinate: "221.27999877929688",
                      y_Coordinate: "557.76000976562500",
                      width_Coordinate: "19.67999267578124",
                      height_Coordinate: "7.67999267578120",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "None",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "None",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Amenities_Patio_Deck_Desc",
            field_id: "ddb1483f-642d-481d-b431-7f2ceec4d3a2",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "5666dec5-6ba2-4c87-aaf0-61e638ca5b00",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "461.27996826171875",
                      y_Coordinate: "751.67999267578120",
                      width_Coordinate: "22.08001708984375",
                      height_Coordinate: "8.64001464843760",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "SmPt",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "SmPt",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Area",
            field_id: "a5408555-0b70-4d91-8f56-80accf80aca3",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "9723feb5-c494-4113-94fc-0a6596e93c36",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "365.76000976562500",
                      y_Coordinate: "477.11999511718750",
                      width_Coordinate: "33.59997558593750",
                      height_Coordinate: "9.60003662109375",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "11761 sf",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "11761 sf",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_1_Unit_Trend_Marketing_Over6",
            field_id: "2f60fdc7-bf15-46f0-a6c7-810bf5a7ed80",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "6d97d317-19b5-4ced-aca4-7e6e9641ce34",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "472.80001831054690",
                      y_Coordinate: "373.44000244140625",
                      width_Coordinate: "9.60000610351560",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Foundation_Infestation",
            field_id: "5711cd31-0c93-4019-9b07-819a7f845860",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "bf43e602-5d63-4add-a83d-1f3cadad397f",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "315.83999633789060",
                      y_Coordinate: "705.59997558593750",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.12005615234370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Utilities_Gas_Other",
            field_id: "c8498f2f-dbfe-4b34-8669-8290e6dbf4bf",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "814e5bb5-8a2a-4d7a-817b-fd3ab928373d",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99400",
                      page_width: "8.26390",
                      x_Coordinate: "208.32000732421875",
                      y_Coordinate: "555.83996582031250",
                      width_Coordinate: "9.59999084472655",
                      height_Coordinate: "9.12005615234370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Off-Site_Improve_Type_Street",
            field_id: "d4e040b1-2ebb-441e-bbd3-8296e9395a53",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "0b8d80e1-cc79-4474-8789-c72dc810ada0",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "510.23999023437500",
                      y_Coordinate: "545.27996826171880",
                      width_Coordinate: "29.28002929687500",
                      height_Coordinate: "9.60003662109370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Asphalt",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Asphalt",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Market_Value_as_of_Date",
            field_id: "a26dac45-4638-4771-8449-88b3c8c6791f",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "874b8fe2-9dd2-4246-915e-a263240ef2a4",
                      type: "Date",
                      page_no: "2",
                      confidence: "0.96900",
                      page_width: "8.26390",
                      x_Coordinate: "249.11999511718750",
                      y_Coordinate: "947.52001953125000",
                      width_Coordinate: "42.72000122070310",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "2023-11-28",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "2023-11-28",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Subject_Census_Tract",
            field_id: "98686c14-7c2f-4e2f-aaba-89f74765e048",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "853bd0f7-ec35-4c9a-ae9b-4acd8caf7a89",
                      type: "Decimal",
                      page_no: "1",
                      confidence: "0.98900",
                      page_width: "8.26390",
                      x_Coordinate: "602.88000488281250",
                      y_Coordinate: "144.00000000000000",
                      width_Coordinate: "31.20001220703130",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "0124.03",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "0124.03",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "One-Unit_Price_Pred",
            field_id: "041c41a5-86f3-4585-9a29-8ae965269c4f",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "08bb163e-f6fc-4389-a59f-a4d1f4df46a6",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "534.23999023437500",
                      y_Coordinate: "396.48001098632810",
                      width_Coordinate: "13.92004394531250",
                      height_Coordinate: "9.60000610351565",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "430",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "430",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Market_Area_No",
            field_id: "6567f588-8ba2-4703-9ac9-8affd9a9c49f",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "b66679c9-544b-4336-b058-f1af22407c42",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "394.08001708984375",
                      y_Coordinate: "578.88000488281250",
                      width_Coordinate: "9.11996459960935",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Amenities_Other_Desc",
            field_id: "763e77a7-6fb1-4e27-bf00-8fbaf07e8098",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "c0f7a140-286f-4bff-a00b-9b8c59be4d9a",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "522.23999023437500",
                      y_Coordinate: "764.16003417968750",
                      width_Coordinate: "19.67999267578120",
                      height_Coordinate: "7.67993164062500",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "None",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "None",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Zone_Compliance_Illegal",
            field_id: "3660fd03-2ae7-459c-ba30-8fd87446da34",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "56474643-a33f-4cd9-8a73-11f2acb136bb",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "421.91998291015625",
                      y_Coordinate: "499.19998168945310",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.12002563476565",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Reconcilation_Comments1",
            field_id: "48d6310e-92e4-4bea-8cf8-900fd021647b",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "7f0b95be-fe7d-4cd9-b6ec-c2e4727138c6",
                      type: "String",
                      page_no: "2",
                      confidence: "0.54100",
                      page_width: "8.26390",
                      x_Coordinate: "131.04000854492188",
                      y_Coordinate: "905.76000976562500",
                      width_Coordinate: "421.43997192382812",
                      height_Coordinate: "10.07995605468750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content:
                        "following required inspection based on the extraordinary assumption that the condition or deficiency does not require alteration or repair.",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value:
                        "following required inspection based on the extraordinary assumption that the condition or deficiency does not require alteration or repair.",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Appliances_Other",
            field_id: "3876e39c-8008-4024-b5f0-90828d70e5ea",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "95129cda-a490-4a1a-8f51-16d7a432c8b8",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "493.91998291015625",
                      y_Coordinate: "774.71997070312500",
                      width_Coordinate: "9.60000610351565",
                      height_Coordinate: "9.60003662109380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Additional_Features",
            field_id: "82acb555-3136-47d5-9a0e-916ec319b6c4",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "d4cc93b8-15af-41a7-8f9a-4bcf4289533a",
                      type: "String",
                      page_no: "1",
                      confidence: "0.91900",
                      page_width: "8.26390",
                      x_Coordinate: "309.59997558593750",
                      y_Coordinate: "799.19995117187500",
                      width_Coordinate: "223.20007324218750",
                      height_Coordinate: "10.08007812500000",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Typical. The subject has a covered stoop and a small patio.",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Typical. The subject has a covered stoop and a small patio.",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Amenities_WoodStove",
            field_id: "a5f5c1f3-eafc-4d02-a0a4-91b094aeea29",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "c6972e82-9695-48d5-8316-bdd6d3e0acab",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "489.59997558593750",
                      y_Coordinate: "728.16003417968750",
                      width_Coordinate: "8.64001464843750",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Exterior_Foundation_Walls",
            field_id: "9a226117-5158-4b2a-ae76-93354797ba03",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "c8dfd7bf-d83b-422b-a303-165c9bc6a072",
                      type: "String",
                      page_no: "1",
                      confidence: "0.97900",
                      page_width: "8.26390",
                      x_Coordinate: "482.88000488281250",
                      y_Coordinate: "649.91998291015620",
                      width_Coordinate: "64.31994628906250",
                      height_Coordinate: "9.11999511718760",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Block/Frame/Avg",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Block/Frame/Avg",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_1_Unit_Trend_Pro_Value_Increasing",
            field_id: "a3d365e9-3137-4bdc-b0c4-949ee9094531",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "6dd7ad14-e7e9-4906-bff2-5515cd31a1ce",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99300",
                      page_width: "8.26390",
                      x_Coordinate: "365.76000976562500",
                      y_Coordinate: "350.88000488281250",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Market_Research_Comments1",
            field_id: "c247b190-f993-45ad-bfc2-990be96c7380",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "e5a48d72-33d5-4897-b04d-51081679ce5f",
                      type: "String",
                      page_no: "9",
                      confidence: "0.45400",
                      page_width: "8.26390",
                      x_Coordinate: "142.55999755859375",
                      y_Coordinate: "351.36001586914060",
                      width_Coordinate: "524.63995361328125",
                      height_Coordinate: "33.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content:
                        "The data search used is the same search used for comparable sales. The market has shifted from a strong sellers market, toward a more balanced market. This is evidenced by longer days on market, lower list to sales price ratios and some seller contributions. A balanced market is considered a good healthy market. Due to small data set, 1004 MC will sometimes find anomalies in its",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value:
                        "The data search used is the same search used for comparable sales. The market has shifted from a strong sellers market, toward a more balanced market. This is evidenced by longer days on market, lower list to sales price ratios and some seller contributions. A balanced market is considered a good healthy market. Due to small data set, 1004 MC will sometimes find anomalies in its",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Factors_No",
            field_id: "af394e29-e5e7-4e08-b533-9a4b34ae3f1b",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "98c2c424-3933-429e-9875-717c5858c041",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.98800",
                      page_width: "8.26390",
                      x_Coordinate: "579.35998535156250",
                      y_Coordinate: "590.40002441406250",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Foundation_Basement_Finish",
            field_id: "3fd8ad4c-3c72-437c-96da-9a6fdfcff722",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "f2284d8a-6bea-47e0-bea1-988c57f01717",
                      type: "String",
                      page_no: "1",
                      confidence: "0.90400",
                      page_width: "8.26390",
                      x_Coordinate: "384.48001098632810",
                      y_Coordinate: "684.00000000000000",
                      width_Coordinate: "4.32000732421880",
                      height_Coordinate: "7.19995117187500",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "0",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "0",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Foundation_Full_Basement",
            field_id: "5c6b163c-9270-4d8f-81fd-a1885a70a461",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "18867408-aed2-4600-86e9-5dbf6fe2d16d",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "272.16000366210940",
                      y_Coordinate: "659.52001953125000",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_1_Unit_Trend_Demand_Shortage",
            field_id: "c1e7e9f6-bafa-49f4-b918-a1a59fc345de",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "147969a7-5fb3-4f32-b637-e2e92e65b88b",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "365.76000976562500",
                      y_Coordinate: "362.40002441406250",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.11996459960940",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_General_Type_Proposed",
            field_id: "2ca2664e-4f5d-4169-8522-a433278f1082",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "904b6497-9d16-40fb-bf58-04ba4c182e95",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99100",
                      page_width: "8.26390",
                      x_Coordinate: "171.83999633789062",
                      y_Coordinate: "682.55999755859380",
                      width_Coordinate: "9.60000610351563",
                      height_Coordinate: "9.60003662109370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Off-Site_Improve_Type_Alley_Private",
            field_id: "be0c97d2-4375-4c06-8e9d-a509ad06bcff",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "4ee179ce-8f68-45c4-96e6-7f05faf3f456",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99300",
                      page_width: "8.26390",
                      x_Coordinate: "660.47998046875000",
                      y_Coordinate: "555.83996582031250",
                      width_Coordinate: "9.60003662109380",
                      height_Coordinate: "9.60003662109370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Flood_Hazard_Yes",
            field_id: "56f84d35-b17d-4643-8d09-a68068f17d8a",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "756d8df0-3bbe-41e4-9fc2-e5bcf395c18d",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99100",
                      page_width: "8.26390",
                      x_Coordinate: "241.91999816894530",
                      y_Coordinate: "567.83996582031250",
                      width_Coordinate: "9.12001037597658",
                      height_Coordinate: "8.64001464843750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_Char_Built-Up_Over75",
            field_id: "d7656a88-360f-401c-9e05-a7e03691a432",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "2dfd0435-8a47-4b4d-9456-131ba05236fa",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "163.20001220703125",
                      y_Coordinate: "361.91998291015625",
                      width_Coordinate: "9.59997558593750",
                      height_Coordinate: "9.12002563476565",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Driveway_Det",
            field_id: "8b593289-87ac-408d-a108-a8fc7769d159",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "834db31b-7d10-42ba-b86f-e28d39ec0294",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "612.00000000000000",
                      y_Coordinate: "762.23999023437500",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Off-Site_Improve_Type_Alley",
            field_id: "3869d7c3-bd90-4075-ac66-a920494a6437",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "a56e7b28-6915-4f38-a4d2-bddedd4810df",
                      type: "String",
                      page_no: "1",
                      confidence: "0.98900",
                      page_width: "8.26390",
                      x_Coordinate: "510.23999023437500",
                      y_Coordinate: "556.80004882812500",
                      width_Coordinate: "19.67999267578120",
                      height_Coordinate: "9.11993408203120",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "None",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "None",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Utilities_Gas_Public",
            field_id: "d6ecb1c8-db26-43f9-8c66-a925d40791b9",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "e147d91f-e953-4394-81a2-62af121d4ce8",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99200",
                      page_width: "8.26390",
                      x_Coordinate: "178.55999755859375",
                      y_Coordinate: "555.83996582031250",
                      width_Coordinate: "9.60000610351563",
                      height_Coordinate: "9.60003662109370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Utilities_Water_Public",
            field_id: "d9af4094-aa58-4e38-95b1-ab238a420b63",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "2b837490-514d-4e0c-baec-8f304524df8e",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.98400",
                      page_width: "8.26390",
                      x_Coordinate: "359.51998901367190",
                      y_Coordinate: "544.32000732421880",
                      width_Coordinate: "9.60000610351560",
                      height_Coordinate: "9.59997558593740",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Deficiencies_No",
            field_id: "8cdd85a7-95ff-43e6-b76a-ad5a4f3b2ff7",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "80d9f36c-5c08-4ad9-9c1d-d83021c8ca04",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "591.35998535156250",
                      y_Coordinate: "877.92004394531250",
                      width_Coordinate: "10.08001708984370",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Market_Research_Comments2",
            field_id: "8a4cab36-bb3a-46d9-b3a2-adb7c8dafbbc",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "16e089ee-b0c2-4b6f-9b8d-fed3fd3c8b13",
                      type: "String",
                      page_no: "9",
                      confidence: "0.09100",
                      page_width: "8.26390",
                      x_Coordinate: "143.52000427246094",
                      y_Coordinate: "476.16000366210940",
                      width_Coordinate: "171.36000061035156",
                      height_Coordinate: "9.60000610351560",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Cite data sources for above information. MFRMLS",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Cite data sources for above information. MFRMLS",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_Land_Use_1Unit",
            field_id: "2a1ee995-5ec6-45c5-bc55-adf06d10c0f1",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "3296fc8f-17ea-4d20-b9da-00a2e911e679",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "672.96002197265620",
                      y_Coordinate: "350.88000488281250",
                      width_Coordinate: "9.59997558593760",
                      height_Coordinate: "8.63998413085940",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "40",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "40",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_Description",
            field_id: "37791f09-f699-4a0a-9008-afc8f850133b",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "bdc1af15-fb53-4f78-be90-24434296a0ed",
                      type: "String",
                      page_no: "1",
                      confidence: "0.75100",
                      page_width: "8.26390",
                      x_Coordinate: "132.00000000000000",
                      y_Coordinate: "408.48001098632810",
                      width_Coordinate: "548.16003417968750",
                      height_Coordinate: "33.60000610351565",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content:
                        'The reader should note that the "other" above is Vacant Land, in the present land use box, and it does not appear to have any negative impact on the subject\'s marketability or value. Predominant value is simply an expression of the mode. An individual properties relationship to the predominant value has no bearing on its value or marketability.',
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value:
                        'The reader should note that the "other" above is Vacant Land, in the present land use box, and it does not appear to have any negative impact on the subject\'s marketability or value. Predominant value is simply an expression of the mode. An individual properties relationship to the predominant value has no bearing on its value or marketability.',
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "RE_Taxes",
            field_id: "4934a3b2-07a9-446d-950b-b02d21ddb6fa",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "0dcdd18f-d5a5-4888-bc40-28a3c32ea86c",
                      type: "String",
                      page_no: "1",
                      confidence: "0.98000",
                      page_width: "8.26390",
                      x_Coordinate: "603.35998535156250",
                      y_Coordinate: "132.47999572753906",
                      width_Coordinate: "21.11999511718750",
                      height_Coordinate: "9.60000610351564",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "2,965",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "2,965",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_Market_Conditions",
            field_id: "235cacc0-c83e-4e86-8f22-b356c3b84661",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "30b803df-0d04-42ad-9331-5e58237f9c93",
                      type: "String",
                      page_no: "1",
                      confidence: "0.85200",
                      page_width: "8.26390",
                      x_Coordinate: "131.52000427246094",
                      y_Coordinate: "442.55999755859375",
                      width_Coordinate: "541.44001770019526",
                      height_Coordinate: "33.60000610351565",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content:
                        "See 1004MC for full market conditions. The subject neighborhood offers a variety of financing methods with the buyers and sellers being responsible for their respective closing costs. There does not appear to be a great need for seller incentives, however they are becoming more prevalent",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value:
                        "See 1004MC for full market conditions. The subject neighborhood offers a variety of financing methods with the buyers and sellers being responsible for their respective closing costs. There does not appear to be a great need for seller incentives, however they are becoming more prevalent",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Driveway_Carport",
            field_id: "83b9344c-9b35-4547-a595-b65229d2ab44",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "a81acea7-1d1b-47bd-a909-f9178928c629",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99300",
                      page_width: "8.26390",
                      x_Coordinate: "567.35998535156250",
                      y_Coordinate: "750.72003173828120",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.11993408203130",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Special_Assessments",
            field_id: "168c7866-37a7-4efd-b142-b7ce176de247",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "980bfcf6-0bbc-480e-822b-11dedc43c827",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "416.16000366210940",
                      y_Coordinate: "155.04000854492188",
                      width_Coordinate: "5.27999877929685",
                      height_Coordinate: "9.59999084472656",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "0",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "0",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Assessor_Parcel",
            field_id: "e1b6a86a-83a9-446f-920d-b83334cb1389",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "7a60d542-d252-400a-ada1-e96382c8ee9a",
                      type: "String",
                      page_no: "1",
                      confidence: "0.98900",
                      page_width: "8.26390",
                      x_Coordinate: "201.59999084472656",
                      y_Coordinate: "132.47999572753906",
                      width_Coordinate: "49.91999816894532",
                      height_Coordinate: "9.60000610351564",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "085196-0618",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "085196-0618",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Amenities_Fireplace",
            field_id: "bed26981-f1f9-447d-8257-b94a6bda1330",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "a37074a1-0e63-476f-9bd9-80b96d564f2f",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99200",
                      page_width: "8.26390",
                      x_Coordinate: "412.32000732421875",
                      y_Coordinate: "739.67999267578120",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "8.64001464843760",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Exterior_Gutters",
            field_id: "4715ccc7-f74f-4f3a-802e-bcc90b497ad1",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "02e0feb7-2a0c-4570-8cdc-089f7146f6d1",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "482.88000488281250",
                      y_Coordinate: "684.00000000000000",
                      width_Coordinate: "19.67999267578125",
                      height_Coordinate: "8.16003417968750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "None",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "None",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Exterior_Insulated",
            field_id: "a12367d3-d7bb-4587-8f16-bee151750d84",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "071846ad-5b18-4b4d-8b54-0c1227262db5",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "482.40002441406250",
                      y_Coordinate: "706.08001708984380",
                      width_Coordinate: "19.67999267578125",
                      height_Coordinate: "8.64001464843740",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "None",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "None",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Assignment_Type_Refinance",
            field_id: "0d19c92d-0473-415e-a386-bf6fe1ec4135",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "f6684101-acbd-4b49-8d88-cad8604d17a0",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "282.72000122070310",
                      y_Coordinate: "178.08000183105470",
                      width_Coordinate: "9.60000610351565",
                      height_Coordinate: "9.12001037597655",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "One-Unit_Age_Low",
            field_id: "0ae7ce4c-0aaf-4533-b07c-c1635cda2c60",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "4d6a232b-66fc-482d-ab52-33444c870b75",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "590.88000488281250",
                      y_Coordinate: "375.36001586914060",
                      width_Coordinate: "4.31994628906250",
                      height_Coordinate: "7.19998168945315",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "4",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "4",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Finished_Area_Rooms",
            field_id: "77687e70-105d-4cfa-abb9-c1b254ce52b6",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "71e68435-af3a-4ad3-ad87-b1665fb9b3f6",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "278.88000488281250",
                      y_Coordinate: "788.64001464843750",
                      width_Coordinate: "3.83999633789060",
                      height_Coordinate: "7.19995117187500",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "7",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "7",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_1_Unit_Trend_Marketing_Under3",
            field_id: "6f180040-37f9-43b2-a7e8-c1e8d3cc47e1",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "b72debf1-2119-40d0-859e-1341e68b6b6a",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99300",
                      page_width: "8.26390",
                      x_Coordinate: "365.27999877929690",
                      y_Coordinate: "373.44000244140625",
                      width_Coordinate: "9.60000610351560",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Comparable_Did_Not_Inspect_Exterior",
            field_id: "1eb7c212-0e6b-4d82-8c6d-c2a7aee12fde",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "7a7e14f3-d886-49c0-b5db-08022cf7e9fb",
                      type: "Boolean",
                      page_no: "8",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "411.83999633789060",
                      y_Coordinate: "900.96002197265620",
                      width_Coordinate: "9.60000610351565",
                      height_Coordinate: "9.59991455078130",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Zone_Class",
            field_id: "79f013d2-f595-4b3f-997e-c3598cbe14c5",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "db459303-938e-4858-a7df-0d0f110b1968",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "222.24000549316406",
                      y_Coordinate: "488.64001464843750",
                      width_Coordinate: "11.51998901367188",
                      height_Coordinate: "8.63995361328125",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "PD",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "PD",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Attic_Drop_Stair",
            field_id: "98141ec2-b05f-4de4-b1f5-c48c4ec2a498",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "4761c205-6e03-41e2-bf66-407e1358b67c",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "132.47999572753906",
                      y_Coordinate: "739.67999267578120",
                      width_Coordinate: "8.63999938964844",
                      height_Coordinate: "9.12005615234380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_FEMA_Map",
            field_id: "16717fdc-0c09-4b10-9419-c4a0994efe21",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "2513440c-fa02-479e-8866-9ecc2024f6f8",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "461.27996826171875",
                      y_Coordinate: "567.83996582031250",
                      width_Coordinate: "52.80004882812505",
                      height_Coordinate: "10.08001708984370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "12057C0410H",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "12057C0410H",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_Char_Location_Rural",
            field_id: "15aa1799-c971-4257-996f-c4d82e7f1953",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "94d5c1fd-64ba-4e2c-b38e-48bc8398ad75",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "261.11999511718750",
                      y_Coordinate: "350.88000488281250",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_General_Type_Det",
            field_id: "a9d020ff-df2e-42f3-8dec-c629124d51a8",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "c5b6b6d0-ac4c-4ef9-bf90-02dbb07f8e23",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99300",
                      page_width: "8.26390",
                      x_Coordinate: "150.72000122070312",
                      y_Coordinate: "670.55999755859380",
                      width_Coordinate: "10.07998657226563",
                      height_Coordinate: "9.60003662109370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Subject_to_Inspection",
            field_id: "fc154d70-1f9c-4fbb-b29a-c784a5976e5e",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "15a64658-bbfb-46c4-91ed-81730c598a55",
                      type: "Boolean",
                      page_no: "2",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "626.88000488281250",
                      y_Coordinate: "895.19995117187500",
                      width_Coordinate: "10.08001708984370",
                      height_Coordinate: "10.08007812500000",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_General_Type_Under_Const",
            field_id: "6abd994f-db96-4547-874c-ca09dda280ee",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "a0ef4034-85a9-4b8b-9a0a-17019261e0dc",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "217.91999816894530",
                      y_Coordinate: "682.55999755859380",
                      width_Coordinate: "9.59999084472658",
                      height_Coordinate: "9.11999511718740",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Interior_Driveway",
            field_id: "b1955e37-85c1-4903-9795-ca282861fa13",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "38e78e3f-cc85-4cad-b387-446e5662ca4c",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "566.88000488281250",
                      y_Coordinate: "716.16003417968750",
                      width_Coordinate: "9.59997558593750",
                      height_Coordinate: "10.07995605468750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Assignment_Type_Purchase",
            field_id: "9011350f-4bda-4825-8efa-cb9416c69667",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "1425d4b6-af9c-40da-b9e0-01dd974a9dcf",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99200",
                      page_width: "8.26390",
                      x_Coordinate: "193.91999816894530",
                      y_Coordinate: "178.08000183105470",
                      width_Coordinate: "9.12001037597658",
                      height_Coordinate: "8.63999938964842",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Exhibit_A",
            field_id: "bf895d91-e37b-4d90-8b83-cb948fb5691b",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "6282f74e-d7d1-48b4-bba2-1de8b90f7b7e",
                      type: "String",
                      page_no: "1",
                      confidence: "0.87500",
                      page_width: "8.26390",
                      x_Coordinate: "192.00000000000000",
                      y_Coordinate: "120.95999908447266",
                      width_Coordinate: "146.88000488281250",
                      height_Coordinate: "11.04000091552734",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Magnolia Green Phase 2 Lot 9 Block E",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Magnolia Green Phase 2 Lot 9 Block E",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_1_Unit_Trend_Demand_Over_Supply",
            field_id: "4d57016e-079e-4181-90ea-cd1d840ed10e",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "45bdbd20-4df5-43ec-b318-09725fb18c4e",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "473.27996826171875",
                      y_Coordinate: "362.40002441406250",
                      width_Coordinate: "8.64001464843750",
                      height_Coordinate: "8.63998413085940",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Subject_to_Completion",
            field_id: "dd78adcd-15bc-4f83-a6c1-d03c4683720b",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "8cb20669-068b-4781-a442-7612237518b0",
                      type: "Boolean",
                      page_no: "2",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "249.11999511718750",
                      y_Coordinate: "885.11999511718750",
                      width_Coordinate: "9.60000610351560",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Foundation_Crawl_Space",
            field_id: "b4602987-9493-4edc-9d19-d4b6703c4177",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "f095bc8f-abf5-46c1-adb6-2008b0fe3952",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "339.83999633789060",
                      y_Coordinate: "648.47998046875000",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Economic_Life",
            field_id: "6f42ec95-52e2-4a2c-ad0a-d4f4d73cdb4e",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "c468b4a8-d48c-48a4-bffa-cf86dde7b27e",
                      type: "String",
                      page_no: "5",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "378.23999023437500",
                      y_Coordinate: "726.72003173828120",
                      width_Coordinate: "10.56002807617190",
                      height_Coordinate: "9.11993408203130",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "55",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "55",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Driveway_Att",
            field_id: "a3a90bcc-19ea-4445-b861-d52f67b87e62",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "a67f8e00-6ef9-4930-b31f-5252a5634119",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "566.88000488281250",
                      y_Coordinate: "762.23999023437500",
                      width_Coordinate: "9.59997558593750",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Sales_Comparison_Comments1",
            field_id: "0aa34252-c039-4230-a9ff-d5777b95f358",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "18c1aedc-abc2-44a5-a188-e172f4774dc9",
                      type: "String",
                      page_no: "2",
                      confidence: "0.07900",
                      page_width: "8.26390",
                      x_Coordinate: "131.52000427246094",
                      y_Coordinate: "668.64001464843750",
                      width_Coordinate: "552.47999572753906",
                      height_Coordinate: "32.16003417968750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content:
                        "The subject has a prior sale which was not listed on MFRMLS and does not appear to have been arms length as it was purchased by Offerpad which is a third party iBuyer. Comparable 7 has a prior transfer which was not listed on MFRMLS and was non arms length as it was purchased by Opendoor which is a third party iBuyer.",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value:
                        "The subject has a prior sale which was not listed on MFRMLS and does not appear to have been arms length as it was purchased by Offerpad which is a third party iBuyer. Comparable 7 has a prior transfer which was not listed on MFRMLS and was non arms length as it was purchased by Opendoor which is a third party iBuyer.",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Attic_Heated",
            field_id: "b18c5a39-9d7d-438d-8e4e-d5b9bc0f46bf",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "2252bb11-d459-447a-8325-234662bbb733",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "203.51998901367188",
                      y_Coordinate: "762.23999023437500",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Cooling_Central",
            field_id: "c627e79f-96fc-48cd-b91e-d60c25505b6a",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "b5bfb825-e546-4a38-85e1-daccfaff0f32",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99300",
                      page_width: "8.26390",
                      x_Coordinate: "303.36001586914060",
                      y_Coordinate: "751.19995117187500",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.12005615234380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Comparable_Did_Inspect_Exterior",
            field_id: "265a03c5-27f5-4535-8b79-d62bdbc33200",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "f172a752-81ad-48a4-a4ef-be07f65dadce",
                      type: "Boolean",
                      page_no: "8",
                      confidence: "0.98900",
                      page_width: "8.26390",
                      x_Coordinate: "411.83999633789060",
                      y_Coordinate: "914.39996337890620",
                      width_Coordinate: "9.60000610351565",
                      height_Coordinate: "9.60003662109380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Utilities_Sewer_Other",
            field_id: "5d64a5bc-b021-4cbc-bea3-d637e346de7e",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "433a33bb-bafd-4f31-9799-01101291a325",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "389.27996826171875",
                      y_Coordinate: "555.83996582031250",
                      width_Coordinate: "9.60003662109375",
                      height_Coordinate: "9.60003662109370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "HOA",
            field_id: "2711498c-18b5-4117-aad0-d6b79e3f2dbe",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "ad5d757e-2541-400e-80b7-005ab9fb7547",
                      type: "String",
                      page_no: "1",
                      confidence: "0.97800",
                      page_width: "8.26390",
                      x_Coordinate: "564.96002197265620",
                      y_Coordinate: "155.04000854492188",
                      width_Coordinate: "14.39996337890630",
                      height_Coordinate: "9.59999084472656",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "560",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "560",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_General_With_Accessory",
            field_id: "9bb7ab9d-550c-4d48-8abe-d916b53e79a8",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "debf6752-be8d-4cb9-b692-0d03f1326ad4",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "179.52000427246094",
                      y_Coordinate: "648.00000000000000",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Occupancy_Status",
            field_id: "d8a77d6c-c86e-4ae3-a8eb-df40e2fff27c",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAUSDU",
                documentTypeDisplayName: "AUS-DU",
                documentTypeShortCode: "AUSDU",
                versions: [
                  {
                    documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                    documentDisplayName: "FINAL",
                    documentVersionCode: "DVAUSDUF0",
                    documentVersionDisplayName: "USDUF0",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "9850dda2-ad72-42c0-8865-0236747b39a5",
                      type: "String",
                      page_no: "6",
                      confidence: "0.96400",
                      page_width: "8.26390",
                      x_Coordinate: "536.84161376953120",
                      y_Coordinate: "419.93280029296875",
                      width_Coordinate: "86.70715332031260",
                      height_Coordinate: "12.97918701171875",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Primary Residence",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Primary Residence",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_1_Unit_Trend_Pro_Value_Declining",
            field_id: "4f9daec0-a8b0-4b22-9bc7-df9aa3824813",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "09c6c942-4ec8-4dc0-aaae-176537dc02cc",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "472.80001831054690",
                      y_Coordinate: "350.88000488281250",
                      width_Coordinate: "9.11996459960935",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Appliances_Dishwasher",
            field_id: "1d5345e3-f855-4e76-b20f-e1d09f2f6b05",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "c551a8c9-02fe-4e60-a97c-ee11cb273452",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "277.44000244140625",
                      y_Coordinate: "774.71997070312500",
                      width_Coordinate: "9.60000610351565",
                      height_Coordinate: "9.60003662109380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Assignment_Type_Other",
            field_id: "5828a432-4626-4395-abcd-e2a42edafef3",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "f4991742-88aa-4298-94aa-d77fe7ff6c2c",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "373.44000244140625",
                      y_Coordinate: "178.08000183105470",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.12001037597655",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Prop_Neighbor_No",
            field_id: "06935911-a359-431b-9f1b-e48d1f6eeebd",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "aedd4a29-d457-4f44-95f1-e173a2adff9d",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "540.96002197265620",
                      y_Coordinate: "924.00000000000000",
                      width_Coordinate: "9.59997558593760",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Owner_Public",
            field_id: "4d387750-4abe-4888-8a67-e4bcaca016bd",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "3558ac42-a839-4fc7-ae60-6966fb2bcf02",
                      type: "String",
                      page_no: "1",
                      confidence: "0.93400",
                      page_width: "8.26390",
                      x_Coordinate: "418.55999755859375",
                      y_Coordinate: "109.44000244140625",
                      width_Coordinate: "67.67999267578125",
                      height_Coordinate: "10.55999755859375",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Op Spe Phx1 LLC",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Op Spe Phx1 LLC",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Property_Best_Use_Yes",
            field_id: "66124b83-0edf-4075-901e-e525f17bfd9f",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "4dce8e5d-bfdc-412d-9b03-ed0fc648f892",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "517.44000244140620",
                      y_Coordinate: "510.23999023437500",
                      width_Coordinate: "9.59997558593760",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_General_Type_Existing",
            field_id: "96813822-9978-44a0-b3a1-e5ab5bc7ec96",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "49a9d1ce-e950-4ddf-a0e4-da7d2b8087dd",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "132.00000000000000",
                      y_Coordinate: "682.55999755859380",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.60003662109370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Interior_No_Cars",
            field_id: "575200e8-80b9-498d-800b-e6bc3f092e03",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "03612abd-12ff-4109-919b-7c5b7faad065",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "668.64001464843750",
                      y_Coordinate: "717.59997558593750",
                      width_Coordinate: "4.32000732421870",
                      height_Coordinate: "8.16003417968750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "2",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "2",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Flood_Hazard_No",
            field_id: "fc88fc08-5e16-4b45-9ac4-e8724ebc74fd",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "6ae59f16-58ed-45c6-9a42-f69e6f6abb8a",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "272.63998413085940",
                      y_Coordinate: "567.83996582031250",
                      width_Coordinate: "9.60000610351560",
                      height_Coordinate: "9.12005615234370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Offered_Sale_No",
            field_id: "0ec4793d-cee2-4ca1-b819-e880c00e6202",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "c207fe88-f56f-43f8-8a3f-79c84fa407c2",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.98900",
                      page_width: "8.26390",
                      x_Coordinate: "624.96002197265620",
                      y_Coordinate: "200.16000366210938",
                      width_Coordinate: "9.59997558593760",
                      height_Coordinate: "9.59999084472656",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Exterior_Roof_Surface",
            field_id: "df828b42-36b1-4a03-a024-e9709fb06d8f",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "22c3372a-f83b-49b1-a34b-ff994ed1b688",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "482.88000488281250",
                      y_Coordinate: "672.47998046875000",
                      width_Coordinate: "45.11999511718750",
                      height_Coordinate: "9.60003662109380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Shingle/Avg",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Shingle/Avg",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Driveway_Carport_No_cars",
            field_id: "28ce4c16-100f-4aa6-9e51-e97ee3e1c72c",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "829df597-4e69-40f9-9cc7-c209d538b6a5",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "667.67999267578120",
                      y_Coordinate: "752.64001464843750",
                      width_Coordinate: "3.84002685546880",
                      height_Coordinate: "7.67999267578130",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "0",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "0",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Interior_Walls",
            field_id: "20ba472e-b6cd-490e-891c-ea4b2c8b9c98",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "4f5f2ecd-097c-45b9-b11e-c3393902afba",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "612.96002197265620",
                      y_Coordinate: "660.96002197265620",
                      width_Coordinate: "44.63995361328130",
                      height_Coordinate: "9.11999511718760",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Drywall/Avg",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Drywall/Avg",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Driveway_Surface",
            field_id: "82f0fa4c-929f-496b-96ee-ee01d7b3f9dd",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "32a9ff74-65b4-4b13-8129-b31ac7afd360",
                      type: "String",
                      page_no: "1",
                      confidence: "0.98900",
                      page_width: "8.26390",
                      x_Coordinate: "640.80004882812500",
                      y_Coordinate: "729.11999511718750",
                      width_Coordinate: "34.55993652343750",
                      height_Coordinate: "9.11999511718750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Concrete",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Concrete",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Site_Factors_Yes",
            field_id: "af13b8e4-a98c-4d24-bea9-eec77d2c101a",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "f1634c96-7010-4595-97d6-b27d6123485a",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.96500",
                      page_width: "8.26390",
                      x_Coordinate: "546.23999023437500",
                      y_Coordinate: "590.40002441406250",
                      width_Coordinate: "9.59997558593750",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Interior_Trim",
            field_id: "1bc8210e-4d6b-4813-b393-f0c9bda1920b",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "64831a47-7e4f-4112-9787-cda34a0e9ee3",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "612.47998046875000",
                      y_Coordinate: "671.52001953125000",
                      width_Coordinate: "39.84002685546880",
                      height_Coordinate: "10.07995605468750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Wood/Avg",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Wood/Avg",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_General_Effective_Age",
            field_id: "47bb4f29-5cc9-47e9-9f89-f13308611991",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "b5274ef4-4dff-4766-a127-6e5da94b28fa",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "195.83999633789062",
                      y_Coordinate: "718.55999755859380",
                      width_Coordinate: "10.56001281738282",
                      height_Coordinate: "7.20001220703120",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "1-6",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "1-6",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Interior_Car_Storage_None",
            field_id: "e5a748ac-bf2b-4c19-af3d-f1b2d09778fe",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "c7c338f2-e8dd-493b-885d-705871afc4e8",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "618.23999023437500",
                      y_Coordinate: "705.59997558593750",
                      width_Coordinate: "8.64001464843750",
                      height_Coordinate: "9.12005615234370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Sale_Price",
            field_id: "871d97e9-545d-4cac-a0f5-f3afde6e5053",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAUSDU",
                documentTypeDisplayName: "AUS-DU",
                documentTypeShortCode: "AUSDU",
                versions: [
                  {
                    documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                    documentDisplayName: "FINAL",
                    documentVersionCode: "DVAUSDUF0",
                    documentVersionDisplayName: "USDUF0",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "ff3e8084-fc79-4d0c-b220-d013c1abfc5a",
                      type: "Decimal",
                      page_no: "6",
                      confidence: "0.98700",
                      page_width: "8.26390",
                      x_Coordinate: "632.71679687500000",
                      y_Coordinate: "566.99517822265620",
                      width_Coordinate: "57.96484375000000",
                      height_Coordinate: "12.16320800781260",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "369900",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "369900",
                    },
                  },
                ],
              },
              {
                documentTypeCode: "DTSFHAUT",
                documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentTypeShortCode: "FHAUT",
                versions: [
                  {
                    documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                    documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "2",
                    always_present: "0",
                    data: {
                      id: "4d150415-a63d-4ced-9c57-ea62b479f1e8",
                      type: "Decimal",
                      page_no: "1",
                      confidence: "0.97300",
                      page_width: "8.26390",
                      x_Coordinate: "375.25439453125000",
                      y_Coordinate: "153.14880371093750",
                      width_Coordinate: "57.52319335937500",
                      height_Coordinate: "8.42880249023438",
                      is_Truth: "0",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "369900",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "369900",
                    },
                  },
                ],
              },
              {
                documentTypeCode: "DTSPROPC",
                documentTypeDisplayName: "PROPERTY-PURCHASE CONTRACT",
                documentTypeShortCode: "PROPC",
                versions: [
                  {
                    documentId: "7a300b75-ce3b-47d1-ac56-274c2769d1da",
                    documentDisplayName: "PROPERTY-PURCHASE CONTRACT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "3",
                    always_present: "0",
                    data: {
                      id: "da8eaa8c-e6ea-4367-a73b-5bb02ccc5b8d",
                      type: "Decimal",
                      page_no: "1",
                      confidence: "0.86600",
                      page_width: "8.26390",
                      x_Coordinate: "649.75683593750000",
                      y_Coordinate: "534.87359619140620",
                      width_Coordinate: "63.75360107421880",
                      height_Coordinate: "13.14239501953130",
                      is_Truth: "0",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "$ 369,900.00",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "$ 369,900.00",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Neighbor_Land_Use_2-4Unit",
            field_id: "0ba8fd7d-5a1f-4382-823f-f3be06a1221c",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "4fd3aea5-d54c-4a88-9c8d-c22169835c45",
                      type: "String",
                      page_no: "1",
                      confidence: "0.92600",
                      page_width: "8.26390",
                      x_Coordinate: "673.44000244140620",
                      y_Coordinate: "362.40002441406250",
                      width_Coordinate: "9.11999511718760",
                      height_Coordinate: "9.11996459960940",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "15",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "15",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Offered_Sale_Yes",
            field_id: "47ce70a9-6745-4fcb-9a56-f4fe9f087779",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "f6b4d44f-ff51-4342-a6b7-8de203b1cfa7",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.98900",
                      page_width: "8.26390",
                      x_Coordinate: "593.27996826171880",
                      y_Coordinate: "200.63998413085938",
                      width_Coordinate: "9.60003662109370",
                      height_Coordinate: "9.12001037597656",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Appraised_Value",
            field_id: "f4235df9-a72a-4dd3-95ed-f5485e15db15",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "4e0c0a0e-dbfb-4e8d-bd24-fbbd8b1e97cd",
                      type: "Decimal",
                      page_no: "8",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "333.11999511718750",
                      y_Coordinate: "845.28002929687500",
                      width_Coordinate: "30.24002075195310",
                      height_Coordinate: "10.07995605468750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "370,000",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "370,000",
                    },
                  },
                ],
              },
              {
                documentTypeCode: "DTSAUSDU",
                documentTypeDisplayName: "AUS-DU",
                documentTypeShortCode: "AUSDU",
                versions: [
                  {
                    documentId: "0ecbae82-7ca6-4f9c-a60f-6ff4a490e774",
                    documentDisplayName: "FINAL",
                    documentVersionCode: "DVAUSDUF0",
                    documentVersionDisplayName: "USDUF0",
                    source_priority: "2",
                    always_present: "0",
                    data: {
                      id: "ef3bb2d9-eced-4700-b1c8-bd019ab773bb",
                      type: "Decimal",
                      page_no: "6",
                      confidence: "0.98300",
                      page_width: "8.26390",
                      x_Coordinate: "632.71679687500000",
                      y_Coordinate: "582.07678222656250",
                      width_Coordinate: "57.43676757812500",
                      height_Coordinate: "12.66241455078130",
                      is_Truth: "0",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "370000",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "370000",
                    },
                  },
                ],
              },
              {
                documentTypeCode: "DTSFHAUT",
                documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentTypeShortCode: "FHAUT",
                versions: [
                  {
                    documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                    documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "3",
                    always_present: "0",
                    data: {
                      id: "6ffeb471-240d-456f-878c-fbfb23610144",
                      type: "Decimal",
                      page_no: "1",
                      confidence: "0.98600",
                      page_width: "8.26390",
                      x_Coordinate: "374.76480102539060",
                      y_Coordinate: "163.52639770507812",
                      width_Coordinate: "57.49438476562500",
                      height_Coordinate: "8.76480102539063",
                      is_Truth: "0",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "370000",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "370000",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Amenities_Porch",
            field_id: "ac0120b6-c007-46e5-b082-f6c10986a5d8",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "8b685da7-54e2-4caf-8cb3-34cc37d3debc",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99200",
                      page_width: "8.26390",
                      x_Coordinate: "489.11999511718750",
                      y_Coordinate: "751.19995117187500",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "9.12005615234380",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Property_Appraised_Leasehold",
            field_id: "914a5933-9c6e-44da-9d29-f87a0e0ea3cc",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "3bf9f06a-0da7-4fd0-888d-d8576c37e77f",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99200",
                      page_width: "8.26390",
                      x_Coordinate: "275.04000854492190",
                      y_Coordinate: "166.55999755859375",
                      width_Coordinate: "9.11999511718750",
                      height_Coordinate: "8.64001464843750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_General_Design",
            field_id: "254943c1-2fb2-45cb-84da-fa930d1966fe",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "ef14d304-194f-45cd-9229-7a013e73f4aa",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "195.36001586914062",
                      y_Coordinate: "695.03997802734380",
                      width_Coordinate: "38.39997863769532",
                      height_Coordinate: "8.64001464843740",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Traditional",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Traditional",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Occupant_Vacant",
            field_id: "d39c87fe-8848-428c-91dc-faa054ec3c1a",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "eefbe87f-db4e-411d-b573-befdcd9bb87d",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "245.75999450683594",
                      y_Coordinate: "155.04000854492188",
                      width_Coordinate: "9.60002136230468",
                      height_Coordinate: "9.59999084472656",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Interior_Floors",
            field_id: "37d284b2-fcd0-4658-ad2a-fc4c2153a6d8",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "8a6d5e8b-5168-4eca-a6ac-39938c25ce15",
                      type: "String",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "613.91998291015620",
                      y_Coordinate: "649.44000244140620",
                      width_Coordinate: "60.48004150390630",
                      height_Coordinate: "9.59997558593760",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: "Tile/Car/Vin/Avg",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: "Tile/Car/Vin/Avg",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_Foundation_Concrete_Slab",
            field_id: "9e44abff-89ac-469c-84b2-fd7ccbaf9c01",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "67ef2ef5-c240-4063-a263-b37eba18c913",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "271.67999267578125",
                      y_Coordinate: "648.00000000000000",
                      width_Coordinate: "9.60000610351565",
                      height_Coordinate: "9.59997558593750",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":selected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":selected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Improve_General_Type_Att",
            field_id: "4c402286-f8e3-4555-8664-fdebf28664b5",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSAPPLR",
                documentTypeDisplayName: "APPRAISAL REPORT",
                documentTypeShortCode: "APPLR",
                versions: [
                  {
                    documentId: "d8374348-522b-4228-9d26-36b620debc43",
                    documentDisplayName: "APPRAISAL REPORT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "db539565-626c-42ac-bfed-23631e2675e9",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99300",
                      page_width: "8.26390",
                      x_Coordinate: "179.52000427246094",
                      y_Coordinate: "671.03997802734380",
                      width_Coordinate: "9.59999084472656",
                      height_Coordinate: "9.12005615234370",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "Proposed_Construction",
            field_id: "50890823-8c99-4ccb-97cd-fff1d96381e2",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: "SUBJECT_PROPERTY_1",
            documents: [
              {
                documentTypeCode: "DTSFHAUT",
                documentTypeDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                documentTypeShortCode: "FHAUT",
                versions: [
                  {
                    documentId: "ff8bd08e-3492-49f4-af2a-033e41721b96",
                    documentDisplayName: "FHA-UW TRANSMITTAL SUMMARY-HUD 92900-LT",
                    documentVersionCode: "",
                    documentVersionDisplayName: "",
                    source_priority: "1",
                    always_present: "0",
                    data: {
                      id: "4716996e-00de-4cfa-b3d3-805e4c6756f8",
                      type: "Boolean",
                      page_no: "1",
                      confidence: "0.99000",
                      page_width: "8.26390",
                      x_Coordinate: "533.10717773437500",
                      y_Coordinate: "162.82559204101562",
                      width_Coordinate: "9.70562744140620",
                      height_Coordinate: "9.58081054687500",
                      is_Truth: "1",
                      lastModifiedBy: "",
                      lastModifiedDate: null,
                      content: ":unselected:",
                      isDeleted: "0",
                      has_Changed: "0",
                      original_Value: ":unselected:",
                    },
                  },
                ],
              },
            ],
          },
          {
            field_name: "COMPARABLE_PROPERTY",
            field_id: "4457431e-5cb0-410a-91da-f6110d8f18c7",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: null,
            documents: null,
          },
          {
            field_name: "COMPARABLE_PROPERTY",
            field_id: "4457431e-5cb0-410a-91da-f6110d8f18c7",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: null,
            documents: null,
          },
          {
            field_name: "COMPARABLE_PROPERTY",
            field_id: "4457431e-5cb0-410a-91da-f6110d8f18c7",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: null,
            documents: null,
          },
          {
            field_name: "COMPARABLE_PROPERTY",
            field_id: "4457431e-5cb0-410a-91da-f6110d8f18c7",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: null,
            documents: null,
          },
          {
            field_name: "COMPARABLE_PROPERTY",
            field_id: "4457431e-5cb0-410a-91da-f6110d8f18c7",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: null,
            documents: null,
          },
          {
            field_name: "COMPARABLE_PROPERTY",
            field_id: "4457431e-5cb0-410a-91da-f6110d8f18c7",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: null,
            documents: null,
          },
          {
            field_name: "COMPARABLE_PROPERTY",
            field_id: "4457431e-5cb0-410a-91da-f6110d8f18c7",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: null,
            documents: null,
          },
          {
            field_name: "COMPARABLE_PROPERTY",
            field_id: "4457431e-5cb0-410a-91da-f6110d8f18c7",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: null,
            documents: null,
          },
          {
            field_name: "COMPARABLE_PROPERTY",
            field_id: "4457431e-5cb0-410a-91da-f6110d8f18c7",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: null,
            documents: null,
          },
          {
            field_name: "COMPARABLE_VALUES_SUBJECT_PROPERTY",
            field_id: "4457431e-5cb0-410a-91da-f6110d8f18c7",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: null,
            documents: null,
          },
          {
            field_name: "PROPERTY_ADDRESS",
            field_id: "48a688cf-b959-4ef6-bb18-1c5008da67f4",
            parent_field_id: "45352188-c1f0-40f7-a243-f4b63135e5d7",
            parent_value: null,
            documents: null,
          },
        ],
        documents: null,
      },
    ],
  },
};
